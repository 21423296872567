<template>
  <div class="widthContainer bg-white my-2 ml-3 mr-2 rounded-lg pt-1 pb-3 px-4">
    <div class="flex flex-row justify-between items-center my-4">
      <div class="flex flex-row items-center justify-between w-11/12">
        <div class="h-10 bg-gray-400 ml-8 mb-2 mt-4 rounded-lg wave w-48"></div>
        <div class="h-7 bg-gray-400 ml-8 mb-2 mt-4 rounded-lg wave w-64"></div>
        <div class="h-7 bg-gray-400 ml-8 mb-2 mt-4 rounded-lg wave w-64"></div>
      </div>
    </div>
    <div class="relative w-11/12">
      <div class="flex flex-col h-10 bg-white my-3 rounded-full"></div>
      <div
        v-for="(num, index) in 7"
        :key="num"
        class="flex flex-col h-10 bg-gray-400 my-3 rounded-full wave"
        :class="index % 2 == 0 ? 'w-full' : 'w-9/12'"
      ></div>
      <div
        class="absolute w-full h-full top-0 border-r border-l border-gray-500"
      ></div>
    </div>
  </div>
</template>
<style scoped>
.widthContainer {
  width: 77%;
}
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
