export default {
  projectDetails(state) {
    return state.projectDetails;
  },
  timeFrame(state) {
    return state.timeFrame;
  },
  isModal(state) {
    return state.isModal;
  },
};
