<template>
  <div>
    <button
      class="bg-gray-300 rounded-lg text-center p-3 text-lg flex flex-row items-center justify-center m-2 shadow-md"
      v-for="service in services"
      :key="service.name"
      @click="selectService(service)"
      :style="{
        backgroundColor: service.authorized && '#9fd489',
        borderColor: selectedService.name == service.name && 'black',
        borderWidth: selectedService.name == service.name && '1px',
      }"
    >
      <div class="w-8 h-8 bg-white rounded-xl ml-2 mr-4 justify-center"></div>
      <p class="text-base">{{ service.name }}</p>
    </button>
  </div>
  <button
    class="p-2 text-center bg-gray-300 rounded-lg flex w-40 mt-7 justify-center shadow-md"
    :disabled="selectedService == ''"
    @click="authService()"
  >
    <LinkIcon class="w-5 h-5 mr-1" />
    <p>{{ $t("greetings-page.connect-dialog") }}</p>
  </button>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { LinkIcon } from "@heroicons/vue/outline";

export default {
  name: "ExternalServicesConnector",
  components: {
    LinkIcon,
  },
  computed: {
    ...mapGetters(["backendUrl", "authorizationHeader"]),
  },
  data() {
    return {
      services: [],
      selectedService: "",
    };
  },
  methods: {
    selectService(service) {
      this.selectedService = service;
    },
    fetchServices() {
      let headers = this.authorizationHeader;

      axios
        .get(`${this.backendUrl}/oauth/services`, { headers })
        .then(() => {
          // uncomment this to open office 365 option to get shown
          // this.services = res.data;
        })
        .catch((err) => console.log(err.message));
    },
    authService() {
      let params = new URLSearchParams();
      params.append("client_id", this.selectedService.client_id);
      params.append("scope", this.selectedService.scope);
      params.append("redirect_uri", this.backendUrl + "/oauth/callback");
      params.append(
        "state",
        `${this.selectedService.name}_${
          this.authorizationHeader.Authorization.split(" ")[1]
        }`
      );
      params.append("response_type", "code");
      window.open(
        this.selectedService.auth_url + "?" + params.toString(),
        "_self"
      );
    },
  },
  mounted() {
    this.fetchServices();
  },
};
</script>

<style scoped></style>
