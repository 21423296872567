export default {
  "header": {
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])}
  },
  "login-page": {
    "header-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Ihrem Konto anmelden"])},
    "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Addresse"])},
    "invalid-credentials-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail oder Passwort ist ungültig"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei Ihrem Recruiting-Dashboard"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "forget-password-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen Ihres Passworts zu erhalten"])},
    "password-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Reset"])}
  },
  "admin-page": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Addresse"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "add-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Benutzer hinzufügen"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "assigned-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Unternehmen und Jobs"])},
    "admin-user-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin-Benutzer können auf alle Unternehmen und Jobs zugreifen"])},
    "select-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle auswählen"])}
  },
  "greetings-page": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen zurück"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "welcome-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei Ihrem Recruiting-Dashboard"])},
    "subtitle-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle das ATS aus, um Projektdaten in Dashr zu verwenden."])},
    "description-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst die Verknüpfung jederzeit in den Einstellungen anpassen."])},
    "connect-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfen"])},
    "choose-later-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später auswählen"])}
  },
  "signup-page": {
    "header-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setzen Sie Ihr Passwort"])},
    "account-check-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie bereits ein Konto?"])},
    "login-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "invalid-general-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es fehlen Felder oder sie sind nicht korrekt ausgefüllt worden"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "firstname-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vorname muss mindestens 2 Buchstaben lang sein"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "lastname-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nachname muss mindestens 2 Buchstaben lang sein"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Addresse"])},
    "email-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine korrekte E-Mail ein"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "password-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie ein Passwort, das mindestens 3 Zeichen lang ist"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "repeat-password-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])}
  },
  "notfound-page": {
    "header-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seite wurde nicht gefunden"])},
    "loggedin-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite konnte nicht gefunden werden - besuchen Sie unser "])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "no-loggedin-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite konnte nicht gefunden werden -"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "check-dashboard-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um das Dashboard zu sehen"])}
  },
  "sidebar": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überblick"])},
    "job-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellendetails"])}
  },
  "projekt-einstellungen": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "general-project-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Projektinformationen"])},
    "general-project-information-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Projektinformationen zum ausgewählten Job"])},
    "stage-mapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage Mapping"])},
    "stage-mapping-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier kannst Du die Stages aus dem ATS-Funnel auf unsere internen Stages 'mappen'"])},
    "estimated-stage-conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschätzte Konversionsrate in Prozent"])},
    "estimated-stage-conversion-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stelle hier die erwarteten Conversion-rates von einer Stage zur nächsten ein /n (in Prozent)"])},
    "estimated-time-expenditure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschätzter Zeitaufwand in Minuten"])},
    "estimated-time-expenditure-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stelle hier die erwarteten Zeitaufwände pro Kandidat in Minuten ein"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbruch"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "Projekt Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Start"])},
    "Projekt Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Status"])},
    "Projektnummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektnummer"])},
    "geplante Projektdauer in Wochen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geplante Projektdauer in Wochen"])},
    "Zu besetzende Vakanzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu besetzende Vakanzen"])},
    "Tatsächliches Abschlussdatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tatsächliches Abschlussdatum"])},
    "Projekt pausiert (Wochen)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt pausiert (Wochen)"])},
    "Allgemeiner Ansprechpartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeiner Ansprechpartner"])},
    "Fachlicher Ansprechpartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting Specialist"])},
    "Account Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Manager"])},
    "Project Lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Lead"])},
    "Recruiting Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting Specialist"])},
    "First Sourcing Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Sourcing Specialist"])},
    "Second Sourcing Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Sourcing Specialist"])},
    "Recruitee Share Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruitee Freigabe-Link"])},
    "Stage Mapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufenzuordnung"])},
    "sourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beschafft"])},
    "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beworben"])},
    "contact request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktanfrage"])},
    "1st contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Kontaktaufnahme"])},
    "follow up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachverfolgung"])},
    "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interessiert"])},
    "motivation call, kooku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivationsgespräch, kooku"])},
    "phone screen, kooku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoninterview, kooku"])},
    "on hold after ti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auf Eis nach Telefoninterview"])},
    "submitted to customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an Kunden übermittelt"])},
    "1st interview, client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Interview, Kunde"])},
    "2nd interview, client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Interview, Kunde"])},
    "evaluation, client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung, Kunde"])},
    "reference check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenzprüfung"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot"])},
    "hired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eingestellt"])},
    "inmail 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmail 1"])},
    "inmail 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmail 2"])},
    "1 nachfass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Nachfass"])},
    "2 nachfass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Nachfass"])},
    "3 nachfass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Nachfass"])},
    "motivation call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivationsgespräch"])},
    "on hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Wartestellung"])},
    "1st interview client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Vorstellungsgespräch (Kunde)"])},
    "2nd interview client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Vorstellungsgespräch (Kunde)"])},
    "evaluation client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung (Kunde)"])}
  },
  "report-pdf": {
    "project-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt-Reporting-"])},
    "cw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" _KW-"])},
    "message-to-change-from-graph-to-table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the widget to change to graph or table view"])},
    "modal-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum für den PDF-Bericht"])},
    "modal-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])},
    "open pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF öffnen"])},
    "Projekt Eigenschaften": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Eigenschaften"])},
    "Zeitrahmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitrahmen"])},
    "Zeiterfassung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiterfassung"])},
    "Kandidaten im Prozess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten im Prozess"])},
    "Partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
    "Stellenbezeichnung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellenbezeichnung"])},
    "Projektnummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektnummer"])},
    "zu besetzende Vakanzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu besetzende Vakanzen"])},
    "tatsächliches Abschlussdatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tatsächliches Abschlussdatum"])},
    "Allgemeiner Ansprechpartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeiner Ansprechpartner"])},
    "Fachlicher Ansprechpartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting-Spezialist"])},
    "Account Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Manager"])},
    "Project Lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektleiter"])},
    "Recruiting Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting-Spezialist"])},
    "First Sourcing Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Sourcing-Spezialist"])},
    "Second Sourcing Specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiter Sourcing-Spezialist"])},
    "Aktueller Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Status"])},
    "Projektbeginn / Rebriefing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektbeginn / Rebriefing"])},
    "planmässiges Projektende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["planmässiges Projektende"])},
    "angenommene Projektlaufzeit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angenommene Projektlaufzeit"])},
    "tatsächliche Projektdauer in Wochen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tatsächliche Projektdauer in Wochen"])},
    "Projekt pausiert(Wochen)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt pausiert(Wochen)"])},
    "geschätzter Zeitaufwand in h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschätzter Zeitaufwand in h"])},
    "tatsächlicher Zeitaufwand in h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tatsächlicher Zeitaufwand in h"])},
    "Zeitaufwand - anteilig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitaufwand - anteilig"])},
    "Namen der Kandidaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namen der Kandidaten"])},
    "Kandidaten gesamt recherchiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten gesamt recherchiert"])},
    "Kandidaten kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten kontaktiert"])},
    "Kandidaten mit Rückmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten mit Rückmeldung"])},
    "Interessiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessiert"])},
    "Telefoninterviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoninterviews"])},
    "Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorstellung beim Kunden"])},
    "Persönliches Gespräch Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliches Gespräch Kunde"])},
    "Einstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellung"])},
    "Projektstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektstatus"])},
    "Gesamt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GESAMT"])},
    "Ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IST"])},
    "Ziel aus KPIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIEL AUS KPIS"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Vertragsangebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsangebot"])},
    "Projektanalyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektanalyse"])},
    "Recherchequalität": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchequalität"])},
    "Rückmeldequote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückmeldequote"])},
    "davon positive Rückmeldungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["davon positive Rückmeldungen"])},
    "Überführung zum Telefoninterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überführung zum Telefoninterview"])},
    "Erfolgsquote - TI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - TI"])},
    "Erfolgsquote - Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Vorstellung beim Kunden"])},
    "Erfolgsquote - Interviews beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Interview beim Kunden"])},
    "Erfolgsquote - Vertragsverhandlung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Vertragsverhandlung"])},
    "Gesamtperformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtperformance"])},
    "KPIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPIs"])},
    "Soll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll"])},
    "KPI-Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPI-Score"])},
    "Quantität": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantität"])},
    "Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])}
  },
  "right-menu-mobile": {
    "company-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Klicken Sie zum Bearbeiten/Löschen auf den Firmennamen"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Einstellungen"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Einstellungen"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausdrucken"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "ats-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATS Einstellungen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])}
  },
  "clients-page": {
    "current-clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine aktuellen Kunden"])},
    "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen..."])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren nach"])},
    "all-clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kunden"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "archived-clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktive Kunden"])},
    "pending-clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perspektivkunden"])},
    "former-clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehemalige Kunden"])}
  },
  "client-details-page": {
    "all-vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Vakanzen"])},
    "no-vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vakanzen"])},
    "in-planning-vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Planung"])},
    "no-in-planning-vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Projekte in Planung"])},
    "vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakanzen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv"])},
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "no-archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kein archivierter Job"])},
    "contact-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt: Details"])}
  },
  "dashboard": {
    "kpi-description-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kpi Wert?"])},
    "kpi-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der KPI Wert berechnet sich aus den <b>Ist-Werten</b> aus dem ATS und den <b>Soll-Werten</b> aus den KPIs vergangener Projekte. Eine genauer Aufschlüsselung ﬁndest du in der"])},
    "project-analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektanalyse"])},
    "days-to-kpi-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage bis KPI Ziel"])},
    "kpi-target-full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPI Ziel erfüllt!"])},
    "conversionrate-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umwandlungen"])},
    "conversionrate-successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversionsrate erfolgreich"])},
    "conversionrate-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversionsrate ok"])},
    "conversionrate-unrealized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversionsrate unrealisiert"])},
    "conversion-first-tipp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur wenige Bewerber erfüllen die Vorraussetzungen für ein Interview"])},
    "conversion-second-tipp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind die Stellenausschreibungen eindeutig und klar verständlich formuliert?"])},
    "conversion-third-tipp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werden die Stellenausschreibungen über zielgruppengerechte Kanäle veröﬀentlicht?"])},
    "calendar-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KW"])},
    "no-candidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kandidaten in den nächsten Schritten"])},
    "save-button-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Projekteinstellungen sind aktualisiert"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])}
  },
  "dashboardProjectDetails": {
    "Projektdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektdetails"])},
    "Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
    "Ansprechpartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner"])},
    "zu besetzende Vakanzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu besetzende Vakanzen"])},
    "Projektnummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektnummer"])},
    "Projektstart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektstart"])},
    "Planmäßiges Projektende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planmäßiges Projektende"])}
  },
  "dashboardWeaklyGauge": {
    "Zahlen der Kandidaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlen der Kandidaten"])},
    "tatsächliche Zahlen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tatsächliche Zahlen"])},
    "geschätzte Zahlen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschätzte Zahlen"])}
  },
  "dashboardKPIScore": {
    "Gesamt Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt Performance"])}
  },
  "dashboardProjectStatus": {
    "Projekt Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Status"])},
    "Forecast Time-to-Sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognostizieren Sie die Zeit für die Unterzeichnung"])},
    "Kandidaten gesamt recherchiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten gesamt recherchiert"])},
    "Kandidaten kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten kontaktiert"])},
    "Kandidaten mit Rückmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten mit Rückmeldung"])},
    "Interessiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessiert"])},
    "Telefoninterviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoninterviews"])},
    "Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorstellung beim Kunden"])},
    "Persönliches Gespräch Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliches Gespräch Kunde"])},
    "Vertragsangebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsangebot"])},
    "Einstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellung"])},
    "Projekt Ende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Ende"])},
    "Bewerbungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungen"])},
    "Recherchequalität": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchequalität"])},
    "Rückmeldequote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückmeldequote"])},
    "davon positive Rückmeldungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["davon positive Rückmeldungen"])},
    "Überführung zum Telefoninterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überführung zum Telefoninterview"])},
    "Erfolgsquote - TI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - TI"])},
    "Erfolgsquote - Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Vorstellung beim Kunden"])},
    "Erfolgsquote - Interview beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Interview beim Kunden"])},
    "Erfolgsquote - Vertragsverhandlung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Vertragsverhandlung"])},
    "Gesamt geschätzter Zeitaufwand in h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt geschätzter Zeitaufwand in h"])}
  },
  "projectTimeExpenditurePerWeek": {
    "Zeitaufwand in h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitaufwand in h"])},
    "Pro Woche Schätzwert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Woche Schätzwert"])}
  },
  "timeTracking": {
    "Zeiterfassung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiterfassung"])},
    "Bewerbermanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbermanagement"])},
    "Kandidaten-Ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten-Ident"])},
    "Kandidatenansprache": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidatenansprache"])},
    "Rückmeldung - Gesamt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückmeldung - Gesamt"])},
    "Telefoninterview / Interesse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoninterview / Interesse"])},
    "Vorstellung Kunde & follow-ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorstellung Kunde & follow-ups"])},
    "persönliche Gespräch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persönliche Gespräch"])},
    "Projektrecherche und Orga (in Prozent)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektrecherche und Orga (in Prozent)"])}
  },
  "donutChart": {
    "Kandidaten gesamt recherchiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten gesamt recherchiert"])},
    "Kandidaten kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten kontaktiert"])},
    "Kandidaten mit Rückmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten mit Rückmeldung"])},
    "Interessiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessiert"])},
    "Telefoninterviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoninterviews"])},
    "Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorstellung beim Kunden"])},
    "Persönliches Gespräch Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliches Gespräch Kunde"])},
    "Vertragsangebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsangebot"])},
    "Einstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellung"])},
    "Leads generiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads generiert"])},
    "Tage bis zum KPI Ziel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage bis zum KPI Ziel"])},
    "Leads ausstehend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads ausstehend"])}
  },
  "conversionRate": {
    "Projektanalyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektanalyse"])},
    "Tatsächliche Conversionrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tatsächliche Conversionrate"])},
    "Recherchiert - Kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchiert - Kontaktiert"])},
    "Kontaktiert - Rüchmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktiert - Rückmeldung"])},
    "Rüchmeldung - Interessiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückmeldung - Interessiert"])},
    "Interessiert - Telefoninterviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessiert - Telefoninterviews"])},
    "Telefoninterviews - Vostellung Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoninterviews - Vostellung Kunden"])},
    "Vostellung Kunden - pG Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vostellung Kunden - p.G. Kunden"])},
    "pG Kunden - Vertragsangebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p.G. Kunden - Vertragsangebot"])},
    "Vertragsangebot - Einstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsangebot - Einstellung"])},
    "Erwartete Conversionrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartete Conversionrate"])}
  },
  "targetComparison": {
    "Ziel / Ist-Vergleich - Gesamtprojekt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel / Ist-Vergleich - Gesamtprojekt"])},
    "Ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IST"])},
    "Ziel aus KPIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIEL AUS KPIS"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "candidateInProcess": {
    "Kandidaten im Prozess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten im Prozess"])},
    "potentielle Telefoninterviews (interested)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["potentielle Telefoninterviews (interested)"])},
    "potentielle Profil-Präsentationen (on hold)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["potentielle Profil-Präsentationen (on hold)"])},
    "Kandidat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KANDIDAT"])},
    "Nächster Schritt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÄCHSTER SCHRITT"])}
  },
  "detailsHistoricView": {
    "Projektzahlen aus ATS (historic view)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektzahlen aus ATS (historic view)"])},
    "Sourced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sourced"])},
    "Sourced - Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sourced - Out"])},
    "Kandidaten kontaktiert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten kontaktiert"])},
    "Kandidaten nachgefasst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten nachgefasst"])},
    "Bewerbungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungen"])},
    "Out nach Bewerbungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out nach Bewerbungen"])},
    "Interessierte Kandidaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessierte Kandidaten"])},
    "Telefoninterview - anstehend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoninterview - anstehend"])},
    "on hold nach TI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on hold nach TI"])},
    "Out nach Telefoninterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out nach Telefoninterview"])},
    "Vorstellung Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorstellung Kunde"])},
    "Out nach Vorstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out nach Vorstellung"])},
    "Persönliches Gespräch Kunde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliches Gespräch Kunde"])},
    "Out nach pG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out nach p.G."])},
    "Vertragsangebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsangebot"])},
    "Einstellung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellung"])},
    "Out (kontaktiert+nachgefasst+Interesse)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out (kontaktiert+nachgefasst+Interesse)"])},
    "Wochenstunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenstunden"])}
  },
  "detailsProjectAnalysis": {
    "Projektanalyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektanalyse"])},
    "Recherchequalität": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchequalität"])},
    "Rückmeldequote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückmeldequote"])},
    "davon positive Rückmeldungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["davon positive Rückmeldungen"])},
    "Überführung zum Telefoninterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überführung zum Telefoninterview"])},
    "Erfolgsquote - TI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - TI"])},
    "Erfolgsquote - Vorstellung beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Vorstellung beim Kunden"])},
    "Erfolgsquote - Interviews beim Kunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Interview beim Kunden"])},
    "Erfolgsquote - Vertragsverhandlung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Vertragsverhandlung"])},
    "Gesamtperformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtperformance"])},
    "KPIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPIs"])},
    "Soll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll"])},
    "Ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist"])},
    "KPI-Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPI-Score"])},
    "Quantität": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantität"])},
    "Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])}
  },
  "detailsCard": {
    "page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Details"])},
    "placeholder-searchbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort-Suche"])},
    "connect-ats-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit ATS Job Verbinden"])},
    "to-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Projekt"])},
    "fetch-job-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten aktualisieren"])},
    "show-job-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten zeigen"])}
  },
  "setpassword-page": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte registrieren Sie Ihr Passwort hier"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "password-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie ein Passwort, das mindestens 3 Zeichen lang ist"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "repeat-password-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss dasselbe wie das vorherige sein"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])}
  },
  "projectStatusChanges": {
    "project-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektstatuseinstellungen"])},
    "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOR"])},
    "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NACH"])},
    "time-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitrahmen"])}
  },
  "add-new-job": {
    "add-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden hinzufügen"])},
    "change-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma wechseln"])},
    "add-new-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Kunden erstellen"])},
    "add-new-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Kontakt erstellen"])},
    "edit-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt bearbeiten"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "company-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "company-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vollständige Adresse"])},
    "company-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "company-point-of-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "company-point-of-contact-position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
    "mandatory-field-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte fülle alle mit '*' markierten Felder aus"])},
    "company-status-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "company-status-archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviert"])},
    "company-status-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
    "company-status-closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
    "company-streetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
    "company-zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "company-poBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PO-Box / z.Hd."])},
    "company-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "company-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "company-street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staße"])},
    "add-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position hinzufügen"])},
    "company-first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "company-last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "company-pronoun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronoun"])},
    "company-position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "add-new-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Position erstellen"])},
    "edit-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job bearbeiten"])},
    "edit-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden bearbeiten"])},
    "job-project-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum Projektstart"])},
    "job-num-opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl offener Positionen"])},
    "job-est-duration-in-weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschätzte Laufzeit in Wochen"])},
    "job-general-contact-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt (allgemein)"])},
    "job-professional-contact-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting-Spezialist"])},
    "job-research-quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchequalität"])},
    "job-feedback-rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückmeldequote"])},
    "job-which-positive-rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["davon positive Rückmeldungen"])},
    "job-telephone-interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überführung zum Telefoninterview"])},
    "job-success-rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - TI"])},
    "job-success-rate-introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Vorstellung beim Kunden"])},
    "job-success-rate-interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Interview beim Kunden"])},
    "job-success-rate-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsquote - Vertragsverhandlung"])},
    "job-application-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbermanagement"])},
    "job-candidate-ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidaten-Ident"])},
    "job-candidate-approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidatenansprache"])},
    "job-feedback-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückmeldung - Gesamt"])},
    "job-interview-interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoninterview / Interesse"])},
    "job-appointment-customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefontermin - Kunde"])},
    "job-personal-conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persönliches Gespräch"])},
    "job-project-research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektrecherche und Orga (in %)"])},
    "contact-imported-in-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Kontakt ist noch einem Projekt zugeordnet - Bitte entferne ihn dort zuerst"])}
  },
  "disqualificationReason": {
    "disqualificationByReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disqualifizierte Kandidaten –  Grund"])},
    "disqualificationByStage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disqualifizierte Kandidaten –  Stufe"])}
  },
  "confirm-msg": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Information wird gelöscht: "])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wirst automatisch weitergeleitet..."])}
  },
  "common-elements": {
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfen"])},
    "withATSJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit ATS job"])},
    "Give UI Feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib UI Feedback"])},
    "RequestNewProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request New Project"])}
  },
  "point-of-contacts": {
    "no-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kontakte zum Import verfügbar"])},
    "import-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakte importieren"])}
  },
  "job-clustering": {
    "all-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Jobs"])},
    "your-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Jobs"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Vorlage verwenden"])},
    "edit-hashtags-for-job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwörter für Job bearbeiten"])},
    "input-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drücke Enter, um ein neues Schlagwort hinzuzufügen oder wähle aus der Liste"])},
    "actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell"])},
    "expected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartet"])},
    "add-new-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Schlagwort hinzufügen"])},
    "tag-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort-Name"])},
    "new-tag-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Schlagwort-Name"])},
    "new-tag-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Schlagwort-Stufe"])}
  }
}