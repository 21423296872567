<template>
  <div>
    <div class="grid grid-cols-3">
      <div v-if="isAddNewOrEdit === 'edit'">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="inputField"
        >
          {{ $t("job-clustering.tag-name") }}
        </label>
        <input
          class="bg-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
          type="text"
          id="inputField"
          disabled
          :value="tagName"
        />
      </div>

      <div>
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="inputField"
        >
          {{ $t("job-clustering.new-tag-name") }}
        </label>
        <input
          class="focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
          type="text"
          id="inputField"
          v-model="newTagName"
        />
      </div>

      <div>
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="inputField"
        >
          {{ $t("job-clustering.new-tag-level") }}
        </label>
        <select
          id="code"
          name="code"
          v-model="category"
          class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          <option
            v-for="option in Object.keys(levelsHexColors)"
            :key="option"
            :value="option"
          >
            <p>{{ option }}</p>
          </option>
        </select>
      </div>
    </div>

    <div class="flex flex-1 flex-row mt-2">
      <button
        class="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-md mt-2 mr-3 primary-button"
        @click="onAddOrEditTag"
        v-if="newTagName.length > 0"
      >
        <p>Save</p>
      </button>
      <button
        class="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-md mt-2"
        @click="$emit('onCancelAddOrEdit')"
      >
        <p class="text-black">Cancel</p>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  props: ["isAddNewOrEdit", "tagName", "levelCategory"],
  computed: {
    ...mapGetters([
      "authorizationHeader",
      "backendUrl",
      "allTags", // [{category: <>, name: <>}]
      "levelsHexColors",
    ]),
  },
  data() {
    return {
      newTagName: "",
      category: "Default",
    };
  },
  mounted() {
    if (this.isAddNewOrEdit == "edit") {
      this.newTagName = this.tagName;
      this.category = this.levelCategory;
    }
  },
  methods: {
    ...mapActions(["updateHashtag", "setAllJobList", "addHashtag"]),

    onAddOrEditTag() {
      let headers = this.authorizationHeader;

      // Check if the operation is an edit
      if (this.isAddNewOrEdit === "edit") {
        let body = [
          {
            old_tag_name: this.tagName,
            new_tag_name: this.newTagName,
            new_tag_category: this.category,
          },
        ];
        axios
          .put(this.backendUrl + `/tag/update-existing`, body, {
            headers,
          })
          .then(() => {
            // Prepare updated data for setting the tag's color and hit update color API
            let updatedBody = [
              {
                tag_name: this.newTagName,
                old_tag_category: this.category,
                new_tag_category: this.category,
              },
            ];
            axios.put(`${this.backendUrl}/tag/set-category`, updatedBody, {
              headers,
            });
            // Update local state and emit changes
            this.updateHashtag(body);
            this.setAllJobList([]);
            this.$emit("onCancelAddOrEdit");
          })
          .catch((error) => console.log(error));
      }
      // ELSE: if user creates a new tag
      else {
        // check if tag name is already exist in the database then give error
        let index = this.allTags.findIndex(
          (value) => value.name === this.newTagName
        );
        if (index !== -1) {
          alert("Hashtag name is already exist");
        } else {
          const body = {
            [this.newTagName]: this.category,
          };
          axios
            .post(`${this.backendUrl}/tag/create-tags`, body, { headers })
            .then(() => {
              // pushing the hashtag object to the hashtags array in state
              this.addHashtag({
                hashtag: { name: this.newTagName, category: this.category },
              });
              this.$emit("onCancelAddOrEdit");
            })
            .catch((error) => console.log(error));
        }
      }
    },
  },
};
</script>
