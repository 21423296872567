<template>
  <div class="m-8">
    <skeleton-conversion-rate-widget v-if="loading" />

    <div v-else class="bg-gray-50 rounded-lg my-5 p-3 ml-3 pl-5 mr-4">
      <div class="flex flex-row justify-between w-8/12">
        <h2 class="text-2xl text-gray-600 pl-5 pt-2 mb-3">
          {{ name }}
        </h2>
      </div>

      <div v-if="isNoJobAssigned">
        <div class="ml-5 flex flex-1 flex-col items-center">
          <div>
            <ExclamationCircleIcon class="h-10 w-10" aria-hidden="true" />
          </div>

          <div>
            <p class="text-xl">Sorry, you don't have any job assigned to you</p>
          </div>
        </div>
      </div>

      <div v-else class="m-5">
        <div class="grid grid-cols-4 gap-4">
          <div class="mt-2" v-for="data in timeExpenditureData" :key="data.id">
            <p>{{ $t(`timeTracking.${data.label}`) }}</p>
            <input
              type="text"
              :value="data.value"
              class="input input-bordered w-full max-w-xs"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import SkeletonConversionRateWidget from "../../DashboardJob/ConversionRateWidget/SkeletonConversionRateWidget.vue";

import { ExclamationCircleIcon } from "@heroicons/vue/outline";
export default {
  props: ["username"],
  data() {
    return {
      name: "Average Time Expenditure",
      loading: true,
      isNoJobAssigned: false,
      timeExpenditureData: [],
    };
  },
  methods: {
    fetchData() {
      const headers = this.authorizationHeader;
      this.loading = true;
      axios
        .get(
          `${
            this.backendUrl
          }/job/overviewTimeExpenditureSingleUser?username=${encodeURIComponent(
            this.username
          )}`,
          { headers }
        )
        .then(({ data }) => {
          if (typeof data !== "string") {
            this.isNoJobAssigned = false;

            data = data.map((obj) => ({
              ...obj,
              value: parseFloat(obj.value).toFixed(2),
            }));

            this.timeExpenditureData = [...data];
          } else {
            this.isNoJobAssigned = true;
          }
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },
  watch: {
    username() {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl"]),
    cssConversionWidgets() {
      return this.isPdfReport
        ? "grid grid-cols-4 gap-4 "
        : "flex flex-row flex-wrap flex-start";
    },
    screenSize() {
      return screen.width;
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    ExclamationCircleIcon,
    SkeletonConversionRateWidget,
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
