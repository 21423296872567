<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <div>
        <p class="text-2xl font-semibold">
          {{ $t("point-of-contacts.import-contacts") }}
        </p>
      </div>

      <div class="mt-5" v-if="filterContacts.length > 0">
        <div
          class="form-control mb-2"
          v-for="contact in filterContacts"
          :key="contact"
        >
          <label class="cursor-pointer">
            <input
              @click="onContactSelect(contact)"
              type="radio"
              name="contactToAdd"
              class="radio checked:bg-gray-400"
            />
            <span class="label-text ml-2"
              >{{ contact.first_name }} {{ contact.last_name }} ({{
                contact.email
              }})</span
            >
          </label>
        </div>
      </div>

      <div v-else class="flex items-center flex-col mt-5 mb-5">
        <MinusCircleIcon class="w-10 h-w-10 text-gray-500" />
        <p>{{ $t("point-of-contacts.no-contacts") }}</p>
      </div>

      <div class="flex flex-row justify-end items-end">
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
          @click="$emit('onModalClose')"
          type="button"
          :disabled="loading"
        >
          <span>Cancel</span>
        </button>
        <button
          v-if="filterContacts.length > 0"
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center shadow-md"
          :disabled="loading || Object.keys(selectedContact).length <= 0"
          @click="onSave"
        >
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { MinusCircleIcon } from "@heroicons/vue/solid";

export default {
  props: ["importContactDetails", "contacts"],
  computed: {
    ...mapGetters(["selectedCompanyId", "authorizationHeader", "backendUrl"]),
  },
  data() {
    return {
      filterContacts: [],
      selectedContact: {},
      loading: false,
    };
  },
  methods: {
    onContactSelect(contact) {
      this.selectedContact = contact;
    },
    onSave() {
      const headers = this.authorizationHeader;
      const { poc_id } = this.selectedContact;
      let data = { poc_id: poc_id };
      // if importContactDetails.jobId !== '0' it means we want to import contact from company to job
      if (this.importContactDetails.jobId !== "0") {
        data = {
          ...data,
          company_id: 0,
          job_id: this.importContactDetails.jobId,
        };
      } else {
        data = { ...data, company_id: this.selectedCompanyId, job_id: 0 };
      }
      axios
        .post(this.backendUrl + "/contact/import", data, { headers })
        .then(() => {
          this.$emit("onModalCloseOrRedirect");
        })
        .catch((error) => console.log(error));
    },
  },
  components: {
    MinusCircleIcon,
  },
  mounted() {
    let alreadyImportedPOCIds =
      this.importContactDetails.alreadyImportedContacts.map((value) => {
        return value.poc_id;
      });

    // if importContactDetails.jobId == 0 it means we want to get  all contacts from jobs (not company) i,e job_id != 0, import contacts from job to company
    this.filterContacts = this.contacts.filter((singleContact) => {
      return (
        (this.importContactDetails.jobId !== "0"
          ? singleContact.job_id == "0"
          : singleContact.job_id !== "0") &&
        !alreadyImportedPOCIds.includes(singleContact.poc_id)
      );
    });

    // close the modal if there are no contacts to import
    // if (this.filterContacts.length <= 0) {
    //   this.$emit("onModalClose");
    // }
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
