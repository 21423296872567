<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <app-header />

  <div class="flex flex-col items-center mt-10">
    <div class="tabs">
      <a
        class="tab tab-lg tab-bordered"
        @click="onChangeTab('Employee Overview')"
        :class="activeTab == 'Employee Overview' ? 'tab-active' : ''"
        >Employee Overview</a
      >
      <a
        class="tab tab-lg tab-bordered"
        :class="activeTab == 'Average Overview' ? 'tab-active' : ''"
        @click="onChangeTab('Average Overview')"
        >Average Overview</a
      >
      <a
        class="tab tab-lg tab-bordered"
        :class="activeTab == 'Job Success Rate' ? 'tab-active' : ''"
        @click="onChangeTab('Job Success Rate')"
        v-if="isSuperAdmin"
        >Job Success Rate</a
      >
    </div>
  </div>
  <employee-overview v-if="activeTab === 'Employee Overview'" />
  <average-overview v-if="activeTab === 'Average Overview'" />
  <job-success-rate v-if="activeTab === 'Job Success Rate'" />
</template>

<script>
import AppHeader from "../../components/layout/header/AppHeader.vue";
import { mapGetters } from "vuex";
import EmployeeOverview from "./EmployeeOverview/EmployeeOverview.vue";
import AverageOverview from "./AverageOverview/AverageOverview.vue";
import JobSuccessRate from "./JobSuccessRate/JobSuccessRate.vue";

export default {
  data() {
    return {
      activeTab: "Employee Overview",
    };
  },
  methods: {
    onChangeTab(tabName) {
      this.activeTab = tabName;
    },
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl", "isSuperAdmin"]),
  },
  mounted() {},
  components: {
    AppHeader,
    EmployeeOverview,
    AverageOverview,
    JobSuccessRate,
  },
};
</script>

<style></style>
