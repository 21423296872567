<template>
  <div class="m-8">
    <skeleton-conversion-rate-widget v-if="loading" />

    <div v-else class="bg-gray-50 rounded-lg my-5 p-3 ml-3 pl-5 mr-4">
      <div class="flex flex-row justify-between w-8/12">
        <h2 class="text-2xl text-gray-600 pl-5 pt-2 mb-3">
          {{ name }}
        </h2>
      </div>

      <div v-if="columnHeaders.length <= 0">
        <div class="ml-5 flex flex-1 flex-col items-center">
          <div>
            <ExclamationCircleIcon class="h-10 w-10" aria-hidden="true" />
          </div>

          <div>
            <p class="text-xl">Sorry, you don't have any job assigned to you</p>
          </div>
        </div>
      </div>

      <div v-else class="flex flex-row">
        <div>
          <div class="flex flex-col justify-between mr-4 h-64 w-24">
            <p class="text-sm mt-6"></p>
            <p class="text-sm mt-6">
              {{ $t(`conversionRate.${columnHeaders[0]}`) }}
            </p>
            <p class="text-sm mb-2">
              {{ $t(`conversionRate.${columnHeaders[1]}`) }}
            </p>
          </div>
          <div
            v-if="isPdfReport || screenSize < 1536"
            class="flex flex-col justify-between mr-4 h-72 w-24"
          >
            <p class="text-sm mt-6"></p>
            <p class="text-sm mt-6">
              {{ $t(`conversionRate.${columnHeaders[0]}`) }}
            </p>
            <p class="text-sm mb-2">
              {{ $t(`conversionRate.${columnHeaders[1]}`) }}
            </p>
          </div>
        </div>

        <div :class="cssConversionWidgets">
          <percentage-displayer
            v-for="(data, index) in tableData"
            :rowheaders="rowHeaders[index]"
            :key="data"
            :data="data"
            :isPdfReport="isPdfReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import PercentageDisplayer from "../../DashboardJob/ConversionRateWidget/PercentageDisplayer.vue";
import SkeletonConversionRateWidget from "../../DashboardJob/ConversionRateWidget/SkeletonConversionRateWidget.vue";

import { ExclamationCircleIcon } from "@heroicons/vue/outline";
export default {
  props: ["username"],
  data() {
    return {
      name: "Average Conversion Rate",
      columnHeaders: [],
      rowHeaders: [],
      tableData: [],
      loading: true,
      isPdfReport: false,
    };
  },
  methods: {
    fetchData() {
      console.log(this.username);
      const headers = this.authorizationHeader;
      this.loading = true;
      axios
        .get(
          `${
            this.backendUrl
          }/widget/userAllJobsConversion?username=${encodeURIComponent(
            this.username
          )}`,
          { headers }
        )
        .then(({ data }) => {
          if (data.column_headers) {
            this.columnHeaders = data.column_headers;
            this.tableData = data.table_data;
            this.rowHeaders = data.row_headers;
          }
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },
  watch: {
    username() {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl"]),
    cssConversionWidgets() {
      return this.isPdfReport
        ? "grid grid-cols-4 gap-4 "
        : "flex flex-row flex-wrap flex-start";
    },
    screenSize() {
      return screen.width;
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    PercentageDisplayer,
    ExclamationCircleIcon,
    SkeletonConversionRateWidget,
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
