<template>
  <div class="m-2">
    <div class="flex flex-col justify-between p-2 w-full">
      <div
        class="w-5 h-5 bg-gray-200 border-black rounded-full self-end"
        :class="colorCard"
      ></div>
      <p class="text-base h-12 w-11/12 text-gray-600 font-bold">
        {{ $t(`donutChart.${rowheaders}`) }}
      </p>
    </div>
    <div class="flex flex-row items-center text-xs justify-center">
      <div class="flex flex-row items-center mr-1">
        <ClockIcon class="w-3 h-3 mr-1" />
        <p>Zeit:</p>
      </div>
      <p class="my-2 text-center" v-if="percent >= 100">
        {{ $t("dashboard.kpi-target-full") }}
      </p>
      <p v-else class="my-2 text-center">
        {{ daysToKpiGoal }} {{ $t("dashboard.days-to-kpi-target") }}
      </p>
    </div>
    <donut-chart
      :days="days"
      :totalDays="totalDays"
      :actualValue="data[1]"
      :expectedValue="data[2]"
      :percentString="data[3]"
      :daysColor="daysColor"
    />
    <div class="flex justify-center">
      <div class="flex flex-col my-2">
        <div
          class="flex flex-row items-center"
          v-for="(text, index) in legend"
          :key="text"
        >
          <div
            :class="colors[index]"
            class="w-3 h-3 border-black rounded-full mr-2"
          ></div>
          <p class="text-xs">{{ $t(`donutChart.${text}`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DonutChart from "./DonutChart.vue";
import { ClockIcon } from "@heroicons/vue/outline";
import { mapGetters } from "vuex";
export default {
  props: ["rowheaders", "data"],
  components: {
    DonutChart,
    ClockIcon,
  },
  data() {
    return {
      daysColor: "",
      colorCard: "",
      days: 0,
      totalDays: 30,
      percent: null,
      legend: ["Leads generiert", "Leads ausstehend", "Tage bis zum KPI Ziel"],
      daysToKpiGoal: 0,
    };
  },
  watch: {
    actualValue() {
      this.setTableData();
    },
    days() {
      this.colorDaysKey();
      this.colorCardSetter();
    },
    percent() {
      this.colorCardSetter();
      this.colorDaysKey();
    },
  },
  computed: {
    ...mapGetters("job", ["timeFrame"]),

    colors() {
      return ["bg-gray-400", "bg-gray-200", this.daysColor[0]];
    },
  },
  methods: {
    setPercent() {
      this.percent = Math.round(
        (Number(this.data[1]) * 100) / Number(this.data[2])
      );
    },
    colorDaysKey() {
      let daysToGoal = this.daysToKpiGoal;
      let daysDifference = this.days - this.totalDays;
      if (daysDifference > 0) {
        return (this.daysColor = ["bg-red-400", "rgb(248 113 113)"]);
      }

      if (daysToGoal !== undefined) {
        if (this.percent >= 100) {
          return (this.daysColor = ["bg-green-400", "rgb(74 222 128)"]);
        } else if (daysToGoal < 4) {
          return (this.daysColor = ["bg-red-400", "rgb(248 113 113)"]);
        } else {
          return (this.daysColor = ["bg-gray-500", "rgb(107 114 128)"]);
        }
      } else {
        return (this.daysColor = ["bg-gray-500", "rgb(107 114 128)"]);
      }
    },
    colorCardSetter() {
      this.setPercent();
      if (this.percent >= 60) {
        return (this.colorCard = "bg-green-400");
      } else if (this.percent < 60 && this.percent >= 40) {
        return (this.colorCard = "bg-yellow-300");
      } else {
        return (this.colorCard = "bg-red-400");
      }
    },
    calDaysToKpiGoal() {
      const projectEndDate = new Date(
        this.timeFrame["Planmäßiges Projektende"]
          ?.split(".")
          ?.reverse()
          ?.join(".")
      );
      const currentDate = new Date();
      // if difference is in negative number that means the deadline is past
      if (projectEndDate - currentDate < 0) {
        this.daysToKpiGoal = 0;
        return;
      }
      const diffTime = Math.abs(currentDate - projectEndDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      this.daysToKpiGoal = diffDays;
    },
  },
  mounted() {
    this.calDaysToKpiGoal();
    this.colorDaysKey();
    this.colorCardSetter();
  },
};
</script>
