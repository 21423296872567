export default {
  controls(state) {
    return state.controls;
  },
  allCompaniesList(state) {
    return state.allCompaniesList;
  },
  isFormValidated(state) {
    return state.isFormValidated;
  },
  selectedAddEditCompanyName(state) {
    return state.selectedAddEditCompanyName;
  },
  selectedAddEditCompanyId(state) {
    return state.selectedAddEditCompanyId;
  },
  selectedEditJobsList(state) {
    return state.selectedEditJobsList;
  },
  isModal(state) {
    return state.isModal;
  },
  assignedCompaniesList(state) {
    return state.assignedCompaniesList;
  },
  roles(state) {
    return state.roles;
  },
  userList(state) {
    return state.userList;
  },
};
