import { createI18n } from "vue-i18n";

function checkDefaultLanguage() {
  // ================= commented by hunzala - have simple logic
  // let matched = null;
  // let languages = Object.getOwnPropertyNames(loadLocaleMessages());

  // languages.forEach((lang) => {
  //   if (lang === navigator.language) {
  //     matched = lang;
  //   }
  // });
  // if (!matched) {
  //   languages.forEach((lang) => {
  //     let languagePartials = navigator.language.split("-")[0];

  //     if (lang == languagePartials) {
  //       matched = lang;
  //     }
  //   });
  // }
  // if (!matched) {
  //   languages.forEach((lang) => {
  //     let languagePartials = navigator.language.split("-")[0];
  //     if (lang.split("-")[0] === languagePartials) {
  //       matched = lang;
  //     }
  //   });
  // }
  // if (!matched) {
  //   matched = "en";
  // }

  return sessionStorage.getItem("langLocal") ?? "en";
}

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const messages = {};
  const locales = [
    "en.json",
    "de.json", // Add other locales as needed
  ];

  locales.forEach((localeFile) => {
    const matched = localeFile.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      // Import the locale file using dynamic import
      const module = require(`./locales/${localeFile}`);
      messages[locale] = module.default;
    }
  });

  return messages;
}

export default createI18n({
  locale: checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
