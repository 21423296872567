import { createRouter, createWebHistory } from "vue-router";

// import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Admin from "./views/AdminSettings/Admin.vue";
import Overview from "./views/Overview/Overview.vue";
import CreateOrUpdateUser from "./views/AdminSettings/CreateOrUpdateUser/CreateOrUpdateUser.vue";
import NotFound from "./views/NotFound.vue";
import SignUp from "./views/SignUp.vue";
import SetPassword from "./views/SetPassword.vue";

import store from "./store";
import ReportPdf from "./views/ReportPdf";
import GreetingsPage from "./views/GreetingsPage";
import DashboardJob from "./views/DashboardJob/index.vue";
import ClientDetailsPage from "./views/ClientDetailsPage/index.vue";
import ClientsOverview from "./views/ClientsOverview/index.vue";
import AtsSettings from "./views/AtsSettings.vue";
import JobsList from "./views/JobClustering/JobsList/JobsList.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Home",
      component: Login,
      meta: { requiresAuth: true },
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { requiresUnauth: true },
    },
    {
      path: "/admin",
      name: "Admin",
      component: Admin,
      meta: { requiresAuth: true },
    },
    {
      path: "/overview",
      name: "Overview",
      component: Overview,
      meta: { requiresAuth: true },
    },
    {
      path: "/admin/createOrUpdateUser/:userId",
      name: "CrateOrUpdateUser",
      component: CreateOrUpdateUser,
      meta: { requiresAuth: true },
    },
    {
      path: "/signup",
      name: "SignUp",
      component: SignUp,
    },
    {
      path: "/report/:companyId/:jobId",
      name: "PDF",
      component: ReportPdf,
      meta: { requiresAuth: true },
    },
    {
      path: "/greetings",
      name: "GreetingsPage",
      component: GreetingsPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/clients",
      name: "ClientsOverview",
      component: ClientsOverview,
      meta: { requiresAuth: true },
    },
    {
      path: "/clients/:id",
      name: "ClientDetailsPage",
      component: ClientDetailsPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/jobsList",
      name: "JobsList",
      component: JobsList,
      meta: { requiresAuth: true },
    },
    {
      path: "/dashboardJob/:compId/:jobId",
      name: "DashboardJob",
      component: DashboardJob,
      meta: { requiresAuth: true },
    },
    {
      path: "/setpassword",
      name: "SetPassword",
      component: SetPassword,
      meta: { requiresAuth: true },
    },
    {
      path: "/atssettings",
      name: "AtsSettings",
      component: AtsSettings,
      meta: { requiresAuth: true },
    },

    { path: "/:notFound(.*)", component: NotFound },
  ],
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next("/login");
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

export default router;
