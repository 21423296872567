<template>
  <div
    class="flex justify-center"
    :key="label"
    v-for="(label, index) in rowHeadersBars"
  >
    <div class="flex flex-row w-3/5 mb-1">
      <div class="flex-initial w-2/5 pr-5">
        <p class="text-right">{{ $t(`dashboardProjectStatus.${label}`) }}</p>
      </div>
      <div class="flex flex-1 justify-center">
        <div
          class="flex-initial bg-slate-500 rounded"
          :style="calculateWidth(barValue[index][1])"
          style="box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5)"
        >
          <p class="text-center font-semibold text-white">
            {{ barValue[index][1] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["barValue", "rowHeadersBars"],
  mounted() {},
  methods: {
    calculateWidth(value) {
      let percentage = (value / this.barValue[0][1]) * 100;
      let width = `width:${percentage > 90 ? percentage : percentage + 5}%`;
      // let bgColor = `background-color:black`
      return `${width}`;
    },
  },
};
</script>
