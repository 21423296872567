<template>
  <app-header />

  <div class="m-10 ml-96 mr-96">
    <input-field-for-create-user />

    <company-list-for-create-user />

    <div class="divider"></div>

    <assigned-companies-for-create-user />

    <!--------------------------------- BUTTON PART --------------------------------->
    <div class="flex flex-1 justify-end mt-7">
      <submit-button-for-create-user />
    </div>

    <assign-new-company-modal v-if="isModal == 'addCompany'" />

    <message-modal
      message="User created/edited successfully"
      redirectUrl="/admin"
      v-if="isModal == 'succesModal'"
    />
  </div>
</template>

<script>
import AppHeader from "../../../components/layout/header/AppHeader.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import AssignNewCompanyModal from "./Overview/Modal/AssignNewCompanyModal.vue";
import MessageModal from "../../../components/Modals/MessageModal/MessageModal.vue";
import InputFieldForCreateUser from "./Overview/InputFieldForCreateUser.vue";
import CompanyListForCreateUser from "./Overview/CompanyListForCreateUser.vue";
import AssignedCompaniesForCreateUser from "./Overview/AssignedCompaniesForCreateUser.vue";
import SubmitButtonForCreateUser from "./Overview/SubmitButtonForCreateUser.vue";

export default {
  beforeRouteLeave() {
    this.resetState();
  },
  mounted() {
    this.fetchAllCompanies();
    this.fetchUserRoles();
    // if edit user then fetch all the details of user
    if (this.$route.params.userId !== "0") {
      this.fetchUserDetails(this.$route.params.userId);
    }
  },
  watch: {
    assignedCompaniesList() {
      this.filterCompaniesForList();
    },
  },
  computed: {
    role() {
      return this.controls.role.value;
    },
    ...mapGetters([
      "authorizationHeader",
      "backendUrl",
      "controls",
      "allCompaniesList",
      "selectedAddEditCompanyName",
      "selectedAddEditCompanyId",
      "selectedEditJobsList",
      "isModal",
      "assignedCompaniesList",
      "roles",
    ]),
  },
  components: {
    AppHeader,
    AssignNewCompanyModal,
    MessageModal,
    CompanyListForCreateUser,
    InputFieldForCreateUser,
    AssignedCompaniesForCreateUser,
    SubmitButtonForCreateUser,
  },
  methods: {
    ...mapActions([
      "setControls",
      "setAllCompaniesList",
      "setSelectedAddEditCompanyName",
      "setSelectedAddEditCompanyId",
      "setSelectedEditJobsList",
      "setIsModal",
      "setAssignedCompaniesList",
      "setRoles",
      "resetState",
    ]),

    filterCompaniesForList() {
      // this function will remove those companies that are already assigned to user from all companies list
      let companyNames = this.assignedCompaniesList.map((value) => {
        return value.company_name;
      });
      let filteredCompanies = this.allCompaniesList.filter(
        (company) => !companyNames.includes(company.name)
      );
      this.setAllCompaniesList([...filteredCompanies]);
    },

    fetchAllCompanies() {
      let companies = this.$store.getters.companies;
      this.setAllCompaniesList([...companies]);
      this.filterCompaniesForList();
    },

    fetchUserRoles() {
      const headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + "/auth/role/list", { headers })
        .then((response) => {
          let roles = response.data;
          this.setRoles(roles);
        })
        .catch((error) => console.log(error));
    },

    fetchUserDetails(id) {
      const headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + `/auth/user/detail?userId=${id}`, { headers })
        .then((response) => {
          let details = response.data;
          let tempControls = { ...this.controls };
          // assign values to control object
          tempControls.role.value = details.roles[0].name;
          tempControls["first-name"].value = details.name.split(" ")[0];
          tempControls["last-name"].value = details.name
            .split(" ")
            .slice(1)
            .join(" ");
          tempControls["email-address"].value = details.mail;
          this.setControls({ ...tempControls });

          // assign companies and jobs list if user is not admin
          if (details.access) {
            details.access.map((singleCompany) => {
              let newValue = {
                jobs: [...singleCompany.jobInfoList],
                ["company_name"]: singleCompany.companyInfo.name,
                ["company_id"]: singleCompany.companyInfo.id,
              };
              let assignedCompaniesList = [
                ...this.assignedCompaniesList,
                newValue,
              ];
              this.setAssignedCompaniesList([...assignedCompaniesList]);
            });
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
