<template>
  <div
    class="w-100 sm:w-2/5 h-[26rem] rounded-lg bg-gray-200 pt-4 p-2 mr-10 sm:mr-0 shadow-xl"
  >
    <div class="flex flex-row justify-between">
      <div class="flex flex-row" :class="isAdmin ? 'flex-1' : 'w-11/12'">
        <logo-container
          :initials="companyInitial(company.name)"
          :childStyle="bg"
        ></logo-container>
        <div class="flex flex-col justify-center ml-2 w-full">
          <div
            class="mb-3 text-2xl w-2/3"
            :class="$store.state.auth.isPresentationMode ? 'blur-effect' : null"
          >
            {{ company.name }}
          </div>
          <div
            :class="$store.state.auth.isPresentationMode ? 'blur-effect' : null"
          >
            <span class="font-semibold">Address:</span> {{ address }}
          </div>
          <div>
            <span class="font-semibold">Status:</span>
            {{ companyInformation.status }}
          </div>

          <div class="mt-3">
            <hashtag-add-or-edit
              :hashtags="companyTags"
              :categories="tagCategories"
              @onHashtagClick="onHashtagClick"
            />
          </div>

          <div class="flex flex-1 flex-col bg-white rounded-lg w-full mt-5 p-3">
            <div class="flex flex-row justify-between flex-1">
              <div class="flex flex-col justify-between flex-1">
                <p class="mb-3 font-semibold">
                  {{ $t("add-new-job.company-point-of-contact") }}:
                </p>
                <div class="grid 2xl:grid-cols-2 2xl:gap-2 grid-cols-1 gap-1">
                  <button
                    v-for="singleContact in companyContactDetails"
                    :key="singleContact"
                    :class="
                      $store.state.auth.isPresentationMode
                        ? 'blur-effect'
                        : null
                    "
                    class="bg-gray-200 hover:bg-gray-400 text-gray-800 text-sm py-1 px-4 border border-gray-400 rounded-lg mb-1 shadow-xl"
                    @click="
                      $emit('onOpenOrCreateContactDetails', singleContact)
                    "
                    :disabled="!isAdmin"
                  >
                    {{ singleContact.first_name }}
                    {{ singleContact.last_name }}
                    ({{ singleContact.email }})
                  </button>
                </div>
              </div>

              <div class="flex flex-row">
                <button
                  v-if="isAdmin"
                  @click="$emit('onOpenOrCreateContactDetails')"
                  class="text-black flex flex-row h-8 text-sm font-semibold"
                >
                  <PlusCircleIcon
                    class="w-5 h-5 hover:text-gray-500 text-black"
                  />
                </button>
                <button
                  v-if="isAdmin"
                  @click="
                    $emit('onOpenContactsImport', '0', companyContactDetails)
                  "
                  class="text-black flex flex-row h-8 text-sm font-semibold ml-2"
                >
                  <SortDescendingIcon
                    class="w-6 h-6 hover:text-gray-500 text-black"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        @mouseover="infoBoxForEdit = true"
        @mouseleave="infoBoxForEdit = false"
        v-if="isAdmin"
        class="btn btn-ghost btn-circle"
      >
        <DotsHorizontalIcon
          @click="onShowEditMenu"
          class="w-7 h-7 text-gray-700 mx:5"
        />
      </div>

      <div
        class="absolute top-48 mt-5 left-1/3 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
        v-if="showEditMenu"
      >
        <div class="py-1" role="none">
          <a
            href="#"
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
            @click="$emit('onOpenCompanyDetails'), closeEditMeu()"
          >
            {{ $t("common-elements.modify") }}
          </a>
          <div class="divider p-0 m-0"></div>
          <a
            href="#"
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
            @click="$emit('onDeleteConfirm'), closeEditMeu()"
          >
            {{ $t("common-elements.delete") }}
          </a>
        </div>
      </div>
    </div>

    <add-or-remove-hash-tags-modal
      :details="companyDetails"
      v-if="isModal == 'AddOrRemoveHashtags'"
      @onHashtagSave="onHashtagSave"
    />
  </div>
</template>
<script>
import {
  PlusCircleIcon,
  DotsHorizontalIcon,
  SortDescendingIcon,
} from "@heroicons/vue/solid";
import LogoContainer from "../../components/ui/LogoContainer.vue";
import { mapGetters } from "vuex";
import HashtagAddOrEdit from "../../components/ui/HashtagAddOrEdit.vue";
import AddOrRemoveHashTagsModal from "../../components/Modals/AddOrRemoveHashtags/AddOrRemoveHashTagsModal.vue";
import {
  removeCompleteCompanyDataFromCache,
  removeGeneralDataInCache,
} from "../../components/helperFunctions/DataCaching";

export default {
  props: ["companyContactDetails", "companyInformation"],
  components: {
    PlusCircleIcon,
    SortDescendingIcon,
    DotsHorizontalIcon,
    LogoContainer,
    HashtagAddOrEdit,
    AddOrRemoveHashTagsModal,
  },
  data() {
    return {
      initials: "",
      bg: { background: "whitesmoke", width: "5rem", height: "5rem" },
      isModal: false,
      showEditMenu: false,
      infoBoxForEdit: false,
    };
  },
  watch: {
    company(newVal) {
      this.companyInitial(newVal);
    },
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    company() {
      return this.$store.getters.company;
    },
    filteredActiveJobs() {
      return this.$store.getters.filteredActiveJobs;
    },
    activeJobs() {
      // logic to remove repeated jobs
      let nonRepeatableJobs = this.$store.getters.activeJobs.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      return nonRepeatableJobs;
    },
    address() {
      let { street, street_number, zip, po_box, country, city } =
        this.companyInformation;
      return `${street ?? "-"} ${street_number ?? ""} ${zip ?? ""} ${
        po_box ?? ""
      } ${city ?? ""} ${country ?? ""}`;
    },
    companyTags() {
      if (
        this.companyInformation.company_tags &&
        this.companyInformation.company_tags !== "null"
      ) {
        const keyValuePairs = this.companyInformation.company_tags
          .slice(1, -1)
          .split(",");

        return keyValuePairs.map((pair) => pair.split(":")[0].trim()); // Extract the keys and remove leading/trailing spaces
      }
      return [];
    },
    tagCategories() {
      if (
        this.companyInformation.company_tags &&
        this.companyInformation.company_tags !== "null"
      ) {
        const keyValuePairs = this.companyInformation.company_tags
          .slice(1, -1)
          .split(",");

        return keyValuePairs.map((pair) => pair.split(":")[1].trim());
      }
      return [];
    },
    companyDetails() {
      return {
        ...this.companyInformation,
        job_tags: this.companyTags,
        tag_category: this.tagCategories,
      };
    },
  },
  methods: {
    onModalClose() {
      this.isModal = "";
    },
    onHashtagClick() {
      this.isModal = "AddOrRemoveHashtags";
    },
    onHashtagSave() {
      this.isModal = "";
      removeGeneralDataInCache("allTags");
      removeGeneralDataInCache("allJobs");
      removeCompleteCompanyDataFromCache(this.companyInformation.company_id);
      this.$router.go();
    },

    onShowEditMenu() {
      this.showEditMenu = !this.showEditMenu;
    },
    companyInitial(name) {
      if (name) {
        return (this.initials = name
          .toString()
          .replace(/[\u200B-\u200D\uFEFF]/, "")
          .substring(0, 1));
      }
    },
    closeEditMeu() {
      this.showEditMenu = !this.showEditMenu;
    },
  },
};
</script>

<style scoped>
.info-box {
  background-color: #fefefe;
  margin: 5px;
  padding: 5px;
  /* border: 1px solid #888; */
  width: fit-content;
  block-size: fit-content;
  position: absolute;
}
</style>
