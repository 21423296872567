<template>
  <div
    id="report"
    class="scale-10 bg-white p-5 -inset-x-0 overflow-visible pdfReport"
  >
    <div
      class="flex flex-row border-b-2 border-gray-500 pb-5 mb-10"
      v-if="true"
    >
      <div class="flex items-end flex-wrap">
        <img
          class="h-12 w-auto"
          src="@/assets/logo_transparent.png"
          alt="Kooku Logo"
        />
      </div>
      <div
        class="flex justify-center items-center text-xl font-semibold w-full flex-1"
      >
        <p :class="ongoingPrint ? 'text-3xl' : 'text-2xl'">
          <span
            :class="$store.state.auth.isPresentationMode ? 'blur-effect' : null"
            >{{ $route.query.number }}</span
          >
          -
          {{ $route.query.name }}
        </p>
      </div>
      <div class="flex justify-end flex-wrap">
        <button
          type="button"
          v-if="!ongoingPrint && !isRoleClient"
          class="float-right m-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
          @click="onSavePdf"
        >
          <p>Send PDF to Client</p>
        </button>
        <button
          type="button"
          v-if="!ongoingPrint"
          class="float-right m-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
          @click="print"
        >
          <PrinterIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          v-if="!ongoingPrint"
          class="float-right m-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 shadow-md"
          @click="close"
        >
          <XIcon class="h-5 w-5" aria-hidden="true" />
        </button>

        <p v-if="ongoingPrint" class="text-sm">{{ calculateDateTime }}</p>
      </div>
    </div>

    <div
      class="container flex flex-row inset-x-0 gap-10 w-full max-w-full"
      :class="ongoingPrint ? 'mb-32' : 'mb-10'"
    >
      <table-widget
        @onResponseToParent="onSaveResponse"
        v-for="path in apiPathsSmallItems"
        :key="path"
        :path="path"
        :ongoingPrint="ongoingPrint"
        class="flex-auto"
        :selectedDate="$route.query.days"
      />

      <name-candidates-pdf-widget
        :ongoingPrint="ongoingPrint"
        @onResponseToParent="onSaveResponse"
        :path="apiNameCandidates"
      />
    </div>
    <div v-if="!ongoingPrint">
      <span class="label-text italic flex flex-1 flex-row">
        <InformationCircleIcon class="h-5 w-5 mr-2" aria-hidden="true" />
        {{ $t("report-pdf.message-to-change-from-graph-to-table") }}
      </span>
    </div>

    <!-- this div is only visible when page is not in printing mode  -->
    <div v-if="!ongoingPrint">
      <pdf-report-table-and-graph-view
        @onResponseToParent="onSaveResponse"
        :ongoingPrint="ongoingPrint"
        @onToggleChange="onToggle"
      />
    </div>

    <!-- this div is only visible when page is in printing mode  -->
    <div
      v-if="ongoingPrint"
      class="container flex-1 max-w-full flex flex-row inset-x-0 w-full"
      :class="isGraph ? 'gap-2' : 'gap-10'"
    >
      <conversion-rate-widget
        v-if="isGraph"
        isPdfReport="true"
        loadingToggle="false"
      />

      <table-widget
        v-for="path in apiPathsLargeItems"
        @onResponseToParent="onSaveResponse"
        :key="path"
        :path="path"
        class="flex-auto"
        :ongoingPrint="ongoingPrint"
        isLargeItems="true"
        :selectedDate="$route.query.days"
      />
    </div>

    <PDF-note-modal
      :loader="loader"
      v-if="isModal == 'PDFNote'"
      @onModalClose="onModalClose"
      @onSubmit="onUploadPDF"
    />
  </div>
</template>

<style scoped>
@media print {
  @page {
    size: landscape;
    margin: 20px;
  }
  .pdfReport {
    zoom: 55%;
  }
}
</style>

<script>
import TableWidget from "../components/widgets/TableWidget.vue";
import {
  PrinterIcon,
  XIcon,
  InformationCircleIcon,
} from "@heroicons/vue/outline";
import { nextTick } from "vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import NameCandidatesPdfWidget from "../components/widgets/NameCandidatesPdfWidget.vue";
import ConversionRateWidget from "./DashboardJob/ConversionRateWidget/ConversionRateWidget.vue";
import PdfReportTableAndGraphView from "../components/layout/PdfReportTableAndGraphView.vue";
import PDFNoteModal from "../components/Modals/PDFReportNote/PDFNoteModal.vue";
import { companyDataInCache } from "../components/helperFunctions/DataCaching";

export default {
  name: "Report",
  created() {
    this.resetPendingApiCount(0);
  },
  computed: {
    apiPathsLargeItems() {
      return this.isGraph
        ? ["/widget/target-de-facto-comparison"]
        : ["/report/project-analysis", "/widget/target-de-facto-comparison"];
    },
    currentJobId() {
      return this.$route.params.jobId;
    },
    calculateDateTime() {
      let dateObject = new Date();
      let date = dateObject.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
      return `${date}`;
    },
    ...mapGetters([
      "authorizationHeader",
      "backendUrl",
      "isRoleClient",
      "pendingApisCount",
    ]),
    ...mapGetters("job", ["projectDetails"]),
  },
  components: {
    TableWidget,
    PrinterIcon,
    XIcon,
    NameCandidatesPdfWidget,
    ConversionRateWidget,
    PdfReportTableAndGraphView,
    InformationCircleIcon,
    PDFNoteModal,
  },
  methods: {
    ...mapActions(["resetPendingApiCount"]),

    onSaveResponse(data) {
      data.row_headers.map((singleHeader, index) => {
        this.completePDFData = {
          ...this.completePDFData,
          [singleHeader]: data.table_data[index],
        };
      });
    },
    onSavePdf() {
      if ("email" in this.clientDetails) this.isModal = "PDFNote";
      else alert("Sorry, No client email is exist for this company");
    },
    onUploadPDF(note) {
      this.loader = true;
      this.completePDFData = {
        ...this.completePDFData,
        fileName: this.documentTitle,
        timeTrackingDateRange: this.getDateRange(),
        jobId: this.currentJobId,
        clientEmail: this.clientDetails.email,
        clientName: `${this.clientDetails.first_name} ${this.clientDetails.last_name}`,
        note,
      };
      let headers = this.authorizationHeader;
      axios
        .post(this.backendUrl + "/job/uploadPDFReport", this.completePDFData, {
          headers,
        })
        .then(() => {
          alert("PDF report save successfully!");
          this.isModal = "";
          this.loader = false;
        })
        .catch((error) => {
          alert("Something went wrong. Please check your input!");
          console.log(error);
        });
    },
    getDateRange() {
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);

      const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      };

      const formattedStartDate = formatDate(sevenDaysAgo);
      const formattedEndDate = formatDate(today);

      return `${formattedStartDate} - ${formattedEndDate}`;
    },
    onToggle() {
      this.isGraph = !this.isGraph;
    },
    close() {
      window.close();
    },
    onModalClose() {
      this.isModal = "";
    },
    print() {
      this.ongoingPrint = true;
      let hubspot = document.getElementById(
        "hubspot-messages-iframe-container"
      );
      hubspot.style.visibility = "hidden";
      // Execute the nextTick to ensure that the DOM has updated before checking API completion
      nextTick(() => {
        // Set up an interval to periodically check if pending APIs are completed
        let apiCheckInterval = setInterval(() => {
          if (this.pendingApisCount == 0) {
            // set timeout so that graphs are fully loaded
            setTimeout(() => {
              window.print();
              // set settings to default after print
              this.isGraph = true;
              this.ongoingPrint = false;
              hubspot.style.visibility = "visible";
            }, 500);
            clearInterval(apiCheckInterval);
          }
        }, 100);
      });
    },
    getWeekNumber(d) {
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      // Return array of year and week number
      // return [d.getUTCFullYear(), weekNo];
      return String(weekNo);
    },
    getStartAndEndDate() {
      let first = new Date();
      first.setDate(first.getDate() - 7);
      let firstday = first.toUTCString().split(" ");
      let lastday = new Date().toUTCString().split(" ");
      return `_${firstday[2]}-${firstday[1]}-${firstday[3]}_to_${lastday[2]}-${lastday[1]}-${lastday[3]}`;
    },
    getContactPersonsWidget() {
      let headers = this.authorizationHeader;
      axios
        .get(
          this.backendUrl +
            "/widget/contact-persons" +
            "?jobId=" +
            this.currentJobId,
          {
            headers,
          }
        )
        .then((response) => {
          this.contactPersonsResponse = response.data;
        })
        .catch((error) => console.log(error));
    },
    getClientDetails() {
      let contacts = companyDataInCache(
        this.$route.params.companyId,
        "contacts"
      );
      // Step 2: Filter the contacts to find those with job_id equal to "0" means company contact (assuming job_id is a string)
      let clientDetails = contacts.filter((contact) => contact.job_id === "0");

      // Step 3: Set the component's 'clientDetails' property
      // If there are matching clientDetails, use the first one; otherwise, set an empty object
      this.clientDetails = clientDetails.length > 0 ? clientDetails[0] : {};
    },
  },
  data() {
    return {
      isGraph: true,
      apiPathsSmallItems: [
        "/report/project-properties",
        "/report/timeframe",
        `/report/time-tracking-date-range`,
      ],
      apiNameCandidates: "/report/candidates-in-process",
      ongoingPrint: false,
      contactPersonsResponse: null,
      documentTitle: "",
      completePDFData: {},
      isModal: "",
      clientDetails: {},
      loader: false,
    };
  },

  mounted() {
    this.documentTitle =
      "Projekt-Reporting-" +
      this.currentJobId +
      "_KW-" +
      this.getWeekNumber(new Date()) +
      this.getStartAndEndDate();
    document.title = this.documentTitle;
    this.getContactPersonsWidget();
    this.getDateRange();
    this.getClientDetails();
  },
};
</script>
