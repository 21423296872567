<template>
  <div
    class="h-72 w-64 bg-white rounded-lg pl-5 pr-3 pt-2 flex flex-col justify-around pb-4 mt-5 ml-3"
  >
    <div class="h-6 w-44 bg-gray-400 ml-4 mr-1 mt-3 mb-2 rounded-lg wave"></div>
    <div class="h-3 w-32 bg-gray-400 ml-4 mb-7 rounded-lg wave"></div>
    <div class="h-8 w-28 bg-gray-400 rounded-lg wave ml-4 mb-2"></div>
    <div class="h-8 w-20 bg-gray-400 rounded-lg wave ml-4 mb-2"></div>
    <div class="h-8 w-28 bg-gray-400 rounded-lg wave ml-4 mb-5"></div>
    <div class="mt-2 mr-2 h-12 bg-gray-100 rounded-full w-40 wave ml-4"></div>
  </div>
</template>
<style scoped>
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
