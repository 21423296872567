<template>
  <div
    class="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 bg-gray-50"
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <img
          class="mx-auto h-12 w-auto"
          src="@/assets/logo_transparent.png"
          alt="Kooku Logo"
        />
      </div>
      <p class="my-3 text-center">{{ $t("setpassword-page.description") }}</p>

      <form action="submit" class="bg-white p-7 rounded-lg">
        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="password"
          >
            {{ $t("setpassword-page.password") }}
          </label>

          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            v-model.trim="password"
            autocomplete="off"
            placeholder="******************"
            :style="errorBorderColor(invalidPassword)"
          />

          <p v-if="invalidPassword" class="text-red-500 text-xs italic">
            {{ $t("setpassword-page.password-error-message") }}
          </p>
        </div>

        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="repPassword"
          >
            {{ $t("setpassword-page.repeat-password") }}
          </label>

          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="repPassword"
            type="password"
            v-model.trim="repeatedPassword"
            autocomplete="off"
            placeholder="******************"
            :style="errorBorderColor(invalidRepeatedPassword)"
          />

          <p v-if="invalidRepeatedPassword" class="text-red-500 text-xs italic">
            {{ $t("setpassword-page.repeat-password-error-message") }}
          </p>
        </div>

        <div>
          <button
            @click="setPassword"
            type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            {{ $t("setpassword-page.signup") }}asd
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      password: "",
      error: null,
      repeatedPassword: "",
      invalidPassword: false,
      invalidRepeatedPassword: false,
      validForm: true,
    };
  },
  computed: {
    ...mapGetters(["backendUrl"]),
  },
  methods: {
    errorBorderColor(invalidField) {
      return { borderColor: invalidField ? "red" : "black" };
    },
    setPassword(e) {
      e.preventDefault();
      this.error = null;
      this.validForm = true;
      const setPasswordData = {
        password: this.password,
        repeatedPassword: this.repeatedPassword,
      };

      if (this.password.length < 3) {
        this.invalidPassword = true;
        this.validForm = false;
      } else {
        this.invalidPassword = false;
      }
      if (this.password !== this.repeatedPassword) {
        this.invalidRepeatedPassword = true;
        this.validForm = false;
      } else {
        this.invalidRepeatedPassword = false;
      }

      if (this.validForm) {
        //TODO check for content type, possible secure issue

        axios
          .post(this.backendUrl + "/user/setPassword", setPasswordData)
          .then(() => {
            this.$router.push("/clients");
          })
          .catch((error) => {
            this.error =
              error.message || "Authorization Failed. Please try again.";
            console.log(error.message);
          });
      }
    },
  },
};
</script>
