export default {
  setToken(state, payload) {
    state.accessToken = payload.accessToken;
    state.refreshToken = payload.refreshToken;
  },
  setAutoLogout(state) {
    state.didAutoLogout = true;
  },
  setUserInfo(state, payload) {
    state.user.mail = payload.mail;
    state.user.name = payload.name;
    state.user.isAdmin = payload.isAdmin;
    state.user.roleName = payload.roleName;
  },
  setActualUserDetails(state, payload) {
    state.actualUserDetails.mail = payload.mail;
    state.actualUserDetails.name = payload.name;
    state.actualUserDetails.isAdmin = payload.isAdmin;
    state.actualUserDetails.roleName = payload.roleName;
  },
  setNoShowAtsConnector(state, payload) {
    state.user.noShowAtsConnector = payload;
    localStorage.setItem("noShowAtsConnectorLS", payload);
  },
  setPresentationMode(state, payload) {
    state.isPresentationMode = payload;
  },
};
