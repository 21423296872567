<template>
  <div class="flex flex-row flex-wrap justify-start">
    <div
      class="
        shadow
        rounded-lg
        bg-gray-50
        flex flex-col
        items-center
        p-2
        mt-4
        mb-4
        ml-3
        w-64
        mx-3
      "
      v-for="num in 8"
      :key="num"
    >
      <div class="flex flex-row items-center justify-between my-2 w-full">
        <div class="h-5 w-48 bg-gray-300 mx-6 px-2 rounded-lg wave"></div>
        <div class="h-5 w-5 bg-gray-300 mr-6 px-2 rounded-full wave"></div>
      </div>
      <div class="h-3 w-48 bg-gray-300 mx-6 px-2 mt-4 rounded-lg wave"></div>
      <div class="flex flex-col justify-between p-2">
        <svg width="240" height="240">
          <g>
            <title>Layer blue</title>
            <circle
              id="circle"
              style="stroke-dashoffset: 0; /* 471 */"
              r="75"
              cy="120"
              cx="120"
              stroke-width="30"
              stroke="rgb(209 213 219)"
              fill="none"
            />
          </g>
        </svg>
      </div>
      <div class="h-2 w-40 bg-gray-300 mx-6 px-2 mb-2 rounded-lg wave"></div>
      <div class="h-2 w-40 bg-gray-300 mx-6 px-2 mb-2 rounded-lg wave"></div>
      <div class="h-2 w-40 bg-gray-300 mx-6 px-2 mb-3 rounded-lg wave"></div>
    </div>
  </div>
</template>
<style scoped>
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
