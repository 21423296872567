<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative inline-block w-64 m-9 ml-10" v-if="isSuperAdmin">
    <select
      class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded-md leading-tight focus:outline-none focus:ring focus:border-blue-300"
      @change="onUserSelect"
    >
      <option value="">Select a User</option>
      <option v-for="option in userList" :key="option.id" :value="option.id">
        {{ option.name }}
      </option>
    </select>
  </div>

  <div>
    <employee-conversion-rate :username="selectedUser.name" />
  </div>

  <div>
    <employee-time-expenditure :username="selectedUser.name" />
  </div>
</template>

<script>
import { getGeneralDataInCache } from "../../../components/helperFunctions/DataCaching";
import EmployeeConversionRate from "./EmployeeConversionRate.vue";
import EmployeeTimeExpenditure from "./EmployeeTimeExpenditure.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userList: [],
      selectedUser: { name: getGeneralDataInCache("user").name },
    };
  },
  methods: {
    onUserSelect(e) {
      const filterUser = this.userList.filter(
        (user) => user.id === parseInt(e.target.value)
      );
      this.selectedUser = { ...filterUser[0] };
    },
  },
  computed: {
    ...mapGetters(["isSuperAdmin"]),
  },
  mounted() {
    if (this.isSuperAdmin) {
      this.userList = [...getGeneralDataInCache("userList")];
    }
  },
  components: {
    EmployeeConversionRate,
    EmployeeTimeExpenditure,
  },
};
</script>
