<template>
  <table class="w-full h-full absolute top-0">
    <tr>
      <td
        v-for="(num, index) in totalProjectDays"
        :key="num"
        :class="
          (index == 0 && ' border-l border-black') ||
          (index == totalProjectDays - 1 && ' border-r border-black')
        "
      >
        <div
          class="h-full flex flex-col datesLabel absolute top-0 ml-1"
          v-if="index == 0"
        >
          <p>{{ rowHeaders[2] }}</p>
          <span v-if="remainingDays == 0"
            >{{ rowHeaders[3] }} {{ startDate }}</span
          >
          <span v-else>{{ startDate }}</span>
        </div>
      </td>
    </tr>
  </table>

  <div class="w-full h-full absolute top-0">
    <div
      :style="calculateTodayLine"
      class="border-l border-gray-500 h-full flex flex-col datesLabel absolute top-0"
    >
      <p class="text-gray-500">{{ rowHeaders[3] }}</p>
      <span class="text-gray-500">{{ today }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "remainingDays",
    "today",
    "totalProjectDays",
    "startDate",
    "rowHeaders",
    "endDate",
    "todayPercentage",
  ],
  computed: {
    calculateTodayLine() {
      let marginLeftValue =
        new Date(this.today) > new Date(this.startDate)
          ? this.todayPercentage
          : 0;

      let displayValue =
        this.todayPercentage >= 100 || marginLeftValue == 0 ? "none" : "";
      let styleCss = `margin-left: ${marginLeftValue}%; display: ${displayValue}`;

      return styleCss;
    },
  },
};
</script>
<style scoped>
.datesLabel {
  font-size: 9px;
}
</style>
