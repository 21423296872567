<template>
  <div>
    <div class="flex ...">
      <div
        v-for="value in [1, 2, 3, 4]"
        :key="value"
        class="h-1 w-1/4 mt-5"
        :class="cssForLine(value)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["step"],
  methods: {
    cssForLine(index) {
      if (this.step >= index) {
        return `bg-green-500 border-r-8 border-green-700`;
      } else {
        return `bg-gray-400 border-r-8 border-gray-600`;
      }
    },
  },
};
</script>
