export default {
  openSidebar(context) {
    context.commit("openSidebar");
  },
  closeSidebar(context) {
    context.commit("closeSidebar");
  },
  setSelectedView(context, payload) {
    context.commit("setSelectedView", payload);
  },
  setSelectedViewDashboard(context, payload) {
    context.commit("setSelectedViewDashboard", payload);
  },
};
