<template>
  <div
    class="realtive z-10 flex-shrink-0 flex h-18 shadow-md glass-effect bg-white"
    id="appHeader"
  >
    <button
      class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 sidebarHide shadow-md"
      @click="openSidebar"
    >
      <span class="sr-only">Open sidebar</span>
      <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
    </button>

    <div class="flex-1 px-4 flex my-2 justify-between">
      <div class="mt-4 flex flex-wrap items-center md:mt-0 md:ml-2">
        <img
          class="h-9 w-auto mr-4 cursor-pointer"
          src="@/assets/logo_transparent.png"
          alt="Kooku Logo"
          @click="goBackToClientsOverview()"
        />

        <company-select-menu
          v-if="
            (currentRouteName === 'DashboardJob' ||
              currentRouteName === 'Home') &&
            !isPresentationMode &&
            !isRoleClient
          "
        />

        <single-client-selector
          v-if="
            (currentRouteName == 'ClientsOverview' ||
              currentRouteName == 'ClientDetailsPage') &&
            !isPresentationMode &&
            !isRoleClient
          "
        />

        <div
          class="flex mx-3 mb-1 items-center"
          v-if="
            (currentRouteName === 'ClientDetailsPage' ||
              currentRouteName === 'DashboardJob') &&
            !isPresentationMode &&
            !isRoleClient
          "
        >
          <p>____</p>
        </div>
        <jobs-client-selector
          v-if="
            currentRouteName === 'ClientDetailsPage' &&
            !isPresentationMode &&
            !isRoleClient
          "
        />
        <job-select-menu
          v-if="
            (currentRouteName === 'DashboardJob' ||
              currentRouteName === 'Home') &&
            !isPresentationMode &&
            !isRoleClient
          "
        />
      </div>

      <div
        class="absolute right-36 mt-3 rounded-xl p-2 px-5 bg-gray-400 hover:bg-[#2197a7] cursor-pointer"
        @click="onBackToAdmin"
        v-if="isSuperAdmin"
      >
        <p class="font-semibold text-white">Back To Admin</p>
      </div>

      <div
        class="absolute right-36 mt-3 rounded-xl p-2 px-5 bg-gray-400 hover:bg-[#2197a7] cursor-pointer"
        @click="onOpenModal('feedbackModal')"
        v-else-if="isAdmin || isRoleUser"
      >
        <p class="font-semibold text-white">
          {{ $t("common-elements.Give UI Feedback") }}
        </p>
      </div>

      <div
        class="absolute right-36 mt-3 rounded-xl p-2 px-5 primary-button cursor-pointer"
        @click="onOpenModal('requestNewProjectModal')"
        v-if="isRoleClient"
      >
        <p class="font-semibold text-white">
          {{ $t("common-elements.RequestNewProject") }}
        </p>
      </div>

      <div class="m-2 mr-5 absolute right-1 logoMobile">
        <img
          class="h-5 w-auto"
          src="@/assets/logo_transparent.png"
          alt="Kooku Logo"
        />
      </div>
      <div class="flex flex-row">
        <user-dropdown-menu />
      </div>

      <feedback-modal
        @onModalClose="onModalClose"
        v-if="isModal == 'feedbackModal'"
      />

      <request-new-job-modal
        @onModalClose="onModalClose"
        v-if="isModal == 'requestNewProjectModal'"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MenuAlt2Icon } from "@heroicons/vue/outline";

import JobSelectMenu from "./JobSelectMenu.vue";
import CompanySelectMenu from "./CompanySelectMenu.vue";
import UserDropdownMenu from "./UserDropdownMenu.vue";
import SingleClientSelector from "../../../views/ClientDetailsPage/SingleClientSelector.vue";
import JobsClientSelector from "../../../views/ClientDetailsPage/JobsClientSelector.vue";
import FeedbackModal from "../../Modals/FeedbackModal/FeedbackModal.vue";
import RequestNewJobModal from "../../Modals/RequestNewJob/RequestNewJobModal.vue";
import {
  getGeneralDataInCache,
  removeGeneralDataInCache,
  storeGeneralDataInCache,
} from "../../helperFunctions/DataCaching";

export default {
  data() {
    return {
      isModal: "",
    };
  },
  components: {
    MenuAlt2Icon,
    RequestNewJobModal,
    JobSelectMenu,
    CompanySelectMenu,
    UserDropdownMenu,
    SingleClientSelector,
    JobsClientSelector,
    FeedbackModal,
  },
  computed: {
    isAdminValue() {
      return this.$store.getters.isAdmin;
    },
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters([
      "isPresentationMode",
      "isRoleClient",
      "isSuperAdmin",
      "isRoleUser",
      "isAdmin",
    ]),
  },
  methods: {
    ...mapActions([
      "toggleProjectPreferences",
      "setUserInfo",
      "setActualUserDetails",
    ]),
    ...mapActions("sidebar", ["openSidebar"]),
    onBackToAdmin() {
      if (getGeneralDataInCache("actualSuperAdmin")) {
        let userInfo = getGeneralDataInCache("actualSuperAdmin");
        let tempUser = {
          isAdmin: userInfo.admin,
          mail: userInfo.mail,
          name: userInfo.name,
          roleName: userInfo.roleName,
        };
        removeGeneralDataInCache("actualSuperAdmin");
        storeGeneralDataInCache("user", tempUser);

        this.setUserInfo({ ...tempUser });
        this.setActualUserDetails({ ...tempUser });
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
    redirectToAdminPage() {
      this.$router.replace("/admin");
    },
    goBackToClientsOverview() {
      this.$router.push("/clients");
    },
    // generic function of model open on button click (pass modal name in parameter)
    onOpenModal(modalName) {
      this.isModal = modalName;
    },
    // function to close modal if want to close without timeout then set porameter to true
    onModalClose(isFromCloseButton) {
      setTimeout(
        () => {
          this.isModal = "";
        },
        isFromCloseButton ? 0 : 2000
      );
    },
  },
};
</script>

<style>
@media print {
  #appHeader {
    display: none;
  }
}
@media only screen and (max-width: 924px) {
  .rightMenu {
    display: none;
  }
  .logoMobile {
    display: block;
  }
}
@media only screen and (min-width: 925px) {
  .sidebarHide {
    display: none;
  }
  .logoMobile {
    display: none;
  }
}
</style>
