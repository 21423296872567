<template>
  <div class="bg-gray-50 rounded-lg my-5 p-3 ml-3 pl-5 mr-4">
    <div class="flex flex-col justify-between">
      <div class="h-5 w-32 bg-gray-400 mt-3 mb-5 rounded-lg wave"></div>

      <div class="flex flex-row">
        <div class="flex flex-col justify-between mr-7 h-56 w-32 mt-4">
          <div class="h-3 w-32 bg-gray-400 my-3 rounded-lg wave"></div>
          <div class="h-3 w-32 bg-gray-400 my-1 rounded-lg wave"></div>
        </div>
        <div class="flex flex-row flex-wrap flex-start">
          <div
            class="flex flex-col w-38 mx-3 relative mt-4 shadow rounded-lg p-2"
            v-for="num in 7"
            :key="num"
          >
            <div class="flex flex-col items-center justify-between h-full">
              <div class="h-2 w-28 bg-gray-400 my-1 rounded-lg wave"></div>
              <div class="h-2 w-24 bg-gray-400 mb-2 rounded-lg wave"></div>
              <div class="progress">
                <div class="barOverflow">
                  <div class="bar"></div>
                </div>
                <div
                  class="h-5 w-10 bg-gray-400 my-0 mx-auto rounded-lg wave"
                ></div>
              </div>
              <div>
                <div class="h-1 w-28 bg-gray-400 mt-4 rounded-lg wave"></div>
                <div class="h-1 w-24 bg-gray-400 my-2 rounded-lg wave"></div>
                <div class="h-1 w-28 bg-gray-400 mb-2 rounded-lg wave"></div>
              </div>
              <div class="h-7 w-20 bg-gray-400 mb-2 rounded-lg wave"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.progress {
  position: relative;
  margin: 0 auto;
  text-align: center;
}
.barOverflow {
  position: relative;
  overflow: hidden;
  width: 130px;
  height: 90px; /* Half circle (overflow) */
  margin-bottom: -14px; /* bring the numbers up */
}
.bar {
  position: absolute;
  top: 20px;
  left: 0;
  width: 130px;
  height: 130px; /* full circle! */
  border-radius: 50%;
  box-sizing: border-box;
  border: 7px solid #eee; /* half gray, */
}
.actualRateSpan {
  line-height: 0.5px;
}
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
