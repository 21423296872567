import { createStore } from "vuex";

import projectModule from "./modules/project/index.js";
import projectPreferencesModule from "./modules/projectPreferences/index.js";
import jobModule from "./modules/job/index.js";
import sidebarModule from "./modules/sidebar/index.js";
import authModule from "./modules/auth/index.js";
import createOrUpdateUserModule from "./modules/createOrUpdateUser/index.js";
import ClusteringOfJobs from "./modules/ClusteringOfJobs/index.js";
// import createMultiTabState from "vuex-multi-tab-state";

const store = createStore({
  modules: {
    job: jobModule,
    project: projectModule,
    projectPreferences: projectPreferencesModule,
    sidebar: sidebarModule,
    auth: authModule,
    createOrUpdateUser: createOrUpdateUserModule,
    ClusteringOfJobs: ClusteringOfJobs,
  },
  // hunzala: comment this multitab because it is crashing the application when using on multiple tabs with multiple jobs and companies
  // plugins: [createMultiTabState()],
});

export default store;
