<template>
  <div>
    <div class="mt-5">
      <p class="mb-3 font-bold text-center">Conversions</p>
      <form action="#" method="PUT">
        <div
          class="col-span-6 sm:col-span-3"
          v-for="(singleRow, i) in rowHeader"
          v-bind:key="singleRow"
        >
          <div class="mt-3">
            <label
              :for="singleRow"
              class="block text-sm font-medium text-gray-700"
            >
              {{ $t(`conversionRate.${singleRow.replaceAll(".", "")}`) }}</label
            >
            <div class="grid grid-cols-2 gap-2">
              <div>
                <input
                  type="number"
                  :name="singleRow"
                  :id="singleRow"
                  v-model="rowValues[i]"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div class="flex flex-1 flex-row">
                <div
                  class="flex flex-1 justify-end items-center cursor-pointer"
                  @click="onConversionChange(responseData.table_data[i][0], i)"
                >
                  <p
                    class="border-gray-400 border px-1 rounded-lg hover:border-gray-700"
                  >
                    {{ $t("job-clustering.actual") }} -
                    {{ Math.round(responseData.table_data[i][0]) }}%
                  </p>
                </div>
                <div
                  class="flex flex-1 items-center justify-center cursor-pointer"
                  @click="onConversionChange(responseData.table_data[i][1], i)"
                >
                  <p
                    class="border-gray-400 border px-1 rounded-lg hover:border-gray-700"
                  >
                    {{ $t("job-clustering.expected") }} -
                    {{ Math.round(responseData.table_data[i][1]) }}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="flex flex-1 flex-row w-full justify-end items-end mt-4">
      <modal-footer-button
        @onModalClose="$emit('onModalClose')"
        @onSubmit="onNextStep"
        buttonText="Next"
        isCancelDisabled="true"
      />
    </div>
  </div>
</template>

<script>
import ModalFooterButton from "../../ui/ModalFooterButton.vue";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalFooterButton,
  },
  props: ["jobDetails"],
  data() {
    return {
      responseData: {},
      rowHeader: [],
      rowValues: [],
    };
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl"]),
  },
  methods: {
    onNextStep() {
      let body = {
        job_id: this.jobDetails.new_job_id,
        estimated_applicants: 10,
        research_quality: this.rowValues[0],
        reply_rate: this.rowValues[1],
        share_of_positive_replies: this.rowValues[2],
        transfer_to_phone_interview: this.rowValues[3],
        success_rate_phone_interview: this.rowValues[4],
        success_rate_presentation_to_client: this.rowValues[5],
        success_rate_interview_at_client: this.rowValues[6],
        success_rate_contract_negotiation: this.rowValues[7],
      };
      const headers = this.authorizationHeader;

      axios
        .put(
          this.backendUrl +
            "/job/estimated-stage-conversion-rates" +
            "?jobId=" +
            this.jobDetails.new_job_id,
          body,
          {
            headers,
          }
        )
        .then(() => {
          this.$emit("onNextStep");
        })
        .catch((error) => console.log(error));
    },
    onConversionChange(value, index) {
      this.rowValues[index] = Math.round(value);
    },
  },
  mounted() {
    const headers = this.authorizationHeader;

    axios
      .get(
        this.backendUrl +
          "/widget/conversions" +
          "?jobId=" +
          this.jobDetails.job_id,
        {
          headers,
        }
      )
      .then((response) => {
        let data = response.data;
        this.responseData = response.data;
        this.rowHeader = data.row_headers;
        data.table_data.map((value) => {
          this.rowValues.push(
            value[0] == 0 ? Math.round(value[1]) : Math.round(value[0])
          );
        });
      })
      .catch((error) => console.log(error));
  },
};
</script>
