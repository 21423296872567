export default {
  setAllJobList(context, payload) {
    context.commit("setAllJobList", payload);
  },
  setAllTags(context, payload) {
    context.commit("setAllTags", payload);
  },
  jobHashtagsChange(context, payload) {
    context.commit("jobHashtagsChange", payload);
  },
  addHashtag(context, payload) {
    context.commit("addHashtag", payload);
  },
  updateHashtag(context, payload) {
    context.commit("updateHashtag", payload);
  },
};
