import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      open: false,
      isSidePanelHidden: false,
      atsDialog: false,
      modalSubmitData: false,
      toggleLoading: false,
      Estimatedtimeexpenditure: [],
      EstimateStageConversion: [],
      GeneralProjectInformation: [],
      projectStatusBar: {},
      apiToRun: "",
    };
  },
  mutations,
  actions,
  getters,
};
