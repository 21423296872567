export default {
  setProjectDetails(context, payload) {
    context.commit("setProjectDetails", payload);
  },
  setTimeFrame(context, payload) {
    context.commit("setTimeFrame", payload);
  },
  setIsModal(context, payload) {
    context.commit("setIsModal", payload);
  },
};
