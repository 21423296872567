<template>
  <div>
    <div class="w-60 m-5">
      <label for="status" class="block text-sm font-medium text-gray-700"
        >Sort By:</label
      >
      <div class="mt-1 relative">
        <select
          id="status"
          name="status"
          class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          @change="sortListBySingleColumn($event.target.value)"
        >
          <option value="overall">Overall</option>
          <option value="kpi1">Applicant Management</option>
          <option value="kpi2">Sourcing</option>
          <option value="kpi3">Contacting</option>
          <option value="kpi4">Feedback - Total</option>
          <option value="kpi5">Telephone interview</option>
          <option value="kpi6">Introduction to Client</option>
          <option value="kpi7">Personal Communication</option>
          <option value="kpi8">Project Research and Org.</option>
        </select>
      </div>
    </div>

    <div class="overflow-x-auto mx-5 mt-7">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              v-for="(column, index) in tableColumns"
              :key="index"
              scope="col"
              class="px-3 py-2 text-left text-[11px] text-gray-500 uppercase tracking-wider"
            >
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(user, index) in userList" :key="index">
            <td
              v-for="(columnValue, index) in Object.keys(user)"
              :key="index"
              class="py-4 whitespace-nowrap"
            >
              <div class="px-3 py-2 text-left text-[13px] text-gray-900">
                {{ user[columnValue] }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getGeneralDataInCache } from "../../../components/helperFunctions/DataCaching";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      userList: [],
      isLoading: true,
      selectedUser: { name: this.username },
      tableColumns: [
        "Name",
        "Overall Ranking",
        "Applicant Management",
        "Ranking Applicant Management",
        "Sourcing",
        "Ranking Sourcing",
        "Contacting",
        "Ranking Contacting",
        "Feedback - Total",
        "Ranking Feedback - Total",
        "Telephone interview",
        "Ranking Telephone interview",
        "Introduction to Client",
        "Ranking Introduction to Client",
        "Personal Communication",
        "Ranking Personal Communication",
        "Project Research and Org. (%)",
        "Ranking Project Research and Org. (%)",
      ],
    };
  },
  methods: {
    onCalculateRanking() {
      let data = [...this.userList];

      // Get the list of KPI keys dynamically from the first object in the array
      let kpiKeys = Object.keys(data[0]).filter((key) => key.startsWith("kpi"));

      // Iterate over each KPI key
      kpiKeys.forEach((kpiKey) => {
        // Extract the KPI values from each object and parse them as numbers
        let kpiValues = data.map((obj) => parseFloat(obj[kpiKey]));

        // Sort the KPI values in descending order and calculate rankings
        let sortedKpiValues = [...kpiValues].sort((a, b) => b - a);
        let rankings = kpiValues.map(
          (kpiValue) => sortedKpiValues.indexOf(kpiValue) + 1
        );

        // Assign rankings to corresponding rankingKpi properties in each object
        data.forEach((obj, index) => {
          obj["ranking" + kpiKey] = rankings[index];
        });
      });

      // Calculate the overall key value for each object as the average of all rankings
      data.forEach((obj) => {
        let sumRankings = 0;
        for (let key in obj) {
          if (key.startsWith("ranking")) {
            sumRankings += obj[key];
          }
        }
        obj.overall = (sumRankings / kpiKeys.length).toFixed(2);
      });

      this.userList = [...data];
      this.sortListBySingleColumn("overall");
    },
    getConversionDataFromBackend() {
      if (this.isSuperAdmin) {
        // Get cached user list
        let cachedUserList = getGeneralDataInCache("userList");

        if (cachedUserList) {
          // Iterate over each user in the cached user list
          cachedUserList.forEach((user) => {
            // Get authorization headers
            let headers = this.authorizationHeader;

            // Make API request to fetch user job conversion data
            axios
              .get(
                `${this.backendUrl}/job/overviewTimeExpenditureSingleUser?username=${user.name}`,
                { headers }
              )
              .then((response) => {
                console.log(response);
                if (response.data !== "No job IDs found.") {
                  // Prepare employee data object
                  let employeeData = {
                    name: user.name,
                    overall: 10,
                  };

                  // Map over table data in the response
                  response.data.forEach((singleData, index) => {
                    // Add KPI and ranking KPI to employee data object
                    employeeData = {
                      ...employeeData,
                      ["kpi" + (index + 1)]: JSON.parse(
                        singleData.value
                      ).toFixed(2),
                      ["rankingkpi" + (index + 1)]: 0,
                    };
                  });

                  // Push employee data to userList
                  this.userList.push(employeeData);
                  this.onCalculateRanking();
                }
              })
              .catch((err) => {
                // Log any errors
                console.log(err.message);
              });
          });
        }
      }
    },
    sortListBySingleColumn(columnName) {
      this.userList.sort((a, b) => {
        // Split the values if they contain '%', otherwise, assign the original values
        const aValue =
          typeof a === "string" && a.includes("%")
            ? parseFloat(a.split("%")[0][columnName])
            : a[columnName];
        const bValue =
          typeof b === "string" && b.includes("%")
            ? parseFloat(b.split("%")[0][columnName])
            : b[columnName];

        // Perform the comparison
        return parseFloat(bValue) - parseFloat(aValue);
      });
    },
  },
  computed: {
    ...mapGetters([
      "isSuperAdmin",
      "username",
      "backendUrl",
      "authorizationHeader",
    ]),
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 4000);

    this.getConversionDataFromBackend();

    // this.userList = cachedUserList.map((single) => ({
    //   name: single.name,
    //   overall: 10,
    //   ...Array.from({ length: 8 }, (_, index) => ({
    //     ["kpi" + (index + 1)]: 11,
    //   })).reduce((acc, val) => ({ ...acc, ...val }), {}),
    //   ...Array.from({ length: 8 }, (_, index) => ({
    //     ["rankingKpi" + (index + 1)]: 12,
    //   })).reduce((acc, val) => ({ ...acc, ...val }), {}),
    // }));
  },
};
</script>
