<template>
  <skeleton-project-status-widget v-if="loading" />
  <div
    v-else
    class="w-full bg-white my-2 ml-3 mr-2 rounded-3xl shadow-2xl pt-1 px-4"
    style="height: 38em"
  >
    <div class="flex flex-row justify-between items-center my-4">
      <div class="flex flex-row items-center justify-around w-1/2">
        <h2 class="text-2xl text-black pl-5 mr-4 whitespace-nowrap">
          {{ $t(`dashboardProjectStatus.${name}`) }}
        </h2>
        <p class="text-gray-400">{{ currentJobName }}</p>
      </div>
      <p class="mr-4 text-gray-400">
        {{ $t(`dashboardProjectStatus.${rowHeaders[1]}`) }}:
        {{ availableWeeks }}
      </p>
      <div class="flex flex-1 flex-row justify-end">
        <button
          v-if="isAdmin"
          class="text-black flex flex-row h-8 text-sm font-semibold mr-5"
        >
          <SwitchHorizontalIcon
            @click="$emit('onToggleChange')"
            class="w-5 h-5 text-black"
          />
        </button>

        <button
          v-if="isAdmin"
          class="text-black flex flex-row h-8 text-sm font-semibold"
        >
          <CogIcon @click="onOpenSetting()" class="w-5 h-5 text-black" />
        </button>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="w-full relative pt-12 heightContainer">
        <project-status-bar
          v-for="(data, index) in tableDataBars"
          :key="data"
          :data="data"
          :totalProjectDays="totalProjectDays"
          :startDate="startDate"
          :today="today"
          :endDate="endDate"
          :remainingDays="remainingDays"
          :rowheaders="rowHeadersBars[index]"
          isNotModal="true"
          :todayPercentage="checkTodayPercentage"
        />
        <date-grafic-table
          :remainingDays="remainingDays"
          :totalProjectDays="totalProjectDays"
          :startDate="startDate"
          :today="today"
          :endDate="endDate"
          :rowHeaders="rowHeaders"
          :todayPercentage="checkTodayPercentage"
        />
      </div>
      <div class="h-full text-left flex flex-col datesLabel ml-1">
        <p>{{ $t(`dashboardProjectStatus.${rowHeaders[4]}`) }}</p>
        <span>{{ endDate }}</span>
      </div>
    </div>

    <project-setting-changes-modal
      v-if="isModal === 'projectStatusChanges'"
      @onModalClose="onModalClose"
      :tableDataBars="tableDataBars"
      :totalProjectDays="totalProjectDays"
      :startDate="startDate"
      :endDate="endDate"
      :rowHeadersBars="rowHeadersBars"
      :rowHeaders="rowHeaders"
      :remainingDays="remainingDays"
      :today="today"
    />
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import ProjectStatusBar from "./ProjectStatusBar.vue";
import DateGraficTable from "./DateGraficTable.vue";
import SkeletonProjectStatusWidget from "./SkeletonProjectStatusWidget.vue";
import ProjectSettingChangesModal from "./Modal/ProjectSettingChangesModal.vue";
import { CogIcon, SwitchHorizontalIcon } from "@heroicons/vue/solid";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../../../components/helperFunctions/DataCaching";
export default {
  components: {
    ProjectStatusBar,
    DateGraficTable,
    SkeletonProjectStatusWidget,
    CogIcon,
    ProjectSettingChangesModal,
    SwitchHorizontalIcon,
  },
  props: ["loadingToggle"],
  data() {
    return {
      name: "",
      currentJobName: "",
      rowHeaders: [],
      rowHeadersBars: [],
      tableDataBars: [],
      startDate: "",
      endDate: "",
      availableWeeks: "",
      today: new Date(),
      totalProjectDays: 0,
      remainingDays: null,
      msSinceMidnight: 0,
      loading: true,
      isModal: "",
    };
  },
  computed: {
    ...mapGetters([
      "currentJobId",
      "authorizationHeader",
      "backendUrl",
      "isAdmin",
    ]),
    toggleLoadingSaveButton() {
      return this.$store.getters.toggleLoading;
    },
    checkTodayPercentage() {
      const diffInMs = new Date(this.startDate) - new Date(this.today);
      let daysFinished = Math.abs(Math.round(diffInMs / (1000 * 60 * 60 * 24)));

      let percentage = (daysFinished / this.totalProjectDays) * 100;

      return percentage;
    },
  },
  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
      this.getBarsDataFromBackend(newJobId);
    },
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
    toggleLoadingSaveButton() {
      this.fetchDataFromBackend(this.currentJobId);
    },
  },
  methods: {
    ...mapActions(["setProjectStatusBar"]),

    onModalClose() {
      this.isModal = "";
    },
    onOpenSetting() {
      this.isModal = "projectStatusChanges";
    },
    getMillisSinceMidnight() {
      var d = new Date(),
        e = new Date(d);
      this.msSinceMidnight = e - d.setHours(0, 0, 0, 0);
    },
    calculateTotalDays() {
      this.getMillisSinceMidnight();
      if (this.endDate !== "" && this.startDate !== "") {
        const diffInMs = new Date(this.endDate) - new Date(this.startDate);
        this.totalProjectDays = Math.round(
          (diffInMs - this.msSinceMidnight) / (1000 * 60 * 60 * 24)
        );
      }
    },
    calculateRemainingDays() {
      const diffInMs = new Date(this.endDate) - new Date(this.today);
      let daysTogo = Math.round(diffInMs / (1000 * 60 * 60 * 24));
      // find the percentage of the days from start to end (assume 100% is from start to end) and check today lies in which percent of
      this.remainingDays = this.totalProjectDays - daysTogo;
    },

    fetchDataFromBackend(jobId) {
      this.loading = true;
      const headers = this.authorizationHeader;
      if (jobId !== null) {
        axios
          .get(
            this.backendUrl + "/widget/project-status-info" + "?jobId=" + jobId,
            {
              headers,
            }
          )
          .then((response) => {
            storeJobDataInCache(jobId, response.data, "ProjectStatus");
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.name = data.name;
      this.rowHeaders = data.row_headers;
      this.tableData = data.table_data;
      this.currentJobName = this.tableData[0][0];
      this.today = this.tableData[3][0].split(".").reverse().join("-");
      this.startDate = this.tableData[2][0].split(".").reverse().join("-");
      this.endDate = this.tableData[4][0].split(".").reverse().join("-");
      this.availableWeeks = this.tableData[1][0];

      this.calculateTotalDays();
      this.calculateRemainingDays();
      this.loading = false;
    },
    postProcessOfBarsFetch(data) {
      this.rowHeadersBars = data.row_headers;
      this.tableDataBars = data.table_data;
      this.setProjectStatusBar({
        name: "projectStatusBar",
        rowHeadersBars: this.rowHeadersBars,
        tableDataBars: this.tableDataBars,
      });
    },
    getBarsDataFromBackend(jobId) {
      const headers = this.authorizationHeader;
      if (jobId !== null) {
        axios
          .get(
            this.backendUrl +
              "/widget/target-de-facto-comparison" +
              "?jobId=" +
              jobId,
            {
              headers,
            }
          )
          .then((response) => {
            let responseData = response.data;
            this.rowHeadersBars = responseData.row_headers;
            this.tableDataBars = responseData.table_data;
            this.setProjectStatusBar({
              name: "projectStatusBar",
              rowHeadersBars: this.rowHeadersBars,
              tableDataBars: this.tableDataBars,
            });
          })
          .catch((error) => console.log(error));
      }
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "ProjectStatus")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "ProjectStatus")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }

      if (jobDataInCache(this.currentJobId, "ActualTargetComparison")) {
        this.postProcessOfBarsFetch(
          jobDataInCache(this.currentJobId, "ActualTargetComparison")
        );
      } else {
        this.getBarsDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
<style scoped>
.widthContainer {
  width: 82%;
}
.heightContainer {
  height: fit-content;
}

.datesLabel {
  font-size: 9px;
}
</style>
