<template>
  <div>
    <!---------------------- PERSONAL DETAILS PART --------------------------------->
    <div class="grid grid-cols-1 gap-4">
      <!-- ============================== input fields start ======================================= -->
      <div
        :key="inputField"
        v-for="inputField in ['first-name', 'last-name', 'email-address']"
        class="flex flex-1 items-center flex-col"
      >
        <input
          type="text"
          :disabled="this.$route.params.userId != '0'"
          :placeholder="'*' + $t(`admin-page.${inputField}`)"
          v-model="controls[inputField].value"
          @keyup="onValueChange(inputField, $event.target.value)"
          class="input input-bordered input-primary w-full max-w-xs"
          :class="
            controls[inputField].errorMessage == ''
              ? 'input-primary'
              : 'input-error'
          "
        />
        <div v-if="!isFormValidated" class="flex flex-1">
          <p class="text-red-500">{{ controls[inputField].errorMessage }}</p>
        </div>
      </div>
      <!-- ============================== input fields end ======================================= -->
    </div>

    <!-- ============================== Role field end ======================================= -->

    <div class="flex flex-1 flex-col mt-4">
      <div class="flex flex-1 w-full justify-center">
        <select
          @change="onValueChange('role', $event.target.value)"
          class="select select-primary w-full max-w-xs"
          v-model="controls.role.value"
        >
          <!-- <option disabled selected>Role</option> -->
          <option v-for="role in roles" :value="role.name" :key="role.name">
            {{ role.name.split("ROLE_")[1] }}
          </option>
        </select>
      </div>
      <div
        v-if="role == 'ROLE_ADMIN'"
        class="flex flex-1 w-full justify-center mt-1"
      >
        <p class="italic">*{{ $t("admin-page.admin-user-message") }}</p>
      </div>
    </div>
    <!-- ============================== Role field end ======================================= -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ROLE_CLIENT } from "../../../../constant/Constants";

export default {
  computed: {
    role() {
      return this.controls.role.value;
    },
    selectText() {
      if (this.isRoleClient && this.assignedCompaniesList.length > 0)
        return "change-company";
      else return "add-company";
    },
    ...mapGetters([
      "controls",
      "isFormValidated",
      "assignedCompaniesList",
      "roles",
      "isRoleClient",
    ]),
  },
  methods: {
    ...mapActions([
      "setControls",
      "setIsFormValidated",
      "setAssignedCompaniesList",
    ]),
    onValueChange(key, value) {
      // if there are more than 1 companies and role become client so remove every company except the last one
      if (
        key == "role" &&
        value === ROLE_CLIENT &&
        this.assignedCompaniesList.length > 1
      ) {
        this.setAssignedCompaniesList(this.assignedCompaniesList.slice(-1));
      }

      let tempControls = { ...this.controls };
      tempControls[key].value = value;
      // reset form validation and message when user is in typing mode
      this.setIsFormValidated(true);
      tempControls[key].errorMessage = "";
      this.setControls(tempControls);
    },
  },
};
</script>
