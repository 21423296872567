<template>
  <div
    class="h-16 bg-gray-200 p-3 pt-4 mx-2 my-5 shadow-xl rounded-lg btn-ghost border border-gray-400"
  >
    <div class="flex flex-row justify-between items-center h-full">
      <div class="flex flex-row w-2/4 items-center">
        <favorite-button
          v-if="!isPendingClients"
          :element="company"
          :favorites="favorites"
        />

        <p
          class="ml-2 text-xl"
          :class="$store.state.auth.isPresentationMode ? 'blur-effect' : null"
        >
          {{ company.name }}
        </p>
      </div>
      <p class="text-xs primary-color-text">
        Status:
        {{ isPendingClients ? "Closed" : $t("client-details-page.completed") }}
      </p>
      <div>
        <button
          :class="detailsButtonClasses"
          @click="goToClientDetailsPage(company)"
        >
          {{ $t("client-details-page.details") }}
          <ChevronRightIcon class="w-5 h-5" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/solid";
import FavoriteButton from "../../components/ui/FavoriteButton.vue";
import { detailsButton } from "../../components/helperFunctions/UIClasses";
export default {
  props: ["company", "favorites", "isPendingClients"],
  components: {
    ChevronRightIcon,
    FavoriteButton,
  },
  computed: {
    detailsButtonClasses() {
      return detailsButton();
    },
  },
  methods: {
    goToClientDetailsPage(company) {
      this.$store.dispatch("setCompany", {
        newCompanyName: company.name,
        newCompanyId: company.id,
      });
      this.$router.push(`/clients/${company.id}`);
    },
  },
};
</script>
