<template>
  <div>
    <div
      class="w-full m-5 flex flex-1 justify-center items-center border-b-2 pb-5"
    >
      <div class="mt-1 flex-1 flex justify-center relative">
        <select
          id="status"
          name="status"
          class="block w-96 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          @change="onAverageTypeChange($event.target.value)"
        >
          <option value="Coversion Rate">Coversion Rate</option>
          <option value="Time Expenditure">Time Expenditure</option>
        </select>
      </div>
    </div>

    <average-conversion-rate v-show="averageType === 'Coversion Rate'" />
    <average-time-expenditure v-show="averageType === 'Time Expenditure'" />
  </div>
</template>

<script>
import AverageConversionRate from "./AverageConversionRate.vue";
import AverageTimeExpenditure from "./AverageTimeExpenditure.vue";

export default {
  data() {
    return {
      averageType: "Coversion Rate",
    };
  },
  methods: {
    onAverageTypeChange(value) {
      this.averageType = value;
    },
  },
  components: { AverageConversionRate, AverageTimeExpenditure },
};
</script>
