<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-20 overflow-hidden"
      :class="isSidePanelHidden ? 'w-0' : ''"
      @close="closeProjectPreferences"
      :open="isOpen"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>
        <div
          class="fixed inset-y-0 right-0 max-w-full flex"
          :class="isSidePanelHidden ? 'w-0' : ''"
        >
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div
              class="w-screen max-w-2xl bg-gray-100 overflow-y-scroll p-2 pt-3"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
                >
                  <button
                    class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white shadow-md"
                    @click="closeProjectPreferences"
                  >
                    <span class="sr-only">Close panel</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>

              <div class="px-4 sm:px-6">
                <DialogTitle class="text-lg font-medium text-gray-900">
                  {{ $t("projekt-einstellungen.name") }}
                </DialogTitle>
              </div>
              <div class="mt-6 relative flex-1 px-4 sm:px-6">
                <!-- Replace with your content -->
                <project-preferences></project-preferences>
                <!-- /End replace -->
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import ProjectPreferences from "./ProjectPreferences.vue";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ProjectPreferences,
    XIcon,
  },
  computed: {
    ...mapGetters(["isOpen", "isSidePanelHidden"]),
  },
  methods: {
    ...mapActions(["closeProjectPreferences"]),
  },
};
</script>
