<template>
  <div class="relative">
    <div class="absolute z-10 left-4 text-xs top-1">
      <p>
        <span :class="isNotModal ? '' : 'font-bold text-base mr-2'">{{
          setLabelValue()
        }}</span>
        {{ $t(`dashboardProjectStatus.${setLabelBar()}`) }}
      </p>
    </div>

    <div
      class="mt-2 mr-2 h-10 bg-gray-200 rounded-full"
      :style="barlength > 100 ? 'width : 100%' : `width: ${barlength ?? 100}%`"
    >
      <div
        class="h-10 bg-gray-400 kpiLevel rounded-full relative"
        :class="assignColorToBar()"
        :style="barPercent > 100 ? 'width : 100%' : `width: ${barPercent}%`"
      >
        <div
          v-if="rowheaders !== 'Einstellung' || rowheaders !== 'Hiring'"
          class="w-6 h-6 absolute positionIcon mr-1"
          :style="barPercent > 4 ? 'right: 2px' : 'left:3px'"
        >
          <CheckCircleIcon
            v-if="barPercent > 99 && isNotModal"
            class="w-4 h-4 text-green-700"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CheckCircleIcon } from "@heroicons/vue/outline";
export default {
  props: [
    "data",
    "totalProjectDays",
    "startDate",
    "endDate",
    "rowheaders",
    "isNotModal",
    "today",
    "remainingDays",
    "todayPercentage",
  ],
  components: {
    CheckCircleIcon,
  },
  data() {
    return {
      barPercent: null,
      barlength: null,
      daysAvailable: null,
      colorBar: "",
      daysForTask: null,
    };
  },
  computed: {
    currentJob() {
      return this.$store.getters.currentJobId;
    },
    company() {
      return this.$store.getters.company;
    },
    labelStyle() {
      if (this.isNotModal) return ``;
      else return `font-weight: 'bold'`;
    },
    barPercentWithRespectToToday() {
      if (this.today > this.endDate)
        return (this.barPercent / this.totalProjectDays) * 100;
      else return (this.barPercent / this.todayPercentage) * 100;
    },
  },
  watch: {
    currentJob() {
      this.setPercent();

      this.daysAvailableToCompleteTask();
    },
    company() {
      this.setPercent();
      this.daysAvailableToCompleteTask();
    },
    data() {
      this.setPercent();
    },
    endDate() {
      this.daysAvailableToCompleteTask();
    },
  },
  methods: {
    // hunzala: we dont know why it is there and it is crashing the bars length when pdf opened
    //This method allows to calculate how long should be the bar related to the days available to complete the task
    //This.daysForTask should be a variable that stores the number of days available for each task
    calculateTaskBar() {
      if (this.totalProjectDays !== null) {
        this.barlength = Math.round(
          (this.daysForTask * 100) / this.totalProjectDays
        );
      }
    },
    setPercent() {
      this.barPercent = (this.data[0] * 100) / this.data[1];
    },
    setLabelBar() {
      if (this.isNotModal) return `${this.rowheaders}`;
      else return `${this.rowheaders}`;
    },
    setLabelValue() {
      this.setPercent();
      if (this.isNotModal) return `${this.data[0]}/${this.data[1]}`;
      else return `${this.data[1]}`;
    },
    daysAvailableToCompleteTask() {
      let today = new Date();
      const diff = new Date(this.endDate) - new Date(today);

      if (diff > 0) {
        const diffInMs = new Date(today) - new Date(this.startDate);
        let daysTogo = Math.round(diffInMs / (1000 * 60 * 60 * 24));
        this.daysAvailable = Math.round(this.daysForTask - daysTogo);
      }
      this.assignColorToBar();
    },
    assignColorToBar() {
      if (
        this.barPercentWithRespectToToday >= 60 ||
        this.todayPercentage <= this.barPercent
      ) {
        this.colorBar = "bg-green-400";
      } else if (
        this.barPercentWithRespectToToday < 60 &&
        this.barPercentWithRespectToToday >= 40
      ) {
        this.colorBar = "bg-yellow-300";
      } else if (this.barPercentWithRespectToToday < 40) {
        this.colorBar = "bg-red-400";
      }
      return `${this.colorBar} ${this.roundBar()}`;
    },
    roundBar() {
      if (this.barPercent < 30) {
        return "rounded-l-full";
      } else {
        return "rounded-full";
      }
    },
  },
  mounted() {
    this.setPercent();
    this.daysAvailableToCompleteTask();
  },
};
</script>
<style scoped>
.kpiLevel {
  animation: draw 2s ease-in-out;
}

@keyframes draw {
  0% {
    width: 0;
  }
}
.positionIcon {
  top: 80%;
  transform: translateY(-50%);
}
</style>
