<template>
  <div
    class="
      p-4
      m-2
      h-12
      text-center
      flex
      items-center
      rounded-full
      border-2 border-solid
      uppercase
      justify-center
    "
    :style="childStyle"
  >
    <img
      v-if="logo"
      class="h-6 w-6"
      :src="require(`@/assets/${logo}`)"
      alt="Kooku Logo"
    />

    <p v-else>{{ initials }}</p>
  </div>
</template>

<script>
export default {
  props: ["initials", "childStyle", "logo"],
};
</script>
