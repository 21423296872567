<template>
  <div class="mt-4">
    <form class="w-full max-w-lg" @submit.prevent="onSubmit">
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-full px-3 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            {{ $t("add-new-job.company-name") }} *
          </label>
          <input
            autocomplete="off"
            class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white shadow-md"
            :class="isValidationFail ? 'border-2 border-red-700' : ' border-0'"
            id="grid-first-name"
            type="text"
            @input="onValueChange($event, 'name')"
            v-model="name"
          />
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-full px-3 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            {{ $t("add-new-job.company-street") }}
          </label>
          <input
            autocomplete="off"
            class="appearance-none block w-full bg-gray-200 text-gray-700 border-0 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white shadow-md"
            id="grid-first-name"
            type="text"
            v-model="street"
            @input="onValueChange($event, 'street')"
          />
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-0">
        <!-- looping to just avoid repeating same code  -->
        <div
          v-for="singleField in inputFields"
          :key="singleField.name"
          class="w-full md:w-1/2 px-3 mb-6 md:mb-6"
        >
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            {{ $t(`add-new-job.${singleField.text}`) }}
          </label>
          <input
            autocomplete="off"
            class="appearance-none block w-full bg-gray-200 text-gray-700 border-0 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white shadow-md"
            id="grid-first-name"
            :type="singleField.type"
            v-model="$data[singleField.name]"
            @input="onValueChange($event, singleField.name)"
          />
        </div>
      </div>

      <div class="flex flex-row">
        <div class="flex-1 flex-row">
          <div class="w-full">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Status
            </label>
            <select
              class="custom-select-style"
              id="grid-state"
              v-model="status"
            >
              <option
                v-for="singleOption in selectOptions"
                :key="singleOption"
                :value="singleOption"
              >
                {{ $t(`add-new-job.company-status-${singleOption}`) }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex flex-1 flex-row w-full justify-end items-end">
          <button
            class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
            @click="$emit('onModalClose')"
            type="button"
          >
            <span>Cancel</span>
          </button>
          <button
            :disabled="loading"
            class="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-md primary-button"
          >
            <span>{{ companyInformation ? "Submit" : "Next" }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["companyInformation"],
  mounted() {
    //initialize all values only when user want to edit the company info
    if (this.companyInformation) {
      this.name = this.companyInformation.name ?? "";
      this.street = this.companyInformation.street ?? "";
      this.streetNumber = this.companyInformation.street_number ?? "";
      this.zip = this.companyInformation.zip ?? "";
      this.country = this.companyInformation.country ?? "";
      this.city = this.companyInformation.city ?? "";
      this.poBox = this.companyInformation.po_box ?? "";
      this.status = this.companyInformation.status;
      this.company_id = this.companyInformation.company_id;
    }
  },
  data() {
    return {
      name: "",
      street: "",
      streetNumber: "",
      zip: "",
      country: "",
      city: "",
      poBox: "",
      status: "pending",
      company_id: "",
      isValidationFail: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "backendUrl",
      "authorizationHeader",
      "primaryColorBackground",
    ]),
    // function to short html code instead of writing same field div multiple times loop them with this array
    inputFields() {
      return [
        { text: "company-streetNumber", name: "streetNumber", type: "number" },
        { text: "company-zip", name: "zip", type: "text" },
        { text: "company-poBox", name: "poBox", type: "text" },
        { text: "company-city", name: "city", type: "text" },
        { text: "company-country", name: "country", type: "text" },
      ];
    },
    selectOptions() {
      return ["active", "archived", "pending", "closed"];
    },
  },
  methods: {
    ...mapActions("job", ["setIsModal"]),

    onSubmit() {
      if (this.name == "") {
        this.isValidationFail = true;
      } else {
        this.loading = true;
        // call an api endpoint to submit complete data
        let headers = this.authorizationHeader;
        // postfix this string so that we know the new job is created from DashR, not when user is editing the company
        this.name = this.companyInformation
          ? this.name
          : `${this.name} - (Created in Dashr)`;
        let companyData = {
          name: this.name,
          street: this.street,
          street_number: this.streetNumber,
          country: this.country,
          status: this.status,
          zip: this.zip,
          po_box: this.poBox,
          city: this.city,
          company_id: this.company_id,
        };
        let url = `${this.backendUrl}${
          this.companyInformation
            ? "/company/modify-company"
            : "/company/new-company"
        }`;
        let method = this.companyInformation ? "PUT" : "POST";
        axios({
          method,
          url,
          headers: headers,
          data: companyData,
        })
          .then((response) => {
            // push the company in the store list
            let companies = this.$store.getters.companies;
            companies = [
              ...companies,
              { name: this.name, id: response.data, status: "pending" },
            ];
            companies.sort((a, b) => a.name.localeCompare(b.name));
            this.$store.dispatch("setAllCompany", companies);
            let parameters = {
              newCompanyName: this.name,
              newCompanyId: this.companyInformation
                ? this.company_id
                : response.data,
            };
            // save the selected company in store
            this.$store.dispatch("setCompany", parameters);
            this.loading = false;
            if (this.companyInformation) {
              this.$store.dispatch("resetCompanyVariables");
              this.setIsModal("");
              this.$router.go();
            } else {
              this.$emit("onNextStep", parameters);
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    },
    onValueChange(value, name) {
      this[name] = value.target.value;
    },
  },
};
</script>
<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 10% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
