export default {
  open(state) {
    return state.open;
  },
  selectedView(state) {
    return state.selectedView;
  },
  selectedViewDashboard(state) {
    return state.selectedViewDashboard;
  },
};
