<template>
  <div class="progress">
    <div class="barOverflow">
      <transition
        :css="false"
        name="fade"
        @enter="onEnter"
        @after-enter="onAfterEnter"
        appear
      >
        <div class="bar" :style="[colorHalfCircle]"></div>
      </transition>
      <transition
        name="fade"
        :css="false"
        @enter="onEnter"
        @after-enter="onAfterEnterExpected"
        appear
      >
        <div class="barExpected" :style="[expectedStylingOfGraph]"></div>
      </transition>
    </div>

    <div class="text-3xl text-gray-500 actualRateSpan">{{ valuePerc }}%</div>
  </div>
</template>
<script>
export default {
  props: ["valuePerc", "expectedRate", "isPdfReport"],

  computed: {
    expectedStylingOfGraph() {
      return {
        borderBottomColor: "rgb(126, 126, 126)",
        borderRightColor: "rgb(126, 126, 126)",
      };
    },
    colorHalfCircle() {
      if (this.compareActualandExpectedRate() < 50) {
        return {
          borderBottomColor: "rgb(248 113 113)",
          borderRightColor: "rgb(248 113 113)",
        };
      } else if (
        this.compareActualandExpectedRate() >= 50 &&
        this.compareActualandExpectedRate() < 90
      ) {
        return {
          borderBottomColor: "rgb(252 211 77)",
          borderRightColor: "rgb(252 211 77)",
        };
      } else {
        return {
          borderBottomColor: "rgb(52 211 153)",
          borderRightColor: "rgb(52 211 153)",
        };
      }
    },
  },
  methods: {
    compareActualandExpectedRate() {
      let difference = (this.valuePerc * 100) / this.expectedRate;
      return difference;
    },
    compareActualandExpectedRateTemp() {
      let difference = ((this.valuePerc + 10) * 100) / this.expectedRate;
      return difference;
    },
    setPerc() {
      let perc = parseInt(this.valuePerc, 10);
      let calcRotation = 45 + perc * 1.8;
      return calcRotation;
    },
    setPercExpected() {
      let perc = parseInt(this.expectedRate, 10);
      let calcRotation = 45 + perc * 1.8;
      return calcRotation;
    },
    onEnter(el) {
      el.style.transform = "rotate(45deg)";
    },
    onAfterEnter(el) {
      setTimeout(() => {
        el.style.transform = "rotate(" + this.setPerc() + "deg)";
      }, 1000);
    },
    onAfterEnterExpected(el) {
      setTimeout(() => {
        el.style.transform = "rotate(" + this.setPercExpected() + "deg)";
      }, 1000);
    },
  },
  mounted() {
    this.setPerc();
    this.setPercExpected();
  },
};
</script>

<style scoped>
.progress {
  position: relative;
  margin: 4px;
  float: left;
  text-align: center;
}
.barOverflow {
  position: relative;
  overflow: hidden;
  width: 130px;
  height: 65px; /* Half circle (overflow) */
  margin-bottom: -14px; /* bring the numbers up */
}
.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 130px;
  height: 130px; /* full circle! */
  border-radius: 50%;
  box-sizing: border-box;
  border: 8px solid #eee; /* half gray, */
}
.barExpected {
  position: absolute;
  top: 0;
  left: 0;
  width: 130px;
  height: 130px; /* full circle! */
  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid #eee; /* half gray, */
}
.actualRateSpan {
  line-height: 0.5px;
}
</style>
