export default function FormValidation(controls) {
  let isFormValidated = true;

  Object.keys(controls).map((singleControl) => {
    let { value, validationRules } = controls[singleControl];

    // rule to check required fiels
    if (validationRules.includes("required") && value == "") {
      isFormValidated = false;
      controls[singleControl].errorMessage = "Field is Mandatory";
    }
    // rule to check email
    if (
      validationRules.includes("email") &&
      value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) == null
    ) {
      isFormValidated = false;
      controls[singleControl].errorMessage = "Email format is not correct";
    }
  });

  return { isFormValidated, controls };
}
