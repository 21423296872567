<template>
  <div
    class="card w-96 h-52 bg-gray-200 shadow-xl my-4 mx-4 btn-ghost border border-gray-400"
  >
    <div class="card-body p-0">
      <div class="flex flex-row relative card-title pt-5 px-2">
        <div class="flex flex-row items-center relative">
          <logo-container
            :initials="companyInitial(company.name)"
            :childStyle="bg"
          ></logo-container>
          <p
            class="text-sm w-32 ml-2 font-bold"
            :class="$store.state.auth.isPresentationMode ? 'blur-effect' : null"
          >
            {{ company.name }}
          </p>
        </div>
        <div v-if="!isPendingClients" class="absolute top-5 right-4">
          <favorite-button :element="company" :favorites="favorites" />
        </div>
      </div>

      <div
        class="flex flex-1 flex-row justify-center items-center mt-6 px-5 card-actions"
      >
        <p class="text-sm">
          Status: {{ isPendingClients ? "Pending" : $t("clients-page.active") }}
        </p>

        <button
          @click="goToClientDetailsPage(company)"
          :class="detailsButtonClasses"
        >
          {{ $t("client-details-page.details") }}
          <ChevronRightIcon class="w-5 h-5" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
//import axios from "axios";
//import { mapGetters } from "vuex";
import LogoContainer from "../../components/ui/LogoContainer.vue";
import { ChevronRightIcon } from "@heroicons/vue/solid";
import FavoriteButton from "../../components/ui/FavoriteButton.vue";
import { detailsButton } from "../../components/helperFunctions/UIClasses";

export default {
  components: {
    LogoContainer,
    ChevronRightIcon,
    FavoriteButton,
  },
  props: ["company", "favorites", "isPendingClients", "loading"],
  computed: {
    detailsButtonClasses() {
      return detailsButton();
    },
  },
  data() {
    return {
      bg: { background: "white", color: "grey" },
      logo: "logo_transparent.png",
    };
  },

  methods: {
    goToClientDetailsPage(company) {
      this.$store.dispatch("setCompany", {
        newCompanyName: company.name,
        newCompanyId: company.id,
      });
      this.$router.push(`/clients/${company.id}`);
    },
    companyInitial(company) {
      if (company !== undefined) {
        return (this.initials = company
          .toString()
          .replace(/[\u200B-\u200D\uFEFF]/, "")
          .substring(0, 1));
      }
    },
    mounted() {},
  },
};
</script>
