<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="mt-10" v-if="!loading">
    <BarChart :chart-data="chartData" :chart-options="chartOptions" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Register the required components
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  data() {
    return {
      statusValue: {},
      loading: true,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {},
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: true,
            position: "top",
          },
          title: {
            display: true,
            text: "Project Status Over Different Time Frames",
          },
        },
      },
    };
  },

  methods: {
    fetchData() {
      const headers = this.authorizationHeader;
      this.loading = true;
      axios
        .get(`${this.backendUrl}/job/all-job-stats`, { headers })
        .then(({ data }) => {
          this.statusValue = { ...data };
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl", "isSuperAdmin"]),

    chartData() {
      const {
        completed_1_year,
        completed_3_month,
        completed_6_month,
        completed_total,
        hired_1_year,
        hired_3_month,
        hired_6_month,
        hired_total,
        inplanning_1_year,
        inplanning_3_month,
        inplanning_6_month,
        inplanning_total,
        inprogress_1_year,
        inprogress_3_month,
        inprogress_6_month,
        inprogress_total,
      } = this.statusValue;
      return {
        labels: ["3 months", "6 months", "1 year", "Total"],
        datasets: [
          {
            label: "In Planning",
            backgroundColor: "#FFA726",
            data: [
              inplanning_3_month,
              inplanning_6_month,
              inplanning_1_year,
              inplanning_total,
            ],
          },
          {
            label: "In Progress",
            backgroundColor: "#EF5350",
            data: [
              inprogress_3_month,
              inprogress_6_month,
              inprogress_1_year,
              inprogress_total,
            ],
          },
          {
            label: "Hired",
            backgroundColor: "#66BB6A",
            data: [hired_3_month, hired_6_month, hired_1_year, hired_total],
          },
          {
            label: "Completed",
            backgroundColor: "#42A5F5",
            data: [
              completed_3_month,
              completed_6_month,
              completed_1_year,
              completed_total,
            ],
          },
        ],
      };
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    BarChart: Bar,
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  margin: auto;
  height: 80vh;
  width: 80vw;
}
</style>
