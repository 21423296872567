export default {
  toggleProjectPreferences(state) {
    state.open = !state.open;
  },
  toggleSidePanelWithHidden(state, payload) {
    state.isSidePanelHidden = payload;
    setTimeout(() => {
      state.open = !state.open;
    }, 500);
  },
  closeProjectPreferences(state) {
    state.open = false;
  },
  setAtsOpen(state) {
    state.atsDialog = true;
  },
  setAtsClose(state) {
    state.atsDialog = false;
  },
  setModalSubmitData(state, payload) {
    state.modalSubmitData = payload;
  },
  setToggleLoading(state, payload) {
    state.toggleLoading = payload;
  },
  setFieldValues(state, payload) {
    state[payload.name] = payload.values;
  },
  setProjectStatusBar(state, payload) {
    let rowHeadersBars = {};
    payload.rowHeadersBars.forEach(
      (ele, i) =>
        (rowHeadersBars[ele.replaceAll(" ", "_")] = parseInt(
          payload.tableDataBars[i][1]
        ))
    );
    state[payload.name] = rowHeadersBars;
  },
  setApiToRun(state, payload) {
    state.apiToRun = payload;
  },
};
