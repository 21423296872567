<template>
  <div class="m-10">
    <div
      class="w-full mb-5"
      :key="singleHeading"
      v-for="singleHeading in headings"
    >
      <h2 class="mx-2 text-2xl mb-3">{{ singleHeading }}</h2>
      <div class="grid grid-cols-4 gap-4 p-5">
        <div
          v-for="index in 4"
          :key="index"
          class="w-full rounded-md wave h-48"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headings: [
        "Your Current Clients",
        "All past clients",
        "Pending Clients",
        "Closed Clients",
      ],
    };
  },
};
</script>

<style scoped>
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #fffefe 8%, #c7c6c6 18%, #eeeeee 33%);
  background-size: 800px 104px;
}
@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
