<template>
  <div class="border bg-white mb-3 p-5 w-full rounded-lg w-11/12 mt-10">
    <div class="mx-5 mt-4 w-96 rounded-md mb-8 wave h-7"></div>
    <div class="flex flex-col">
      <div
        v-for="(num, index) in lines"
        :key="num"
        class="h-12 w-11/22 rounded-lg"
        :class="index % 2 == 0 ? 'bg-white' : 'wave'"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["lines"],
};
</script>
<style scoped>
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
