<template>
  <!-- TODO Remove this when Header is fixed -->
  <div class="space-y-2">
    <!-- General form  -->
    <input-form :formInfo="generalFormInfo" ref="generalForm"></input-form>
    <!-- StageMapping form -->
    <stage-mapping-form ref="stageMappingForm"></stage-mapping-form>
    <!-- Conversion Estimation form -->
    <input-form
      :formInfo="stageConversionFormInfo"
      ref="conversionEstimationForm"
    ></input-form>
    <!-- Time expenditure form -->
    <input-form
      :formInfo="timeExpenditureFormInfo"
      ref="timeExpenditureForm"
    ></input-form>

    <div class="flex justify-end">
      <button
        type="button"
        class="bg-white py-2 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
        @click="closeProjectPreferences()"
      >
        {{ $t("projekt-einstellungen.cancel") }}
      </button>
      <button
        type="submit"
        class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md primary-button"
        @click="submitData()"
      >
        {{ $t("projekt-einstellungen.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StageMappingForm from "./StageMappingForm.vue";
import InputForm from "./InputForm.vue";
import { removeCompleteJobDataFromCache } from "../helperFunctions/DataCaching";

export default {
  components: {
    StageMappingForm,
    InputForm,
  },
  watch: {
    apiToRun() {
      switch (this.apiToRun) {
        case "/job/stages-mapping":
          this.$refs.generalForm.submitData();
          break;
        case "/job/setting-general-info":
          this.$refs.timeExpenditureForm.submitData();
          break;
        case "/job/estimated-time-expenditure":
          this.$refs.conversionEstimationForm.submitData();
          this.onCompleteAllSubmitData();
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["apiToRun"]),
    generalFormInfo() {
      return {
        translationKey: "projekt-einstellungen",
        storeKeyName: "General Project Information",
        name: this.$t("projekt-einstellungen.general-project-information"),
        description: this.$t(
          "projekt-einstellungen.general-project-information-text"
        ),
        url: "/job/setting-general-info",
      };
    },
    stageConversionFormInfo() {
      return {
        translationKey: "dashboardProjectStatus",
        storeKeyName: "Estimate Stage Conversion",
        name: this.$t("projekt-einstellungen.estimated-stage-conversion"),
        description: this.$t(
          "projekt-einstellungen.estimated-stage-conversion-text"
        ),
        url: "/job/estimated-stage-conversion-rates",
      };
    },
    timeExpenditureFormInfo() {
      return {
        translationKey: "timeTracking",
        storeKeyName: "Estimated time expenditure",
        name: this.$t("projekt-einstellungen.estimated-time-expenditure"),
        description: this.$t(
          "projekt-einstellungen.estimated-time-expenditure-text"
        ),
        url: "/job/estimated-time-expenditure",
      };
    },
  },

  methods: {
    ...mapActions(["closeProjectPreferences"]),
    submitData() {
      this.$store.dispatch("setToggleLoading", true);
      this.$refs.stageMappingForm.submitData();
      // remaining refs submitData funtions on watch
      // this logic is because when we hit all submitData functions at once data is not updated in backend because of all api hits at once
    },

    onCompleteAllSubmitData() {
      removeCompleteJobDataFromCache(this.$route.params.jobId);

      this.$store.dispatch("toggleProjectPreferences");
      setTimeout(() => this.$store.dispatch("setModalSubmitData", true), 200);
      setTimeout(() => {
        this.$store.dispatch("setToggleLoading", false);
        this.$router.go();
      }, 1500);
    },
  },
};
</script>
