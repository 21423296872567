<template>
  <div class="text-3xl text-gray-600 text-bold">
    {{ weekOfTheYear }} <span class="ml-2">{{ currentDate() }}</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      weekOfTheYear: "",
    };
  },
  watch: {
    calendarWeek() {
      this.textWeekOfTheYear();
    },
  },
  computed: {
    calendarWeek() {
      return this.$t("dashboard.calendar-week");
    },
  },
  methods: {
    getWeekNumber(d) {
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      // Return array of year and week number
      // return [d.getUTCFullYear(), weekNo];
      return String(weekNo);
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}.${
        current.getMonth() + 1
      }.${current.getFullYear()}`;
      return date;
    },
    textWeekOfTheYear() {
      return (this.weekOfTheYear = `${this.calendarWeek} ${this.getWeekNumber(
        new Date()
      )}`);
    },
  },
  mounted() {
    this.textWeekOfTheYear();
  },
};
</script>
