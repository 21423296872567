<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <div>
        <p class="text-2xl font-semibold">
          {{
            step == 4
              ? $t("add-new-job.add-new-contact")
              : $t("add-new-job.add-new-job")
          }}
        </p>
      </div>

      <div>
        <div class="flex ...">
          <div
            class="h-1 w-1/2 mt-5 bg-green-500 border-r-8 border-green-700"
          ></div>
          <div :class="stepsCss('stepFour')"></div>
        </div>
      </div>

      <step-one
        v-if="step == 1"
        @onModalClose="$emit('onModalClose')"
        @onNextStep="onNextStep"
      ></step-one>

      <step-two
        v-if="step == 2"
        @onModalClose="$emit('onModalClose')"
        @onNextStep="onNextStep"
        :jobId="jobId"
        :jobName="jobName"
        :projectCode="projectCode"
      >
      </step-two>

      <step-three
        v-if="step == 3"
        @onModalClose="$emit('onModalClose')"
        @onNextStep="onNextStep"
        :jobId="jobId"
        :jobName="jobName"
        :projectCode="projectCode"
      >
      </step-three>

      <contact-information-step
        v-if="step == 4"
        :companyId="companyId"
        :jobId="jobId"
        :projectCode="projectCode"
        :jobName="jobName"
      />
    </div>
  </div>
</template>

<script>
import StepOne from "./StepOne.vue";
import StepTwo from "./StepTwo.vue";
import StepThree from "./StepThree.vue";
import ContactInformationStep from "../NewCompanyModal/ContactInformationStep.vue";
export default {
  props: ["companyId"],
  data() {
    return {
      step: 1,
      jobId: null,
      jobName: null,
      projectCode: null,
    };
  },
  methods: {
    onSubmit() {
      // call an api endpoint to submit complete data
      this.$router.push(`/clients/8`);
    },
    onValueChange(value, name) {
      this[name] = value.target.value;
    },
    onNextStep(data) {
      // only set job id when it is returning from step 1 only
      if (data) {
        this.jobId = data.jobId;
        this.jobName = data.jobName;
        this.projectCode = data.projectCode;
      }

      this.step += 1;
    },
    stepsCss(stepName) {
      // function to return the css for steps according to given parameter
      let compareNumber =
        stepName == "stepTwo" ? 2 : stepName == "stepThree" ? 3 : 4;
      if (this.step >= compareNumber)
        return "h-1 w-1/2 mt-5 bg-green-500 border-r-8 border-green-700";
      else return "h-1 w-1/2 mt-5 bg-gray-400 border-r-8 border-gray-600";
    },
  },
  components: { StepOne, StepTwo, StepThree, ContactInformationStep },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 10% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
