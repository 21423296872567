<template>
  <label class="swap swap-flip text-9xl w-full" style="place-content: inherit">
    <!-- this hidden checkbox controls the state -->

    <input type="checkbox" class="hidden" @change="onToggle" />

    <div class="swap-off">
      <div class="container max-w-full flex flex-row inset-x-0 w-full gap-2">
        <conversion-rate-widget isPdfReport="true" loadingToggle="false" />

        <table-widget
          v-for="path in ['/widget/target-de-facto-comparison']"
          :key="path"
          :path="path"
          class="flex-auto"
          :ongoingPrint="ongoingPrint"
          isLargeItems="true"
          :selectedDate="$route.query.days"
        />
      </div>
    </div>
    <div class="swap-on">
      <div class="container max-w-full flex flex-row inset-x-0 w-full gap-10">
        <table-widget
          v-for="path in ['/report/project-analysis']"
          @onResponseToParent="onSaveResponse"
          :key="path"
          :path="path"
          class="flex-auto"
          :ongoingPrint="ongoingPrint"
          isLargeItems="true"
          :selectedDate="$route.query.days"
        />

        <table-widget
          v-for="path in ['/widget/target-de-facto-comparison']"
          @onResponseToParent="onSaveResponse"
          :key="path"
          :path="path"
          class="flex-auto"
          :ongoingPrint="ongoingPrint"
          isLargeItems="true"
          :selectedDate="$route.query.days"
        />
      </div>
    </div>
  </label>
</template>

<script>
import ConversionRateWidget from "../../views/DashboardJob/ConversionRateWidget/ConversionRateWidget.vue";
import TableWidget from "../widgets/TableWidget.vue";

export default {
  props: ["ongoingPrint"],
  components: {
    ConversionRateWidget,
    TableWidget,
  },
  data() {
    return {};
  },
  computed: {},

  methods: {
    onToggle(value) {
      this.$emit("onToggleChange", value);
    },
    onSaveResponse(data) {
      this.$emit("onResponseToParent", data);
    },
  },
};
</script>
<style scoped></style>
