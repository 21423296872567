<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="mt-7">
    <!-- <new-user-dialog
        @closed="hideNewUserOverlay"
        v-if="isNewUserOverlayDisplayed"
      /> -->
  </div>

  <div class="flex flex-col items-center">
    <div class="flex flex-row flex-1 relative w-80 mb-5 ml-10">
      <div class="flex w-40 items-center">
        <p>Sort User List</p>
      </div>

      <select
        class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded-md leading-tight focus:outline-none focus:ring focus:border-blue-300"
        @change="onSort"
      >
        >
        <option value="">Sort By</option>
        <option v-for="option in sortOptions" :key="option" :value="option">
          {{ option }}
        </option>
      </select>
    </div>

    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                ></th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ $t("admin-page.name") }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ $t("admin-page.email-address") }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ $t("admin-page.admin") }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Last Login
                </th>
                <th scope="col" class="relative px-6 py-3 text-right">
                  <button
                    type="button"
                    @click="createNewOrEditUser(0)"
                    class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
                  >
                    <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    {{ $t("admin-page.user") }}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="user in users" :key="user.email">
                <td v-if="isSuperAdmin" class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div
                      class="rounded-xl p-1 px-3 bg-gray-400 hover:bg-[#2197a7] cursor-pointer"
                      @click="onLogInAsUser(user)"
                    >
                      <p class="font-semibold text-white">Log in As User</p>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="text-sm text-gray-500">
                      {{ user.name }}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="text-sm text-gray-500">
                      {{ user.mail }}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <span
                    v-if="user.admin"
                    class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                  >
                    {{ $t("admin-page.admin") }}
                  </span>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="text-sm text-gray-500">
                      {{ fetchDateTime(user.lastLogin) }}
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                >
                  <a
                    href="#"
                    @click="createNewOrEditUser(user.id)"
                    class="text-blue-600 hover:text-blue-900 mr-5"
                  >
                    Edit</a
                  >
                  <a
                    href="#"
                    @click="deleteUser(user.id)"
                    class="text-red-600 hover:text-red-900"
                  >
                    {{ $t("admin-page.delete") }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PlusIcon } from "@heroicons/vue/solid";
import axios from "axios";
import {
  getGeneralDataInCache,
  storeGeneralDataInCache,
} from "../../components/helperFunctions/DataCaching";

export default {
  data() {
    return {
      users: [],
      sortOptions: ["Name", "Role", "Last Login"],
    };
  },
  methods: {
    ...mapActions(["setActualUserDetails", "setUserInfo", "getAllUserList"]),

    fetchDateTime(dateTime) {
      if (dateTime) {
        dateTime = new Date(dateTime);
        return dateTime.toLocaleString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        });
      } else {
        return "-";
      }
    },
    deleteUser: function (id) {
      if (confirm("Do you really want to delete?")) {
        let headers = this.authorizationHeader;
        axios
          .delete(this.backendUrl + "/auth/user/delete?id=" + id, { headers })
          .then(() => {
            this.$emit("closed");
            this.getAllUserList().then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            alert("Something went wrong.");
            console.log(error);
          });
      }
    },
    onSort(e) {
      if (e.target.value == "Name") {
        this.users.sort((a, b) => a.name.localeCompare(b.name));
      } else if (e.target.value == "Role") {
        this.users.sort((a, b) => b.admin - a.admin);
      } else if (e.target.value == "Last Login") {
        this.users.sort(
          (a, b) => new Date(b.lastLogin) - new Date(a.lastLogin)
        );
      }
    },
    onLogInAsUser(userInfo) {
      let tempUser = {
        isAdmin: userInfo.admin,
        mail: userInfo.mail,
        name: userInfo.name,
        roleName: userInfo.roles[0].name,
      };
      storeGeneralDataInCache("actualSuperAdmin", this.user);
      storeGeneralDataInCache("user", tempUser);

      this.setUserInfo({ ...tempUser });
      this.setActualUserDetails({ ...this.user });
    },
    createNewOrEditUser(id) {
      this.$router.push(`/admin/createOrUpdateUser/${id}`);
    },
    // hideNewUserOverlay() {
    //   this.isNewUserOverlayDisplayed = false;
    //   this.getUsers();
    // },
  },
  computed: {
    ...mapGetters([
      "authorizationHeader",
      "backendUrl",
      "user",
      "isSuperAdmin",
    ]),
  },
  mounted() {
    this.getAllUserList().then(() => {
      this.users = getGeneralDataInCache("userList");
    });
  },
  components: {
    PlusIcon,
  },
};
</script>

<style></style>
