<template>
  <skeleton-table-widget v-if="loading"></skeleton-table-widget>
  <div v-else class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <label
          class="block font-bold text-gray-700 whitespace-nowrap"
          :class="ongoingPrint ? 'text-2xl' : 'text-base'"
        >
          {{ $t(`report-pdf.${name}`) }}
        </label>
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="w-80 divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  v-for="header in columnHeaders"
                  v-bind:key="header"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in tableData"
                :key="data"
                :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td
                  class="p-3 font-medium text-gray-900 text-center w-2/5"
                  :class="ongoingPrint ? 'text-xs' : 'text-xs'"
                  v-if="hasRowHeaders"
                >
                  {{ $t(`report-pdf.${rowHeaders[index]}`) }}
                </td>
                <td
                  class="py-3 pr-3 text-gray-500"
                  :class="ongoingPrint ? 'text-xs' : 'text-xs'"
                >
                  <div class="grid grid-cols-2 gap-2">
                    <div class="grid grid-rows-6 grid-flow-col gap-2">
                      <div
                        class="whitespace-nowrap"
                        :class="
                          $store.state.auth.isPresentationMode
                            ? 'blur-effect'
                            : null
                        "
                        v-for="value in candidates"
                        :key="value"
                      >
                        {{ value }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import SkeletonTableWidget from "./SkeletonTableWidget.vue";

export default {
  components: {
    SkeletonTableWidget,
  },

  computed: {
    currentJobId() {
      return this.$route.params.jobId;
    },
    ...mapGetters(["authorizationHeader", "backendUrl"]),
  },
  props: ["path", "ongoingPrint"],
  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
  },
  data() {
    return {
      name: "",
      columnHeaders: [],
      rowHeaders: [],
      tableData: [],
      hasRowHeaders: false,
      loading: true,
      candidates: "",
    };
  },
  methods: {
    ...mapActions([
      "setIncreasePendingApisCount",
      "setDecreasePendingApisCount",
    ]),

    fetchDataFromBackend(jobId) {
      this.loading = true;
      const headers = this.authorizationHeader;
      this.setIncreasePendingApisCount();
      axios
        .get(this.backendUrl + this.path + "?jobId=" + jobId, {
          headers,
        })
        .then((response) => {
          let responseData = response.data;
          this.name = responseData.name;
          this.columnHeaders = responseData.column_headers;
          this.tableData = responseData.table_data;
          this.loading = false;
          this.setDecreasePendingApisCount();

          this.candidates = this.tableData[0][0];

          //Add tableData as an argument in createArrayWithCandidates and modified the method to add the argument
          this.createArrayWithCandidates(this.candidates);

          if (
            Object.prototype.hasOwnProperty.call(responseData, "row_headers")
          ) {
            this.hasRowHeaders = true;
            this.rowHeaders = responseData.row_headers;
          }

          this.$emit("onResponseToParent", responseData);
        })
        .catch((error) => console.log(error));
    },

    createArrayWithCandidates(candidates) {
      this.candidates = candidates.split(",");
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      this.fetchDataFromBackend(this.currentJobId);
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
