export default {
  setJob(context, payload) {
    context.commit("setJob", payload);
  },
  setCompany(context, payload) {
    context.commit("setCompany", payload);
  },
  setAllJobs(context, payload) {
    context.commit("setAllJobs", payload);
  },
  setFilteredActiveJobs(context, payload) {
    context.commit("setFilteredActiveJobs", payload);
  },
  setAllEndedJobs(context, payload) {
    context.commit("setAllEndedJobs", payload);
  },
  sortDataAz(context, payload) {
    context.commit("sortDataAz", payload);
  },
  sortDataZa(context, payload) {
    context.commit("sortDataZa", payload);
  },
  sortDataFavCompanies(context, payload) {
    context.commit("sortDataFavCompanies", payload);
  },
  sortDataFavJobs(context, payload) {
    context.commit("sortDataFavJobs", payload);
  },
  sortDataAzJobs(context, payload) {
    context.commit("sortDataAzJobs", payload);
  },
  sortDataZaJobs(context, payload) {
    context.commit("sortDataZaJobs", payload);
  },
  setActiveJobs(context, payload) {
    context.commit("setActiveJobs", payload);
  },
  resetJobVariables(context, payload) {
    context.commit("resetJobVariables", payload);
  },
  setAllCompany(context, payload) {
    context.commit("setAllCompany", payload);
  },
  setActiveCompanies(context, payload) {
    context.commit("setActiveCompanies", payload);
  },
  setFilteredActiveCompanies(context, payload) {
    context.commit("setFilteredActiveCompanies", payload);
  },
  resetCompanyVariables(context, payload) {
    context.commit("resetCompanyVariables", payload);
  },
  setKeywords(context, payload) {
    context.commit("setKeywords", payload);
  },
  setSortBy(context, payload) {
    context.commit("setSortBy", payload);
  },
  setIncreasePendingApisCount(context) {
    context.commit("setIncreasePendingApisCount");
  },
  setDecreasePendingApisCount(context) {
    context.commit("setDecreasePendingApisCount");
  },
  resetPendingApiCount(context, payload) {
    context.commit("resetPendingApiCount", payload);
  },
};
