<template>
  <skeleton-details-card-widgets :rows="15" v-if="loading" />
  <div v-else class="flex flex-col mt-10">
    <div class="-my-2 overflow-x-auto rounded-lg">
      <div
        class="pt-2 align-middle inline-block min-w-full px-6 pb-6 bg-white rounded-3xl"
      >
        <div class="rounded-lg bg-white">
          <label class="block text-2xl text-gray-700 p-4 mb-3">
            {{ $t(`detailsHistoricView.${name}`) }}
          </label>
          <table class="min-w-full divide-gray-200">
            <thead class="bg-white">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  v-for="header in columnHeaders"
                  v-bind:key="header"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in tableData"
                :key="data"
                :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'"
                class="rounded-2xl"
              >
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  :class="
                    keyword.length > 0 &&
                    keyword !== undefined &&
                    rowHeaders[index]
                      .toLowerCase()
                      .includes(keyword.toLowerCase()) &&
                    'text-red-400'
                  "
                >
                  {{
                    $t(
                      `detailsHistoricView.${rowHeaders[index].replaceAll(
                        ".",
                        ""
                      )}`
                    )
                  }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  v-for="(value, index) in data"
                  :key="value"
                  :class="
                    index !== data.length - 1
                      ? 'border-l-2'
                      : 'border-l-2 border-gray-400'
                  "
                >
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../../../components/helperFunctions/DataCaching";
import SkeletonDetailsCardWidgets from "./SkeletonDetailsCardWidgets.vue";

export default {
  components: {
    SkeletonDetailsCardWidgets,
  },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
    keyword() {
      return this.$store.getters.keywords;
    },
  },

  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
  },
  data() {
    return {
      name: "",
      columnHeaders: [],
      rowHeaders: [],
      tableData: [],
      loading: true,
      keywordSearched: "",
    };
  },

  methods: {
    fetchDataFromBackend(jobId) {
      if (jobId !== null) {
        this.loading = true;
        const headers = this.authorizationHeader;
        axios
          .get(this.backendUrl + "/widget/historic-view" + "?jobId=" + jobId, {
            headers,
          })
          .then((response) => {
            storeJobDataInCache(jobId, response.data, "HistoricView");
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.name = data.name;
      this.columnHeaders = data.column_headers;
      this.tableData = data.table_data;
      this.rowHeaders = data.row_headers;
      this.loading = false;
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "HistoricView")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "HistoricView")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
<style scoped>
tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
