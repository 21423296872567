<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <div>
        <p class="text-2xl font-semibold">
          {{ modalTitle }}
        </p>
      </div>

      <div>
        <div class="flex ...">
          <div
            class="h-1 w-1/2 mt-5 bg-green-500 border-r-8 border-green-700"
          ></div>
          <div :class="stepsCss('stepTwo')"></div>
        </div>
      </div>

      <company-information-step
        v-if="step == 1"
        @onModalClose="$emit('onModalClose')"
        @onNextStep="onNextStep"
        :companyInformation="companyInformation"
      />

      <contact-information-step
        v-if="step == 2"
        :companyId="this.companyId"
        :jobIds="jobIds"
        :companyName="this.companyName"
        :companyContactDetailsToEdit="companyContactDetailsToEdit"
        :contacts="contacts"
      />
    </div>
  </div>
</template>

<script>
import CompanyInformationStep from "./CompanyInformationStep.vue";
import ContactInformationStep from "./ContactInformationStep.vue";
export default {
  props: [
    "companyInformation",
    "companyContactDetailsToEdit",
    "editStep",
    "contacts",
    "jobIds",
  ],
  data() {
    return {
      step: this.editStep ?? 1,
      companyName: null,
    };
  },
  computed: {
    modalTitle() {
      if (this.step == 1) {
        if (this.companyInformation) return this.$t("add-new-job.edit-company");
        else return this.$t("add-new-job.add-new-company");
      } else {
        if (this.companyContactDetailsToEdit)
          return this.$t("add-new-job.edit-contact");
        else return this.$t("add-new-job.add-new-contact");
      }
    },
    companyId() {
      return this.companyInformation
        ? this.companyInformation.company_id
        : null;
    },
  },
  methods: {
    onNextStep(data) {
      // only set job id when it is returning from step 1 only
      if (data) {
        this.$store.dispatch("resetCompanyVariables");
        this.companyName = data.newCompanyName;
      }
      this.step += 1;
    },
    stepsCss(stepName) {
      // function to return the css for steps according to given parameter
      let compareNumber = stepName == "stepTwo" ? 2 : 3;
      if (this.step >= compareNumber)
        return "h-1 w-1/2 mt-5 bg-green-500 border-r-8 border-green-700";
      else return "h-1 w-1/2 mt-5 bg-gray-400 border-r-8 border-gray-600";
    },
  },
  components: {
    CompanyInformationStep,
    ContactInformationStep,
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 6% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  border-radius: 20px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
