export default {
  toggleProjectPreferences(context) {
    context.commit("toggleProjectPreferences");
  },
  toggleSidePanelWithHidden(context, payload) {
    context.commit("toggleSidePanelWithHidden", payload);
  },
  closeProjectPreferences(context) {
    context.commit("closeProjectPreferences");
  },
  setAtsOpen(context) {
    context.commit("setAtsOpen");
  },
  setAtsClose(context) {
    context.commit("setAtsClose");
  },
  setModalSubmitData(context, payload) {
    context.commit("setModalSubmitData", payload);
  },
  setToggleLoading(context, payload) {
    context.commit("setToggleLoading", payload);
  },
  setFieldValues(context, payload) {
    context.commit("setFieldValues", payload);
  },
  setProjectStatusBar(context, payload) {
    context.commit("setProjectStatusBar", payload);
  },
  setApiToRun(context, payload) {
    context.commit("setApiToRun", payload);
  },
};
