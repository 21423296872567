<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div @click="toggleShowMenu" class="mr-5 cursor-pointer">
    <logo-container
      aria-hidden="true"
      :initials="initials"
      :childStyle="bgStyle"
    />
  </div>

  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class=" opacity-0 scale-95"
    enter-to-class="opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class=" opacity-100 scale-100"
    leave-to-class=" opacity-0 scale-95"
  >
    <div v-if="showMenu">
      <div
        class="absolute right-5 top-16 mt-2 w-72 rounded-md shadow-lg bg-white divide-y divide-gray-100 z-20"
      >
        <div class="mb-2 bg-white">
          <div class="flex flex-row items-center justify-center">
            <logo-container :initials="initials" :childStyle="userInfoStyle" />
            <div class="flex-col m-2">
              <p class="capitalize">{{ user.name }}</p>
              <p class="text-gray-500 text-sm">{{ user.mail }}</p>
              <p v-if="user.isAdmin" class="text-sm text-gray-400">Admin</p>
              <div class="text-sm underline text-gray-500 mt-2 cursor-pointer">
                {{ $t("right-menu-mobile.edit") }}
              </div>
            </div>
          </div>
        </div>
        <div class="py-1">
          <div v-if="isAdminValue">
            <div
              @click="redirectToOverviewPage"
              class="text-gray-700 group cursor-pointer flex items-center pl-5 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            >
              <ChartBarIcon
                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              Overview
            </div>
          </div>

          <div v-if="isAdminValue">
            <div
              @click="redirectToAdminPage"
              class="text-gray-700 cursor-pointer group flex items-center pl-5 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            >
              <UserIcon
                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              {{ $t("right-menu-mobile.admin") }}
            </div>
          </div>

          <div class="py-1" v-if="isAdminValue">
            <!-- <div
              @click="atsService"
              class="text-gray-700 group flex items-center pl-5 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 <<<<<<< HEAD cursor-pointer ======= >>>>>>> afe8de0900edb0db94d2486f5b8521fee1bea041"
            > -->
            <div
              class="cursor-not-allowed text-gray-700 group flex items-center pl-5 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            >
              <LinkIcon
                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              {{ $t("right-menu-mobile.ats-settings") }}
            </div>
          </div>
        </div>
        <div class="py-1">
          <div
            @click="logout"
            class="bg-gray-400 rounded-lg w-10/12 my-0 mx-auto py-2 flex justify-center hover:text-gray-500 text-sm text-gray-200"
          >
            <LogoutIcon
              class="mr-3 h-5 w-5 text-gray-100 group-hover:text-gray-500"
              aria-hidden="true"
            />
            {{ $t("right-menu-mobile.logout") }}
          </div>

          <div class="grid grid-cols-2">
            <div>
              <locale-switcher />
            </div>
            <div v-if="isAdminValue">
              <PresentationModeSwitcher />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from "vuex";

import LocaleSwitcher from "./LocaleSwitcher.vue";
import LogoContainer from "../../ui/LogoContainer.vue";

import {
  LinkIcon,
  UserIcon,
  LogoutIcon,
  ChartBarIcon,
} from "@heroicons/vue/outline";
import PresentationModeSwitcher from "./PresentationModeSwitcher.vue";

export default {
  components: {
    LocaleSwitcher,
    LogoContainer,
    LinkIcon,
    UserIcon,
    LogoutIcon,
    PresentationModeSwitcher,
    ChartBarIcon,
  },
  data() {
    return {
      showMenu: false,
      bgStyle: {
        background: "rgb(229 231 235)",
        border: "solid 1px black",
        width: "3rem",
      },
      userInfoStyle: {
        background: "rgb(229 231 235)",
        border: "solid 1px black",
        width: "3.5rem",
        height: "3.5rem",
      },
    };
  },
  computed: {
    isAdminValue() {
      return this.$store.getters.isAdmin;
    },
    initials() {
      return this.$store.getters.userInitials;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    ...mapActions(["toggleProjectPreferences", "isAdmin"]),

    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
    redirectToOverviewPage() {
      this.$router.push("/overview");
    },
    redirectToAdminPage() {
      this.$router.push("/admin");
    },
    atsService() {
      this.$router.push("/atssettings");
    },
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style>
@media print {
  #appHeader {
    display: none;
  }
}
</style>
};
