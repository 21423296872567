<template>
  <!-- ============================== companies list start ======================================= -->
  <div
    v-if="role != 'ROLE_ADMIN' && allCompaniesList.length > 0"
    class="flex flex-1 justify-center mt-10 mb-10"
  >
    <select
      class="select select-primary w-full max-w-xs"
      @change="onAddNewCompanyModal($event.target.value)"
    >
      <option selected value="AddCompany">
        {{ $t(`add-new-job.${selectText}`) }}
      </option>
      <option
        v-for="company in allCompaniesList"
        :value="`${company.name}-Dashr-${company.id}`"
        :key="company.name"
      >
        {{ company.name }}
      </option>
    </select>
  </div>
  <!-- ============================== companies list end ======================================= -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    selectText() {
      if (this.isRoleClient && this.assignedCompaniesList.length > 0)
        return "change-company";
      else return "add-company";
    },
    ...mapGetters([
      "controls",
      "allCompaniesList",
      "assignedCompaniesList",
      "isRoleClient",
    ]),
  },
  methods: {
    ...mapActions([
      "setSelectedAddEditCompanyName",
      "setSelectedAddEditCompanyId",
      "setIsModal",
    ]),
    onAddNewCompanyModal(company) {
      // split the name and id from value prop of select with splitter -Dashr-
      this.setSelectedAddEditCompanyName(company.split("-Dashr-")[0]);
      this.setSelectedAddEditCompanyId(company.split("-Dashr-")[1]);
      this.setIsModal("addCompany");
    },
  },
};
</script>
