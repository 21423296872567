<template>
  <div class="bg-gray-200 mb-4 rounded-lg pl-9 pr-5 rounded-b-lg pb-12">
    <div class="flex flex-row items-center justify-between">
      <p class="pt-6 text-xl text-gray-700 mb-7">
        {{ $t("detailsCard.page-title") }}
      </p>
      <keyword-search-bar />
    </div>

    <div class="pl-6 w-11/12">
      <historic-view-widget />
      <project-analysis-widget />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import HistoricViewWidget from "./DetailsCardWidget/HistoricViewWidget.vue";
import ProjectAnalysisWidget from "./DetailsCardWidget/ProjectAnalysisWidget.vue";
import KeywordSearchBar from "./DetailsCardWidget/KeywordSearchBar.vue";
export default {
  components: {
    HistoricViewWidget,
    ProjectAnalysisWidget,
    KeywordSearchBar,
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl"]),
  },
  methods: {
    fetchDataCompaniesGoBackButton() {
      let companies = this.$store.getters.storeState.project.companies;

      let companySelectWithParam = companies.filter((company) => {
        return company.id == document.URL.split("/").reverse()[1];
      });
      this.$store.dispatch("setCompany", {
        newCompanyName: companySelectWithParam[0].name,
        newCompanyId: companySelectWithParam[0].id,
      });

      this.fetchDataJobGoBackButton(companySelectWithParam[0].id);
    },
    fetchDataJobGoBackButton(id) {
      const headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + "/menu/jobs-by-company/" + id, {
          headers,
        })
        .then((response) => {
          let jobs = response.data;

          let selected = jobs.filter(
            (job) => job.id == document.URL.split("/").slice(-1)[0]
          );
          this.$store.dispatch("setJob", {
            newJobId: selected[0].id,
            newJobName: selected[0].name,
            newKookuId: selected[0].kookuId,
          });
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    window.onpopstate = () => {
      this.fetchDataCompaniesGoBackButton();
    };
  },
};
</script>
