<template>
  <app-header />
  <div class="pt-20 bg-gray-100 h-screen">
    <div
      class="
        w-2/4
        flex flex-col
        items-center
        my-0
        mx-auto
        px-4
        py-16
        bg-white
        rounded-lg
      "
    >
      <h2 class="mb-16 text-center text-3xl">
        Wähle das ATS aus um Projektdaten in Dashr zu verwenden.
      </h2>

      <external-services-connector />
    </div>
  </div>
</template>
<script>
import ExternalServicesConnector from "./../components/admin/ExternalServicesConnector.vue";
import AppHeader from "./../components/layout/header/AppHeader.vue";
export default {
  components: {
    ExternalServicesConnector,
    AppHeader,
  },
};
</script>
