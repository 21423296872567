<template>
  <skeleton-project-details-widget v-if="loading" />
  <div v-else-if="this.name !== 'Zeitrahmen'" class="rounded-lg mt-5">
    <div
      class="overflow-hidden border-b border-gray-200 h-72 bg-white rounded-3xl shadow-2xl"
    >
      <div v-if="this.name !== 'Zeitrahmen'" class="flex justify-between">
        <div>
          <label class="block text-black text-xl bg-white pl-5 pt-3">
            {{ $t(`dashboardProjectDetails.${name}`) }}
          </label>
        </div>
      </div>

      <div class="bg-white pl-5 pr-2 py-2 text-base text-gray-500">
        <!-- project details part  -->
        <ul v-for="(data, index) in tableData" :key="data">
          <div class="flex flex-row my-2">
            <li>{{ $t(`dashboardProjectDetails.${rowHeaders[index]}`) }}:</li>
            <li
              class="ml-2"
              v-for="value in data"
              :key="value"
              :class="
                $store.state.auth.isPresentationMode &&
                ['Kunde', 'Projektnummer'].includes(rowHeaders[index])
                  ? 'blur-effect'
                  : null ||
                    (index === 3 &&
                      (rowHeaders[index] == 'zu besetzende Vakanzen' ||
                        rowHeaders[index] == 'Vacancies to fill') &&
                      'font-bold') ||
                    (index === 2 &&
                      (rowHeaders[index] == 'Ansprechpartner' ||
                        rowHeaders[index] == 'Contact') &&
                      'font-bold')
              "
            >
              {{ value }}
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import {
  storeJobDataInCache,
  jobDataInCache,
} from "../../../components/helperFunctions/DataCaching";
import SkeletonProjectDetailsWidget from "./SkeletonProjectDetailsWidget.vue";

export default {
  props: ["path", "loadingToggle"],
  components: {
    SkeletonProjectDetailsWidget,
  },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
    ...mapGetters("job", ["projectDetails"]),

    toggleLoadingSaveButton() {
      return this.$store.getters.toggleLoading;
    },
  },

  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
    toggleLoadingSaveButton() {
      this.fetchDataFromBackend(this.currentJobId);
    },
  },
  data() {
    return {
      name: "",
      rowHeaders: [],
      tableData: [],
      loading: true,
      isModal: "",
      TimeTrackingGaugeDetails: {},
    };
  },
  methods: {
    ...mapActions("job", ["setProjectDetails", "setTimeFrame"]),

    onModalClose() {
      this.isModal = "";
    },
    onOpenSetting() {
      if (this.name == "Zeitrahmen") this.isModal = "Zeitrahmen";
    },
    fetchDataFromBackend(jobId) {
      this.loading = true;
      const headers = this.authorizationHeader;
      if (jobId !== null) {
        axios
          .get(this.backendUrl + this.path + "?jobId=" + jobId, {
            headers,
          })
          .then((response) => {
            storeJobDataInCache(
              jobId,
              response.data,
              "ProjectDetailsTimeframe"
            );
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.name = data.name;
      this.tableData = data.table_data;
      this.rowHeaders = data.row_headers;
      this.loading = false;
      this.setResponseToVueX(data);
    },
    setResponseToVueX(response) {
      let temp = {};
      response.row_headers.map((rowValue, index) => {
        temp = { ...temp, [rowValue]: response.table_data[index][0] };
      });
      this.setProjectDetails({ ...temp });
      this.setTimeFrame({ ...temp });
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "ProjectDetailsTimeframe")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "ProjectDetailsTimeframe")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
