<template>
  <TransitionRoot as="template" :show="atsDialog">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block align-bottom bg-white rounded-lg text-left transform transition-all h-full mt-16 w-3/6 pt-7"
          >
            <div
              class="relative inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left w-full transform transition-all h-full"
            >
              <div class="relative flex flex-col mx-7 mb-5 my-0 w-10/12">
                <div class="text-xl my-5">
                  {{ $t("greetings-page.welcome-dialog") }}
                </div>

                <div class="text-lg">
                  {{ $t("greetings-page.subtitle-dialog") }}
                </div>
                <div class="text-base">
                  {{ $t("greetings-page.description-dialog") }}
                </div>
                <div class="flex flex-col items-center my-3">
                  <external-services-connector />
                </div>
              </div>

              <div class="flex justify-between mb-5 mr-2">
                <div class="flex flex-row items-center">
                  <input
                    class="ml-4"
                    type="checkbox"
                    id="noShow"
                    v-model="checked"
                  />
                  <label class="ml-2" for="noShow">Don't show again</label>
                </div>
                <button
                  class="flex items-center shadow-md"
                  @click="closeAtsDialog"
                >
                  <p class="underline p-1">
                    {{ $t("greetings-page.choose-later-dialog") }}
                  </p>
                  <ChevronRightIcon class="w-4 h-4 ml-1" />
                </button>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { ChevronRightIcon } from "@heroicons/vue/solid";
import ExternalServicesConnector from "./../components/admin/ExternalServicesConnector.vue";

export default {
  components: {
    Dialog,
    DialogOverlay,
    ChevronRightIcon,
    TransitionChild,
    TransitionRoot,
    ExternalServicesConnector,
  },
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    ...mapGetters(["atsDialog"]),
  },
  methods: {
    ...mapActions(["setAtsClose"]),
    closeAtsDialog() {
      this.setAtsClose();
      this.$router.push("/clients");
      this.$store.dispatch("setNoShowAtsConnector", this.checked);
    },
  },
};
</script>
