<template>
  <div>
    <button
      class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
      @click="$emit('onModalClose')"
      type="button"
      v-if="!isCancelDisabled"
      :disabled="loader"
    >
      <span>Cancel</span>
    </button>
    <button
      class="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-md primary-button"
      @click="$emit('onSubmit')"
      :disabled="loader"
    >
      <span>{{ buttonText }}</span>
    </button>
  </div>
</template>

<script>
export default {
  props: ["buttonText", "isCancelDisabled", "loader"],
};
</script>
