<template>
  <div>
    <!--------------------------------- ASSIGNED COMPANIES AND JOBS PART --------------------------------->
    <div
      v-if="
        !['ROLE_ADMIN', ''].includes(role) && assignedCompaniesList.length > 0
      "
    >
      <div>
        <div class="flex flex-row justify-center">
          <p class="text-lg font-semibold">
            {{ $t("admin-page.assigned-company") }}
          </p>
        </div>
      </div>

      <div class="flex justify-center mt-2">
        <p class="italic text-sm">
          {{ $t("right-menu-mobile.company-edit-message") }}
        </p>
      </div>

      <div
        tabindex="0"
        class="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box mt-4"
        v-for="company in assignedCompaniesList"
        :key="company"
      >
        <div class="collapse-title text-md font-medium">
          <button
            class="text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center w-full justify-center"
            @click="editCompany(company)"
          >
            {{ company["company_name"] }}
          </button>
        </div>
        <div class="collapse-content">
          <div class="grid grid-cols-1 gap-4">
            <div v-for="job in company.jobs" :key="job">
              <p>{{ job.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    role() {
      return this.controls.role.value;
    },
    ...mapGetters(["controls", "assignedCompaniesList", "roles"]),
  },
  methods: {
    ...mapActions([
      "setSelectedAddEditCompanyName",
      "setSelectedAddEditCompanyId",
      "setSelectedEditJobsList",
      "setIsModal",
    ]),
    editCompany(company) {
      this.setSelectedAddEditCompanyName(company["company_name"]);
      this.setSelectedAddEditCompanyId(company["company_id"]);
      this.setSelectedEditJobsList([...company.jobs]);
      this.setIsModal("addCompany");
    },
  },
};
</script>
