<template>
  <skeleton-time-expenditure v-if="loading" />
  <div v-else class="">
    <div
      class="h-72 w-full rounded-3xl shadow-2xl pl-3 pr-1"
      style="background-color: rgba(104, 67, 149, 0.3)"
    >
      <p class="text-2xl text-black inline-block ml-4 mr-1 mt-3 mb-2">
        {{ $t(`projectTimeExpenditurePerWeek.${name}`) }}:
      </p>

      <p class="ml-4 text-black text-sm mb-4">
        {{ $t(`projectTimeExpenditurePerWeek.Pro Woche Schätzwert`) }}
      </p>
      <div class="flex flex-row justify-center">
        <vue-gauge
          :refid="'Time-Tracking-Hour-Gauge'"
          :options="timeTrackingGaugeOption"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import SkeletonTimeExpenditure from "./SkeletonTimeExpenditure.vue";
import VueGauge from "vue-gauge";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../../../components/helperFunctions/DataCaching";

export default {
  components: {
    SkeletonTimeExpenditure,
    VueGauge,
  },
  props: ["loadingToggle"],
  data() {
    return {
      actualTime: null,
      expectedTime: null,
      percent: null,
      name: "",
      columnHeaders: [],
      rowHeaders: [],
      tableData: [],

      loading: true,
    };
  },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
    toggleLoadingSaveButton() {
      return this.$store.getters.toggleLoading;
    },
    timeTrackingGaugeOption() {
      let labels = [
        this.actualTime.replace(/\s/g, ""),
        `${this.expectedTime}h`,
      ];
      let percentage = this.percent;
      let delimiters = [percentage + 1, 90];
      let arcColors = ["#4ade80", "#2eb15e", "rgb(248, 113, 113)"];
      // calculate percent for different scenarios
      if (percentage == 0) {
        delimiters = [1, 90];
        labels = [``, `${this.expectedTime}h`];
      } else if (percentage >= 85 && percentage <= 99) {
        // this case is to avoid overlapping of labels
        percentage = 85;
        delimiters = [85, 90];
      } else if (percentage == 100) {
        // this case when percentage become almost 100% so remove expected hours and only show actual time in labels
        percentage = 90;
        delimiters = [90, 90];
        labels = [this.actualTime.replace(/\s/g, ""), ``];
      } else if (percentage > 100) {
        percentage = 95;
        delimiters = [85, 95];
        labels = [`${this.expectedTime} h`, this.actualTime.replace(/\s/g, "")];
        arcColors = ["#4ade80", "rgb(248, 113, 113)", "rgb(248, 113, 113)"];
      }

      return {
        needleValue: percentage,
        arcDelimiters: delimiters,
        arcColors: arcColors,
        arcLabels: labels,
        needleColor: "rgb(75 85 99)",
        centralLabel: `${this.percent}%`,
        rangeLabel: ["", ""],
        arcOverEffect: true,
        chartWidth: 240,
      };
    },
  },
  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
      this.setPercent();
    },
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
    toggleLoadingSaveButton() {
      this.fetchDataFromBackend(this.currentJobId);
    },
  },
  methods: {
    fetchDataFromBackend(jobId) {
      if (jobId !== null) {
        const headers = this.authorizationHeader;
        axios
          .get(
            this.backendUrl + "/widget/time-consumption" + "?jobId=" + jobId,
            {
              headers,
            }
          )
          .then((response) => {
            storeJobDataInCache(jobId, response.data, "TimeExpenditure");
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.name = data.name;
      this.columnHeaders = data.column_headers;
      this.tableData = data.table_data;
      this.rowHeaders = data.row_headers;
      this.actualTime = this.tableData[0][0];
      this.expectedTime = Math.round(this.tableData[1][0]);
      this.percent = JSON.parse(this.tableData[2][0]);
      this.loading = false;
    },
    setPercent() {
      return (this.percent = (this.actualTime * 100) / this.expectedTime);
    },
  },
  mounted() {
    this.setPercent();
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "TimeExpenditure")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "TimeExpenditure")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
<style scoped>
.timeLevel {
  animation: draw 1s ease-in-out;
}
@keyframes draw {
  0% {
    width: 0;
  }
}
</style>
