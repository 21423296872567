export default function calculateHours(
  inputData,
  NumberOfApplicated,
  projectStatusBar
) {
  // variables use to calculate each bar
  let index = 0;
  let objectKeys = {
    time_expenditure_managing_applications: 0,
    time_expenditure_identifying_candidates: 0,
    time_expenditure_contacting_candidates: 0,
    time_expenditure_handling_replies: 0,
    time_expenditure_conducting_phone_interview: 0,
    time_expenditure_managing_phone_interview_with_client: 0,
    time_expenditure_having_personal_meeting: 0,
    percentage_project_management: 0,
  };

  // assign values to each object key from inputData
  Object.keys(objectKeys).map((singleKey) => {
    index = inputData.findIndex((singleObject) => singleObject.id == singleKey);
    objectKeys[singleKey] = parseInt(inputData[index].value);
  });

  // ======================================================== calculate hours ====================================================

  // NumberOfApplicated * timeExpenditureManagingApplications
  let estimatedTimeSpentApplications =
    parseInt(NumberOfApplicated) *
    objectKeys["time_expenditure_managing_applications"];
  estimatedTimeSpentApplications = parseFloat(
    (estimatedTimeSpentApplications / 60.0).toFixed(5)
  );

  // Kandidaten gesamt recherchiert * timeExpenditureIdentifyingCandidates
  let estimatedTimeSpentCandidatesTotallyResearched =
    projectStatusBar["Kandidaten_gesamt_recherchiert"] *
    objectKeys["time_expenditure_identifying_candidates"];
  estimatedTimeSpentCandidatesTotallyResearched = parseFloat(
    (estimatedTimeSpentCandidatesTotallyResearched / 60.0).toFixed(5)
  );

  // Kandidaten kontaktiert * timeExpenditureContactingCandidates
  let estimatedTimeSpentCandidatesContacted =
    projectStatusBar["Kandidaten_kontaktiert"] *
    objectKeys["time_expenditure_contacting_candidates"];
  estimatedTimeSpentCandidatesContacted = parseFloat(
    (estimatedTimeSpentCandidatesContacted / 60.0).toFixed(5)
  );

  // Kandidaten mit Rückmeldung * timeExpenditureHandlingReplies
  let estimatedTimeSpentCandidatesWithReplies =
    projectStatusBar["Kandidaten_mit_Rückmeldung"] *
    objectKeys["time_expenditure_handling_replies"];
  estimatedTimeSpentCandidatesWithReplies = parseFloat(
    (estimatedTimeSpentCandidatesWithReplies / 60.0).toFixed(5)
  );

  // Telefoninterviews * timeExpenditureConductingPhoneInterview
  let estimatedTimeSpentPhoneAndInterested =
    projectStatusBar["Telefoninterviews"] *
    objectKeys["time_expenditure_conducting_phone_interview"];
  estimatedTimeSpentPhoneAndInterested = parseFloat(
    (estimatedTimeSpentPhoneAndInterested / 60.0).toFixed(5)
  );

  // beim Kunden vorgestellt gesamt * timeExpenditureManagingPhoneInterviewWithClient
  let estimatedTimeSpentPresentationToClient =
    projectStatusBar["Vorstellung_beim_Kunden"] *
    objectKeys["time_expenditure_managing_phone_interview_with_client"];
  estimatedTimeSpentPresentationToClient = parseFloat(
    (estimatedTimeSpentPresentationToClient / 60.0).toFixed(5)
  );

  // Persönliches Gespräch Kunde * timeExpenditureHavingPersonalMeeting
  let estimatedTimeSpentInterviewAtClient =
    projectStatusBar["Persönliches_Gespräch_Kunde"] *
    objectKeys["time_expenditure_having_personal_meeting"];
  estimatedTimeSpentInterviewAtClient = parseFloat(
    (estimatedTimeSpentInterviewAtClient / 60.0).toFixed(5)
  );

  let estimatedTotalTimeSpent =
    estimatedTimeSpentApplications +
    estimatedTimeSpentCandidatesTotallyResearched +
    estimatedTimeSpentCandidatesContacted +
    estimatedTimeSpentCandidatesWithReplies +
    estimatedTimeSpentPhoneAndInterested +
    estimatedTimeSpentPresentationToClient +
    estimatedTimeSpentInterviewAtClient;

  let estimatedTimeSpentProjectManagement =
    (estimatedTotalTimeSpent * objectKeys["percentage_project_management"]) /
    100.0;

  return parseFloat(
    (estimatedTimeSpentProjectManagement + estimatedTotalTimeSpent).toFixed(1)
  );
}
