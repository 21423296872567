<template>
  <transition name="appear">
    <div
      v-if="show"
      class="flex justify-center align-middle h-screen items-center"
    >
      <div class="flex justify-center">
        <div class="flex-col justify-items-center">
          <img
            class="h-18 w-auto my-0 mx-auto"
            src="@/assets/logo_transparent.png"
            alt="Kooku Logo"
          />

          <p class="my-11 text-center text-3xl font-semibold">
            {{ $t("greetings-page.welcome") }}
          </p>
          <div class="flex flex-row justify-center items-center">
            <logo-container
              :initials="initials"
              :childStyle="bg"
            ></logo-container>
            <div class="flex-col m-2">
              <p class="capitalize">{{ user.name }}</p>
              <p v-if="user.isAdmin" class="text-sm text-gray-400">
                {{ $t("greetings-page.admin") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <ats-link-dialog v-if="!noShowAtsLocalStorage" />
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import LogoContainer from "./../components/ui/LogoContainer.vue";
import AtsLinkDialog from "./AtsLinkDialog";

export default {
  components: {
    LogoContainer,
    AtsLinkDialog,
  },
  data() {
    return {
      show: false,
      noShowAtsLocalStorage: false,
      bg: { width: "3.5rem", height: "3.5rem", background: "rgb(229 231 235)" },
    };
  },
  computed: {
    ...mapGetters(["atsDialog"]),
    user() {
      return this.$store.getters.user;
    },
    initials() {
      return this.$store.getters.userInitials;
    },
  },
  methods: {
    ...mapActions(["setAtsClose", "setAtsOpen"]),
    convertStringInBoolean() {
      this.noShowAtsLocalStorage =
        localStorage.getItem("noShowAtsConnectorLS") === "true";
    },
  },
  created() {
    setTimeout(() => (this.show = false), 2500);
    this.setAtsClose();
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    let timeTillPopUp = 2500;
    if (urlParams.has("success")) timeTillPopUp = 0;
    this.show = true;
    setTimeout(() => this.setAtsOpen(), timeTillPopUp);

    this.convertStringInBoolean();

    if (this.noShowAtsLocalStorage === true) {
      setTimeout(() => this.$router.replace("/clients"), 2500);
    }
  },
};
</script>
<style scoped>
.appear-enter-from,
.appear-leave-to {
  opacity: 0;
}
.appear-enter-active,
.appear-leave-active {
  transition: opacity 2.5s ease-in-out;
}
.appear-enter-to,
.appear-leave-from {
  opacity: 1;
}
</style>
