<template>
  <div
    class="h-auto w-auto bg-white hover:bg-gray-300 p-3 m-2 shadow-xl rounded-lg btn-ghost border border-gray-400"
  >
    <div class="flex flex-row justify-between items-center h-full">
      <div class="grid grid-cols-12 gap-4 w-full items-center">

        <div class="col-span-1">
          <div class="flex flex-1 items-center">
            <div class="badge rounded-md border-[#9CA3AF] bg-[#9CA3AF] hover:bg-[#000000] ml-1">
            <p class="text-xs">
              {{ jobDetails.job_project_code }}
            </p>
            </div>
          </div>
        </div>

        <div class="col-span-3">
          <div
            class="flex flex-1 flex-wrap items-center h-full cursor-pointer hover:text-[#186c77]"
            @click="openJobDetails()"
          >
            <CheckCircleIcon
              v-if="jobDetails.job_has_hire == 'true'"
              class="w-6 h-6 mr-2"
              style="color: green"
            />
            <p
              class="mr-1 text-md font-semibold"
              :class="highlightSearchText(word)"
              :key="i"
              v-for="(word, i) in jobDetails.job_name.split(' ')"
            >
              {{ word }}
            </p>
            <p>
              <!-- <p class="ml-2 text-md font-semibold">{{ jobDetails.job_name }}</p> -->
            </p>
          </div>
        </div>

        <div class="col-span-1">
          <p class="mr-1 text-xs font-semibold text-center">
            {{ jobDetails.job_project_name }}
          </p>
        </div>

        <div class="col-span-1 flex flex-1 justify-center items-center">
            <p 
                v-if="this.jobDetails.job_recruiting_specialist !=='' && this.jobDetails.job_recruiting_specialist !== 'null'" 
                class="mr-1 text-md font-semibold" 
                style="color: #2197a7">
                {{ jobDetails.job_recruiting_specialist }}
            </p>
        </div>

        <div class="col-span-1 flex flex-1 justify-center items-center">
          <ExclamationCircleIcon
            v-if="jobDetails.is_all_roles_assigned === 'false'"
            class="w-6 h-6 mr-2" style="color: #f87171"
          />
        </div>

        <div class="col-span-1 flex flex-1 justify-center items-center relative">
          <UserGroupIcon
            v-if="jobDetails.job_hire_count > 0"
            class="w-6 h-6 mr-2"
            style="color: #9CA3AF"
          />
          <span
            v-if="jobDetails.job_hire_count > 0"
            class="absolute top-1 right-5 bg-green-700 text-white rounded-full text-xs w-4 h-4 flex items-center justify-center"
          >
            <b>{{ jobDetails.job_hire_count }}</b>
          </span>
        </div>

        <div class="col-span-1 justify-center">
          <hashtag-add-or-edit
            :hashtags="jobDetails.job_tags"
            :categories="jobDetails.tag_category"
            @onHashtagClick="onHashtagClick"
          />
        </div>

        <div class="col-span-1 flex flex-1 justify-center items-center relative">
          <span
            class="absolute  bg-gray-500 text-white rounded-full text-xs w-11 h-11 flex items-center justify-center"
          >
            <b>{{ jobDetails.job_time_hours }}</b>
          </span>
        </div>

        <div class="col-span-1 flex flex-1 justify-center items-center relative">
          <span
            class="absolute  bg-gray-500 text-white rounded-full text-xs w-11 h-11 flex items-center justify-center"
          >
            <b>{{ jobDetails.job_time_weeks }}</b>
          </span>
        </div>

        <!-- <div class="col-span-2">
          <div class="flex flex-1 justify-end">
            <button
              class="text-gray-800 font-bold p-2 rounded-xl inline-flex items-center"
              @click="onOpenUseAsTemplate()"
            >
              <span class="text-[#2197a7] hover:text-[#2a656d]">{{
                $t("job-clustering.template")
              }}</span>
            </button>
          </div>
        </div> -->

        <div class="col-span-1">
          <div class="flex flex-1 justify-end flex-wrap">
          
            <div class="badge rounded-md border-[#9CA3AF] bg-[#9CA3AF] hover:bg-[#000000] ml-1">
            <p class="text-xs">
              {{ jobDetails.job_date_start }}
            </p>
            </div>
            <div class="badge rounded-md border-[#9CA3AF] bg-[#9CA3AF] hover:bg-[#000000] ml-1 mt-1">
            <p class="text-xs">
              {{ jobDetails.job_date_end }}
            </p>
            </div>
          </div>
        </div>


      </div>
    </div>

    <add-or-remove-hash-tags-modal
      :details="jobDetails"
      isForJob="true"
      v-if="onModal == 'AddOrRemoveHashtags'"
      @onHashtagSave="onHashtagSave"
    />

    <use-as-template-modal
      :job="jobDetails"
      isForJob="true"
      v-if="onModal == 'useAsTemplateModal'"
      @onModalClose="onModalClose"
    />
  </div>
</template>

<script>
import AddOrRemoveHashTagsModal from "../../../components/Modals/AddOrRemoveHashtags/AddOrRemoveHashTagsModal.vue";
import UseAsTemplateModal from "../../../components/Modals/JobClustering/UseAsTemplateModal.vue";
import { mapGetters } from "vuex";
import HashtagAddOrEdit from "../../../components/ui/HashtagAddOrEdit.vue";
import { CheckCircleIcon, ExclamationCircleIcon, UserGroupIcon } from "@heroicons/vue/solid";

export default {
  props: ["jobDetails", "searchTerms"],
  components: {
    AddOrRemoveHashTagsModal,
    UseAsTemplateModal,
    HashtagAddOrEdit,
    CheckCircleIcon,
    ExclamationCircleIcon,
    UserGroupIcon,
  },
  computed: {
    ...mapGetters(["levelsHexColors"]),
  },
  methods: {
    onHashtagClick() {
      this.onModal = "AddOrRemoveHashtags";
    },
    openJobDetails() {
      this.$store.dispatch("setCompany", {
        newCompanyName: this.jobDetails.company_name,
        newCompanyId: this.jobDetails.company_id,
      });

      this.$store.dispatch("setJob", {
        newJobId: this.jobDetails.job_id,
        newJobName: this.jobDetails.job_name,
      });

      this.$router.push(
        `/dashboardJob/${this.jobDetails.company_id}/${this.jobDetails.job_id}`
      );
    },
    onModalClose() {
      // this.$router.go();
      this.onModal = "";
    },
    onOpenUseAsTemplate() {
      this.onModal = "useAsTemplateModal";
    },
    onHashtagSave() {
      this.onModal = "";
    },
    highlightSearchText(word) {
      // Remove special characters '()', '/', and '-' that have non-space characters before or after them,
      // then convert the resulting word to lowercase and trim any leading/trailing whitespace.
      const formattedWord = word
        .replaceAll(/(?<=[^\s])[()/-]|[()/-](?=[^\s])/g, "")
        .toLowerCase()
        .trim();

      // Check if any element in the searchTerms array includes the formatted search word
      const containsSearchTerm = this.searchTerms.some((item) =>
        item.includes(formattedWord)
      );
      // Return the appropriate CSS class for highlighting based on the result
      return containsSearchTerm ? "bg-amber-300" : null;
    },
  },
  data() {
    return {
      onModal: "",
    };
  },
};
</script>
