<template>
  <div class="flex flex-row rounded-md mb-2 p-2 w-1/2 flex items-center">
    <div class="w-96 rounded-md wave h-5"></div>
    <div class="w-5 rounded-md wave h-5 ml-5"></div>
  </div>
</template>

<style scoped>
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #fffefe 8%, #c7c6c6 18%, #eeeeee 33%);
  background-size: 800px 104px;
}
@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
