<template>
  <skeleton-input-form v-if="isLoading"></skeleton-input-form>

  <div v-else class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{ formInfo.name }}
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          {{ formInfo.description }}
        </p>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form action="#" method="PUT">
          <div class="grid grid-cols-6 gap-6">
            <div
              class="col-span-6 sm:col-span-3"
              v-for="data in inputData"
              v-bind:key="data.id"
            >
              <project-code-selector
                :data="data"
                v-if="data.id === 'project_code'"
                :status="inputData[1].value"
                @change="setProjectName"
              />
              <status-selector
                :data="data"
                v-else-if="data.id === 'project_status'"
                @change="setProjectStatus"
              />
              <assign-recruiter
                :data="data"
                v-else-if="contactInputList.includes(data.id)"
                @change="setContact"
              />
              <div v-else>
                <label
                  :for="data.id"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{
                    $t(`${this.formInfo.translationKey}.${data.label}`)
                  }}</label
                >
                <input
                  :type="data.type"
                  :name="data.id"
                  :id="data.id"
                  v-model="data.value"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import ProjectCodeSelector from "./ProjectCodeSelector.vue";
import StatusSelector from "./StatusSelector.vue";
import SkeletonInputForm from "./SkeletonInputForm.vue";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../helperFunctions/DataCaching";
import AssignRecruiter from "./AssignRecruiter.vue";

export default {
  props: ["formInfo"],
  components: {
    ProjectCodeSelector,
    SkeletonInputForm,
    StatusSelector,
    AssignRecruiter,
  },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),

    contactInputList() {
      return [
        "professional_contact_person",
        "account_manager",
        "project_lead",
        "recruiting_specialist",
        "first_sourcing_specialist",
        "second_sourcing_specialist",
      ];
    },
  },
  data() {
    return {
      inputData: [],
      selectedProject: "",
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(["setFieldValues", "setApiToRun"]),
    setProjectName(val) {
      this.selectedProject = val;
    },
    setProjectStatus(value) {
      let index = this.inputData.findIndex(
        (val) => val.id === "project_status"
      );
      this.inputData[index] = { ...this.inputData[index], value };
    },
    setContact({ value, details }) {
      let index = this.inputData.findIndex((val) => val.id === details.id);
      this.inputData[index] = { ...this.inputData[index], value: value.name };
    },
    fetchDataFromBackend() {
      const headers = this.authorizationHeader;
      this.isLoading = true;
      axios
        .get(
          `${this.backendUrl}${this.formInfo.url}?jobId=${this.$route.params.jobId}`,
          { headers }
        )
        .then((response) => {
          storeJobDataInCache(
            this.$route.params.jobId,
            response.data,
            this.formInfo.storeKeyName.replaceAll(" ", "")
          );
          this.postProcessOfFetchedData(response.data);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    postProcessOfFetchedData(data) {
      this.setFieldValues({
        name: this.formInfo.storeKeyName.replaceAll(" ", ""),
        values: Object.values(data),
      });
      this.inputData = Object.values(data);
      this.isLoading = false;
    },
    submitData() {
      let body = { job_id: this.$route.params.jobId };
      this.inputData.forEach((ele) => (body[ele.id] = ele.value));
      const headers = this.authorizationHeader;
      this.isLoading = true;
      axios
        .put(
          `${this.backendUrl}${this.formInfo.url}?jobId=${this.$route.params.jobId}`,
          body,
          { headers }
        )
        .then(() => {
          this.updateProjectCode();
          this.isLoading = false;
        })
        .catch((error) => console.log(error));
    },
    updateProjectCode() {
      let body = {
        job_id: this.$route.params.jobId,
        project_code: this.selectedProject,
      };
      const headers = this.authorizationHeader;
      axios
        .put(
          `${this.backendUrl}/job/timetracking-mapping?jobId=${this.$route.params.jobId}`,
          body,
          { headers }
        )
        .then(() => {
          this.setApiToRun(this.formInfo.url);
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    // fetch data from cache of current job id -
    // if exist then do not attempt API hit and get all data from cache else get data from backend
    if (
      jobDataInCache(
        this.$route.params.jobId,
        this.formInfo.storeKeyName.replaceAll(" ", "")
      )
    ) {
      this.postProcessOfFetchedData(
        jobDataInCache(
          this.$route.params.jobId,
          this.formInfo.storeKeyName.replaceAll(" ", "")
        )
      );
    } else {
      this.fetchDataFromBackend();
    }
  },
};
</script>
