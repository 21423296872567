import axios from "axios";
import {
  removeGeneralDataInCache,
  storeGeneralDataInCache,
} from "../../../components/helperFunctions/DataCaching";
export default {
  setActualUserDetails(context, payload) {
    context.commit("setActualUserDetails", payload);
  },
  setUserInfo(context, payload) {
    context.commit("setUserInfo", payload);
  },
  async login(context, payload) {
    return context.dispatch("auth", { ...payload, mode: "login" });
  },
  async signup(context, payload) {
    return context.dispatch("auth", { ...payload, mode: "signup" });
  },
  async auth(context, payload) {
    //const mode = payload.mode;
    //TODO set url for login or signup
    let url = process.env.VUE_APP_BACKEND_URL + "/login";

    let urlencoded = new URLSearchParams();
    urlencoded.append("mail", payload.email);
    urlencoded.append("password", payload.password);

    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
      //TODO find out if can be deleted redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to authenticate. Check your login data."
      );
      throw error;
    }

    localStorage.setItem("accessToken", responseData.access_token);
    localStorage.setItem("refreshToken", responseData.refresh_token);

    context.commit("setToken", {
      accessToken: responseData.access_token,
      refreshToken: responseData.refresh_token,
    });
    context.dispatch("getUserInfo");
  },
  tryLogin(context) {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const user = JSON.parse(localStorage.getItem("user"));

    if (accessToken && refreshToken && user) {
      context.commit("setToken", {
        accessToken,
        refreshToken,
      });
      context.commit("setUserInfo", {
        mail: user.mail,
        name: user.name,
        isAdmin: user.isAdmin,
        roleName: user.roleName,
      });
    }
  },
  setPresentationMode(context, payload) {
    context.commit("setPresentationMode", payload);
  },
  logout(context) {
    localStorage.clear();

    context.commit("setToken", {
      accessToken: null,
      refreshToken: null,
    });
    context.commit("setUserInfo", {
      mail: null,
      name: null,
      isAdmin: false,
      roleName: null,
    });
  },
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
  },
  getAllUserList(context) {
    return new Promise((resolve, reject) => {
      const headers = context.getters.authorizationHeader;
      axios
        .get(`${context.getters.backendUrl}/auth/users`, {
          headers,
        })
        .then((response) => {
          let responseData = response.data;
          responseData.forEach((obj) => delete obj.password);
          let internalUsers = responseData.filter(user => !user.roles.some(role => role.name === "ROLE_CLIENT"));
          context.commit("setUserList", [...responseData]);
          removeGeneralDataInCache("userList");
          storeGeneralDataInCache("userList", responseData);
          removeGeneralDataInCache("internalUserList");
          storeGeneralDataInCache("internalUserList", internalUsers);
          resolve(responseData);
        })
        .catch((error) => reject(error));
    });
  },
  tokenRefresh(context) {
    return new Promise((resolve, reject) => {
      const headers = context.getters.refreshAuthorizationHeader;
      axios
        .get(`${context.getters.backendUrl}/auth/token/refresh`, {
          headers,
        })
        .then((response) => {
          let responseData = response.data;
          // console.log("access", response.data);
          localStorage.setItem("accessToken", responseData.access_token);
          localStorage.setItem("refreshToken", responseData.refresh_token);
          context.commit("setToken", {
            accessToken: responseData.access_token,
            refreshToken: responseData.refresh_token,
          });

          resolve(responseData.access_token);
        })
        .catch((error) => reject(error));
    });
  },
  async getUserInfo(context) {
    try {
      const headers = context.getters.authorizationHeader;
      let response = await axios.get(
        `${context.getters.backendUrl}/auth/user/me`,
        {
          headers,
        }
      );

      let responseUserData = response.data;

      localStorage.setItem("user", JSON.stringify(responseUserData));
      await context.commit("setUserInfo", {
        mail: responseUserData.mail,
        name: responseUserData.name,
        isAdmin: responseUserData.isAdmin,
        roleName: responseUserData.roleName,
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  setNoShowAtsConnector(context, payload) {
    context.commit("setNoShowAtsConnector", payload);
  },
};
