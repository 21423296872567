import { createApp } from "vue";

import App from "./App.vue";

import "./assets/styles/index.css";
import store from "./store/index.js";
import router from "./router.js";
import axios from "axios";
import i18n from "./i18n";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import "./main.css";

const app = createApp(App).use(i18n);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //TODO change to 401
    if (error.response.status !== 403) {
      return new Promise((_, reject) => {
        reject(error);
      });
    }

    // do not try again if 403 is a valid response
    let pathUrl =
      "/" + error.config.url.split("?")[0].split("/").slice(2).join("/"); // e.g. '/auth/user/verify'
    const urlsToIgnore = ["/auth/user/verify"];
    if (urlsToIgnore.includes(pathUrl))
      return new Promise((_, reject) => {
        reject(error);
      });

    //TODO Remove hardcoded URL
    //Logout user if token refresh didnt work
    if (error.config.url.replace(/^.*\/\/[^/]+/, "") == "/auth/token/refresh") {
      store.dispatch("logout");
      router.replace("/login");
      return new Promise((_, reject) => {
        reject(error);
      });
    }

    // Try request again with new token
    return store
      .dispatch("tokenRefresh")
      .then((token) => {
        const config = error.config;
        config.headers["Authorization"] = `Bearer ${token}`;

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }
);

app.use(router);

app.use(store);

app.use(Donut);

document.title = "Dashr";

app.mount("#app");
