<template>
  <div class="flex flex-col w-full mt-5 rounded-lg shadow mr-2">
    <div
      class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg h-full"
    >
      <div class="bg-white w-full p-4 h-full">
        <div class="h-7 w-3/5 px-5 pt-6 mb-6 rounded-lg wave"></div>
        <div v-for="(num, index) in 5" :key="num" class="flex flex-col my-3">
          <div
            class="h-4 rounded-lg wave"
            :class="index % 2 !== 0 ? 'w-3/6' : 'w-4/6'"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
