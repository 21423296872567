import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
import { ROLE_ADMIN } from "../../../constant/Constants";

export default {
  state() {
    return {
      controls: {
        role: {
          value: ROLE_ADMIN,
          validationRules: [],
          errorMessage: "",
        },
        "first-name": {
          value: "",
          validationRules: ["required"],
          errorMessage: "",
        },
        "last-name": {
          value: "",
          validationRules: ["required"],
          errorMessage: "",
        },
        "email-address": {
          value: "",
          validationRules: ["email"],
          errorMessage: "",
        },
      },
      allCompaniesList: [], // list of all companies
      isFormValidated: true,
      selectedAddEditCompanyName: "", // selected company name to add or edit
      selectedAddEditCompanyId: 0, //selected company od to add or edit
      selectedEditJobsList: [], // list of all jobs for the selected company
      isModal: "",
      assignedCompaniesList: [], // all companies and jobs that are assigned to the user
      roles: [], // list of possible roles than can be assigned
      userList: [],
    };
  },
  mutations,
  actions,
  getters,
};
