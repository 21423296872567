<template>
  <div
    v-if="showSavedModal"
    class="w-2/6 h-2/6 z-30 bg-gray-100 absolute absolutePosition shadow rounded-lg flex flex-col justify-center items-center text-2xl"
  >
    <p>{{ $t("dashboard.save-button-confirmation") }}</p>
    <CheckCircleIcon class="w-20 h-20 text-green-400 mt-6" />
    <button
      type="button"
      class="inline-block px-6 py-2.5 bg-gray-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out self-end mt-5 mr-5"
      @click="onModalClose"
      data-bs-dismiss="modal"
    >
      Close
    </button>
  </div>
</template>
<script>
import { CheckCircleIcon } from "@heroicons/vue/outline";
export default {
  components: {
    CheckCircleIcon,
  },
  computed: {
    showSavedModal() {
      return this.$store.getters.modalSubmitData;
    },
  },
  watch: {
    showSavedModal() {
      if (this.showSavedModal == true) {
        setTimeout(
          () => this.$store.dispatch("setModalSubmitData", false),
          1500
        );
      }
    },
  },
  methods: {
    onModalClose() {
      this.$store.dispatch("setToggleLoading", false);
    },
  },
};
</script>
<style scoped>
.absolutePosition {
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
