<template>
  <skeleton-weekly-gauge-widget v-if="loading" />

  <div
    v-else
    class="h-72 bg-white pl-5 pr-3 pt-2 flex flex-col justify-around pb-4 mt-5 rounded-3xl shadow-2xl"
  >
    <p class="text-black text-xl">
      {{ $t(`dashboardWeaklyGauge.${responseData["widget_name"][0]}`) }}
    </p>
    <Line
      :chart-options="chartOptions"
      :chart-data="chartData"
      :styles="styles"
    />
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from "chart.js";
import axios from "axios";
import { mapGetters } from "vuex";
import SkeletonWeeklyGaugeWidget from "./SkeletonWeeklyGaugeWidget.vue";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../helperFunctions/DataCaching";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);

export default {
  props: ["loadingToggle"],
  watch: {
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
  },
  components: { Line, SkeletonWeeklyGaugeWidget },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
    // GaugeOptions() {},
    chartId() {
      return {
        default: "line-chart",
      };
    },
    styles() {
      return {
        position: "relative",
        height: "230px",
      };
    },
    chartOptions() {
      return {
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: true,
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
        plugins: {
          legend: {
            labels: {
              color: "black", // Replace with the desired text color
            },
          },
        },
      };
    },
    chartData() {
      let legendName = this.responseData["Legend_name"];
      let actualContactedCount = this.responseData[
        "actual_new_candidate_counts"
      ].map((value) => {
        return JSON.parse(value);
      });

      let estimatedContactedCount = this.responseData[
        "estimated_new_candidate_counts"
      ].map((value, i) => {
        // remove null values from actual count and then remove initial values of estimated count uptill length of actual values
        // logic to start estiated line after actual line
        let withoutNulls = this.responseData[
          "actual_new_candidate_counts"
        ].filter((val) => val !== "null");

        if (i < withoutNulls.length - 1) return null;
        else if (value < 0) return 0;
        else return JSON.parse(value);
      });

      // logic to replace first and last week name with project start and end date
      let tempLabels = [...this.responseData.weeks];
      tempLabels[0] = this.responseData["dates"][0];
      tempLabels[tempLabels.length - 1] = this.responseData["dates"][1];
      let labels = [...tempLabels, ...this.responseData["extra_weeks"]];
      return {
        labels: [...labels],
        datasets: [
          {
            labels: [...labels],
            label: this.$t(`dashboardWeaklyGauge.${legendName[0]}`),
            backgroundColor: "#a5b4fc",
            borderColor: "#a5b4fc",
            data: [...actualContactedCount],
            tension: 0.3,
            pointBackgroundColor: "white",
            pointBorderColor: "#a5b4fc",
            radius: 5,
          },
          {
            labels: [...labels],
            label: this.$t(`dashboardWeaklyGauge.${legendName[1]}`),
            backgroundColor: "transparent",
            borderColor: "#a5b4fc",
            borderDash: [10, 10],
            data: [...estimatedContactedCount],
            tension: 0.3,
            pointBackgroundColor: "white",
            pointBorderColor: "#a5b4fc",
            radius: 5,
          },
        ],
      };
    },
  },
  methods: {
    fetchDataFromBackend(jobId) {
      this.loading = true;

      const headers = this.authorizationHeader;
      axios
        .get(
          this.backendUrl +
            "/widget/new-candidate-historic-view" +
            "?jobId=" +
            jobId,
          {
            headers,
          }
        )
        .then((response) => {
          storeJobDataInCache(jobId, response.data, "WeeklyGauge");
          this.responseData = { ...response.data };
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "WeeklyGauge")) {
        this.responseData = {
          ...jobDataInCache(this.currentJobId, "WeeklyGauge"),
        };
        this.loading = false;
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
  data() {
    return {
      loading: true,
      responseData: {},
    };
  },
};
</script>
