<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <app-header />
  <div class="mt-7">
    <!-- <new-user-dialog
      @closed="hideNewUserOverlay"
      v-if="isNewUserOverlayDisplayed"
    /> -->
  </div>

  <div class="flex flex-col items-center">
    <div class="tabs">
      <a
        class="tab tab-lg tab-bordered"
        @click="onChangeTab('UsersList')"
        :class="activeTab == 'UsersList' ? 'tab-active' : ''"
        >Users List</a
      >
      <a
        class="tab tab-lg tab-bordered"
        :class="activeTab == 'AdminAPIs' ? 'tab-active' : ''"
        @click="onChangeTab('AdminAPIs')"
        >Admin APIs</a
      >
    </div>
  </div>
  <user-list v-if="activeTab === 'UsersList'" />
  <admin-API v-if="activeTab === 'AdminAPIs'" />
</template>

<script>
import AppHeader from "../../components/layout/header/AppHeader.vue";
import { mapGetters } from "vuex";
import UserList from "./UserList.vue";
import AdminAPI from "./AdminAPI.vue";

export default {
  data() {
    return {
      activeTab: "UsersList",
    };
  },
  methods: {
    onChangeTab(tabName) {
      this.activeTab = tabName;
    },
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl"]),
  },
  mounted() {},
  components: {
    AppHeader,
    UserList,
    AdminAPI,
  },
};
</script>

<style></style>
