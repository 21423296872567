<template>
  <skeleton-conversion-rate-widget v-if="loading" />
  <div v-else class="bg-gray-50 rounded-lg my-5 p-3 ml-3 pl-5 mr-4">
    <div class="flex flex-row justify-between w-8/12">
      <h2 v-if="!isPdfReport" class="text-2xl text-gray-600 pl-5 pt-2 mb-3">
        {{ name }}
      </h2>
      <h2 v-else class="text-2xl text-gray-600 pl-5 pt-2 mb-3">
        {{ $t(`conversionRate.Projektanalyse`) }}
      </h2>
    </div>

    <div class="flex flex-row">
      <div>
        <div class="flex flex-col justify-between mr-4 h-64 w-24">
          <p class="text-sm mt-6"></p>
          <p class="text-sm mt-6">
            {{ $t(`conversionRate.${columnHeaders[0]}`) }}
          </p>
          <p class="text-sm mb-2">
            {{ $t(`conversionRate.${columnHeaders[1]}`) }}
          </p>
        </div>
        <div
          v-if="isPdfReport || screenSize < 1536"
          class="flex flex-col justify-between mr-4 h-72 w-24"
        >
          <p class="text-sm mt-6"></p>
          <p class="text-sm mt-6">
            {{ $t(`conversionRate.${columnHeaders[0]}`) }}
          </p>
          <p class="text-sm mb-2">
            {{ $t(`conversionRate.${columnHeaders[1]}`) }}
          </p>
        </div>
      </div>

      <div :class="cssConversionWidgets">
        <percentage-displayer
          v-for="(data, index) in tableData"
          :rowheaders="rowHeaders[index]"
          :key="data"
          :data="data"
          :isPdfReport="isPdfReport"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../../../components/helperFunctions/DataCaching";

import PercentageDisplayer from "./PercentageDisplayer.vue";
import SkeletonConversionRateWidget from "./SkeletonConversionRateWidget.vue";
export default {
  props: ["loadingToggle", "isPdfReport"],
  components: {
    PercentageDisplayer,
    SkeletonConversionRateWidget,
  },
  data() {
    return {
      name: "",
      columnHeaders: [],
      rowHeaders: [],
      tableData: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
    toggleLoadingSaveButton() {
      return this.$store.getters.toggleLoading;
    },
    cssConversionWidgets() {
      return this.isPdfReport
        ? "grid grid-cols-4 gap-4 "
        : "flex flex-row flex-wrap flex-start";
    },
    screenSize() {
      return screen.width;
    },
  },
  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
    toggleLoadingSaveButton() {
      this.fetchDataFromBackend(this.currentJobId);
    },
  },
  methods: {
    ...mapActions([
      "setIncreasePendingApisCount",
      "setDecreasePendingApisCount",
    ]),

    fetchDataFromBackend(jobId) {
      this.loading = true;
      const headers = this.authorizationHeader;
      this.setIncreasePendingApisCount();
      if (jobId !== null) {
        axios
          .get(this.backendUrl + "/widget/conversions" + "?jobId=" + jobId, {
            headers,
          })
          .then((response) => {
            this.setDecreasePendingApisCount();
            storeJobDataInCache(jobId, response.data, "ConversionRate");
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.name = data.name;
      this.columnHeaders = data.column_headers;
      this.tableData = data.table_data;
      this.rowHeaders = data.row_headers;
      this.loading = false;
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "ConversionRate")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "ConversionRate")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
