// this function will store data in cache with respect to job id and widget name
// the format is {jobId: {widgetName: data}}
function storeJobDataInCache(jobId, dataToSave, widgetName) {
  let tempJobDetailsFromBackend = JSON.parse(
    localStorage.getItem("jobDetailsCacheFromBackend")
  );
  let dataToStoreInCache = JSON.stringify({
    ...tempJobDetailsFromBackend,
    [jobId]: {
      ...(tempJobDetailsFromBackend[jobId] ?? {}),
      [widgetName]: { ...dataToSave },
    },
  });
  localStorage.setItem("jobDetailsCacheFromBackend", dataToStoreInCache);
}

// this functions gets job id and a single key to check if there is already data in cache of that specific API,
// if yes then send the data to caller else send undefined to hit the API of backend
function jobDataInCache(jobId, widgetName) {
  let jobDataInCache = JSON.parse(
    localStorage.getItem("jobDetailsCacheFromBackend") ?? "{}"
  )[jobId];
  return jobDataInCache && jobDataInCache[widgetName]
    ? jobDataInCache[widgetName]
    : undefined;
}

// this function will remove single widget data from the cache to store the new data
function removeWidgetDataFromCache(jobId, widgetName) {
  let tempJobDetailsFromBackend = JSON.parse(
    localStorage.getItem("jobDetailsCacheFromBackend")
  );
  delete tempJobDetailsFromBackend[jobId][widgetName];
  localStorage.setItem(
    "jobDetailsCacheFromBackend",
    JSON.stringify(tempJobDetailsFromBackend)
  );
}

// this function will remove complete job data from the cache to store the new data
function removeCompleteJobDataFromCache(jobId) {
  let tempJobDetailsFromBackend = JSON.parse(
    localStorage.getItem("jobDetailsCacheFromBackend")
  );
  delete tempJobDetailsFromBackend[jobId];
  localStorage.setItem(
    "jobDetailsCacheFromBackend",
    JSON.stringify(tempJobDetailsFromBackend)
  );
}

// this funtion save the company details in cache
// format: [companyId]: {[key]: value...}
function storeCompanyDetailsInCache(companyId, key, value) {
  let tempCompanyDetailsFromBackend = JSON.parse(
    localStorage.getItem("companyDetailsCacheFromBackend")
  );
  tempCompanyDetailsFromBackend[companyId] = {
    ...tempCompanyDetailsFromBackend[companyId],
    [key]: value,
  };
  localStorage.setItem(
    "companyDetailsCacheFromBackend",
    JSON.stringify(tempCompanyDetailsFromBackend)
  );
}

// this functions gets company id and a single key to check if there is already data in cache of that specific API,
// if yes then send the data to caller else send undefined to hit the API of backend
function companyDataInCache(companyId, key) {
  let companyDataInCache = JSON.parse(
    localStorage.getItem("companyDetailsCacheFromBackend")
  )[companyId];
  return companyDataInCache && companyDataInCache[key]
    ? companyDataInCache[key]
    : undefined;
}

// this function will remove complete job data from the cache to store the new data
function removeCompleteCompanyDataFromCache(companyId) {
  let tempCompanyDetailsFromBackend = JSON.parse(
    localStorage.getItem("companyDetailsCacheFromBackend")
  );
  delete tempCompanyDetailsFromBackend[companyId];
  localStorage.setItem(
    "companyDetailsCacheFromBackend",
    JSON.stringify(tempCompanyDetailsFromBackend)
  );
}

function storeGeneralDataInCache(keyName, data) {
  localStorage.setItem([keyName], JSON.stringify(data));
}

function getGeneralDataInCache(keyName) {
  return JSON.parse(localStorage.getItem([keyName]));
}

function removeGeneralDataInCache(keyName) {
  return localStorage.removeItem([keyName]);
}

export {
  storeJobDataInCache,
  jobDataInCache,
  removeWidgetDataFromCache,
  removeCompleteJobDataFromCache,
  storeGeneralDataInCache,
  getGeneralDataInCache,
  storeCompanyDetailsInCache,
  companyDataInCache,
  removeCompleteCompanyDataFromCache,
  removeGeneralDataInCache,
};
