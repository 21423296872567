export default {
  setControls(context, payload) {
    context.commit("setControls", payload);
  },
  setAllCompaniesList(context, payload) {
    context.commit("setAllCompaniesList", payload);
  },
  setIsFormValidated(context, payload) {
    context.commit("setIsFormValidated", payload);
  },
  setSelectedAddEditCompanyName(context, payload) {
    context.commit("setSelectedAddEditCompanyName", payload);
  },
  setSelectedAddEditCompanyId(context, payload) {
    context.commit("setSelectedAddEditCompanyId", payload);
  },
  setSelectedEditJobsList(context, payload) {
    context.commit("setSelectedEditJobsList", payload);
  },
  setIsModal(context, payload) {
    context.commit("setIsModal", payload);
  },
  setAssignedCompaniesList(context, payload) {
    context.commit("setAssignedCompaniesList", payload);
  },
  setRoles(context, payload) {
    context.commit("setRoles", payload);
  },
  setUserList(context, payload) {
    context.commit("setUserList", payload);
  },
  resetState(context, payload) {
    context.commit("resetState", payload);
  },
};
