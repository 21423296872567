<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content w-[50%] 2xl:w-[40%]">
      <div class="m-3">
        <div class="border-b-2 border-gray-400 pb-2">
          <p class="font-bold text-xl">PDF Report Note</p>
        </div>

        <div>
          <textarea
            class="textarea textarea-ghost border-black w-full mt-8 h-[60vh]"
            v-model="note"
            placeholder="Reporting Notes"
          ></textarea>
        </div>

        <div class="flex flex-1 flex-row w-full justify-end items-end mt-4">
          <modal-footer-button
            @onModalClose="$emit('onModalClose')"
            @onSubmit="onSubmit"
            buttonText="Submit"
            :loader="loader"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalFooterButton from "../../ui/ModalFooterButton.vue";

export default {
  props: ["details", "isForJob", "loader"],
  components: {
    ModalFooterButton,
  },
  data() {
    return {
      jobOrCompanyDetails: {},
      isAddNew: false,
      note: "",
    };
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$emit("onSubmit", this.note);
    },
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: white;
  margin: 4% auto; /*  from the top and centered */
  padding: 5px;
  border: 1px solid #888;
  border-radius: 10px;
}
</style>
