<template>
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <div>
        <p class="text-2xl font-semibold">
          {{ $t("add-new-job.edit-job") }}
        </p>
      </div>
      <div class="mt-4">
        <form class="w-full max-w-lg" @submit.prevent="onSubmit">
          <div class="flex flex-wrap -mx-3 mb-0">
            <!-- looping to just avoid repeating same code  -->
            <div
              v-for="singleField in inputFields"
              :key="singleField.name"
              class="w-full md:w-1/2 px-3 mb-6 md:mb-6"
            >
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                {{ $t(`add-new-job.${singleField.text}`) }}
                {{ singleField.isMandatory && "*" }}
              </label>
              <input
                autocomplete="off"
                class="custom-input-style"
                :class="validationBorderCss(singleField)"
                id="grid-first-name"
                :type="singleField.type"
                v-model="$data[singleField.name]"
                @input="onValueChange($event, singleField.name)"
              />
            </div>
          </div>

          <div class="flex flex-row">
            <div class="flex flex-1 flex-row w-full justify-end items-end">
              <button
                class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
                @click="$emit('onModalClose')"
                type="button"
              >
                <span>Cancel</span>
              </button>
              <button
                v-if="this.contactDetail.poc_id"
                class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
                @click="onDelete()"
                type="button"
              >
                <span>Delete</span>
              </button>
              <button
                class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center shadow-md primary-button"
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  props: ["contactDetail"],
  emits: ["onModalClose"],
  data() {
    return {
      firstName: this.contactDetail.first_name ?? "",
      lastName: this.contactDetail.last_name ?? "",
      pronoun: this.contactDetail.pronoun ?? "",
      position: this.contactDetail.position ?? "",
      email: this.contactDetail.email ?? "",
      fon: this.contactDetail.fon ?? "",
      isValidationFail: false,
    };
  },
  computed: {
    ...mapGetters(["backendUrl", "authorizationHeader"]),
    // function to short html code instead of writing same field div multiple times loop them with this array
    inputFields() {
      return [
        {
          text: "company-first-name",
          name: "firstName",
          type: "text",
          isMandatory: true,
        },
        {
          text: "company-last-name",
          name: "lastName",
          type: "text",
          isMandatory: true,
        },
        { text: "company-pronoun", name: "pronoun", type: "text" },
        { text: "company-position", name: "position", type: "text" },
        {
          text: "company-email",
          name: "email",
          type: "email",
          isMandatory: true,
        },
        { text: "company-phone", name: "fon", type: "text" },
      ];
    },
    isValidated() {
      if ([this.firstName, this.lastName, this.email].includes("")) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    validationBorderCss(values) {
      if (
        values.isMandatory &&
        this.$data[values.name] == "" &&
        this.isValidationFail
      )
        return "border-2 border-red-700";
      else return "border";
    },
    onSkip() {
      this.$emit("onModalClose");
    },
    onDelete() {
      let headers = this.authorizationHeader;

      let data = {
        job_id: this.contactDetail.job_id,
        poc_id: this.contactDetail.poc_id,
      };

      axios
        .delete(this.backendUrl + "/contact/delete-contact", { headers, data })
        .then(() => {
          this.$emit("onModalClose");
        })
        .catch((error) => console.log(error));
    },
    onSubmit() {
      if (this.isValidated) {
        // call an api endpoint to submit complete data
        let headers = this.authorizationHeader;
        let url = `${this.backendUrl}${
          this.contactDetail.poc_id
            ? "/contact/modify-contact"
            : "/contact/new-contact"
        }`;
        let method = this.contactDetail.poc_id ? "PUT" : "POST";
        let jobData = {
          first_name: this.firstName,
          last_name: this.lastName,
          pronoun: this.pronoun,
          position: this.position,
          email: this.email,
          fon: this.fon,
          job_id: this.contactDetail.job_id,
          poc_id: this.contactDetail.poc_id,
          company_id: 0,
          card_level: 2,
        };
        console.log(jobData);
        axios({
          method,
          url,
          headers: headers,
          data: jobData,
        })
          .then(() => {
            this.$emit("onModalClose");
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else {
        this.isValidationFail = true;
      }
    },
    onValueChange(value, name) {
      this[name] = value.target.value;
    },
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
