<template>
  <div class="mt-4" v-if="false">
    <form class="w-full max-w-lg" @submit.prevent="onSubmit">
      <div class="flex flex-wrap -mx-3 mb-0">
        <!-- looping to just avoid repeating same code  -->
        <div
          v-for="singleField in inputFields"
          :key="singleField.name"
          class="w-full md:w-1/2 px-3 mb-6 md:mb-6"
        >
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            {{ $t(`add-new-job.${singleField.text}`) }}
          </label>
          <input
            autocomplete="off"
            class="custom-input-style"
            id="grid-first-name"
            type="number"
            @input="onValueChange($event, singleField.name)"
            v-model="$data[singleField.name]"
          />
        </div>
      </div>

      <div class="flex flex-row justify-end items-end">
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
          @click="$emit('onModalClose')"
          type="button"
        >
          <span>Cancel</span>
        </button>
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center shadow-md"
        >
          <span>Next</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: ["jobId"],
  data() {
    return {
      researchQuality: "95",
      feedbackRate: "18",
      positiveRate: "23",
      telephoneInterview: "70",
      successRate: "50",
      successRateIntroduction: "66",
      successRateInterview: "40",
      successRateContract: "80",
    };
  },
  mounted() {
    // not so dirty fix
    // just hit the end point when this component is rendered and hide step from front end
    this.onSubmit();
  },
  computed: {
    ...mapGetters(["selectedCompanyId", "authorizationHeader", "backendUrl"]),
    // function to short html code instead of writing same field div multiple times loop them with this array
    inputFields() {
      return [
        { text: "job-research-quality", name: "researchQuality" },
        { text: "job-feedback-rate", name: "feedbackRate" },
        { text: "job-which-positive-rate", name: "positiveRate" },
        { text: "job-telephone-interview", name: "telephoneInterview" },
        { text: "job-success-rate", name: "successRate" },
        {
          text: "job-success-rate-introduction",
          name: "successRateIntroduction",
        },
        { text: "job-success-rate-interview", name: "successRateInterview" },
        { text: "job-success-rate-contract", name: "successRateContract" },
      ];
    },
  },
  methods: {
    onSubmit() {
      // call an api endpoint to submit complete data
      let data = {
        job_id: this.jobId,
        reply_rate: this.feedbackRate,
        research_quality: this.researchQuality,
        share_of_positive_replies: this.positiveRate,
        success_rate_contract_negotiation: this.successRateContract,
        success_rate_interview_at_client: this.successRateInterview,
        success_rate_phone_interview: this.successRate,
        success_rate_presentation_to_client: this.successRateIntroduction,
        transfer_to_phone_interview: this.telephoneInterview,
        estimated_applicants: 0,
      };
      let headers = this.authorizationHeader;

      axios
        .put(this.backendUrl + `/job/estimated-stage-conversion-rates`, data, {
          headers,
        })
        .then(() => {
          this.$emit("onNextStep");
        });
    },
    onValueChange(value, name) {
      this[name] = value.target.value;
    },
  },
};
</script>
