<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content w-[50%] 2xl:w-[40%]">
      <div class="m-3">
        <div class="border-b-2 border-gray-400 pb-2">
          <p class="font-bold text-xl">All Tags</p>
        </div>

        <div class="mt-5">
          <!-- ================================================ Select From List section  =================================================== -->
          <div class="grid grid-cols-3 gap-3" v-if="isAddNewOrEdit == ''">
            <div class="col-span-2 mt-5">
              <div class="tag-input-container">
                <input
                  type="text"
                  v-model="tagName"
                  @input="onFilterSuggestions"
                  placeholder="Edit Tags"
                  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ul class="dropdown" v-if="filteredSuggestions.length > 0">
                  <li
                    v-for="suggestion in filteredSuggestions"
                    :key="suggestion"
                    @click="addTagFromDropdown(suggestion)"
                  >
                    {{ suggestion.name }}
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <button
                class="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center justify-center shadow-md mt-5 w-full"
                @click="onAddNewTag()"
              >
                <p class="text-center text-gray-700">
                  +{{ $t("job-clustering.add-new-tag") }}
                </p>
              </button>
            </div>
          </div>
          <!-- ======================================================================================================== -->

          <!-- ==================================== Add or Edit tag section ==================================================  -->
          <div v-else>
            <all-tags-add-or-edit-section
              :isAddNewOrEdit="isAddNewOrEdit"
              :tagName="tagName"
              :levelCategory="category"
              @onCancelAddOrEdit="onCancelAddOrEdit"
            />
          </div>
        </div>
        <!-- ================================================================================================================== -->

        <div class="flex flex-1 justify-end my-4 border-t-2 border-gray-400">
          <button
            class="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-md mt-2"
            @click="$emit('onModalClose')"
          >
            <p class="text-black">Close</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AllTagsAddOrEditSection from "./AllTagsAddOrEditSection.vue";

export default {
  props: ["job"],
  components: {
    AllTagsAddOrEditSection,
  },
  data() {
    return {
      tagName: "",
      category: "Default",
      filteredSuggestions: [],
      isAddNewOrEdit: "",
    };
  },
  computed: {
    ...mapGetters([
      "allJobList",
      "authorizationHeader",
      "backendUrl",
      "allTags", // [{category: <>, name: <>}]
    ]),
  },
  mounted() {
    this.onFilterSuggestions();
  },
  methods: {
    ...mapActions(["jobHashtagsChange", "addHashtag"]),

    onFilterSuggestions() {
      /**
       * Filter data array to generate suggestions for autocomplete or dropdown.
       * get all those data which contain input data as substring, as well as remove those data which are already selected
       */
      this.filteredSuggestions = this.allTags.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(this.tagName.toLowerCase())
      );
    },
    addTagFromDropdown(hashtag) {
      this.tagName = hashtag.name;
      this.category = hashtag.category;
      this.isAddNewOrEdit = "edit";
      this.onFilterSuggestions();
    },
    onAddNewTag() {
      this.tagName = "";
      this.category = "Default";
      this.isAddNewOrEdit = "add";
    },
    onCancelAddOrEdit() {
      this.tagName = "";
      this.category = "Default";
      this.isAddNewOrEdit = "";
      this.onFilterSuggestions();
    },
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: white;
  margin: 4% auto; /*  from the top and centered */
  padding: 5px;
  border: 1px solid #888;
  border-radius: 10px;
}

.tag-input-container {
  display: inline-block;
  position: relative;
}

input[type="text"] {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 300px;
}

/* Styles for the dropdown container */
.dropdown {
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease;

  /* Hide the default scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* WebKit (Chrome, Safari) custom scrollbar styles */
.dropdown::-webkit-scrollbar {
  width: 8px;
}

.dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dropdown::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Styles for the dropdown items */
.dropdown li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Hover effect for dropdown items */
.dropdown li:hover {
  background-color: #f2f2f2;
}
</style>
