<template>
  <div class="custom-alert-overlay" v-show="visible">
    <div class="custom-alert">
      <div class="custom-alert-message">
        {{ message }}
      </div>
      <div class="custom-alert-buttons">
        <button class="custom-alert-ok-button" @click="okClicked">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      visible: false,
    };
  },
  methods: {
    show(message) {
      this.message = message;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    okClicked() {
      this.hide();
    },
    cancelClicked() {
      this.hide();
    },
  },
};
</script>

<style scoped>
.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.2s ease-out;
}

.custom-alert {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 24px 36px;
  animation: slideDown 0.4s ease-out;
}

.custom-alert-message {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 24px;
}

.custom-alert-buttons {
  display: flex;
  justify-content: center;
}

.custom-alert-ok-button,
.custom-alert-cancel-button {
  font-size: 16px;
  font-weight: 500;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
}

.custom-alert-ok-button {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  color: black;
  margin-right: 16px;
}

.custom-alert-ok-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.custom-alert-cancel-button {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  color: black;
}

.custom-alert-cancel-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

@keyframes slideDown {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
