<template>
  <div
    class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 h-[100vh] lg:px-8 bg-gray-50"
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <img
          class="mx-auto h-12 w-auto"
          src="@/assets/logo_transparent.png"
          alt="Kooku Logo"
        />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {{ $t("signup-page.header-message") }}
        </h2>
        <!-- <p class="text-center mt-4">
          {{ $t("signup-page.account-check-message") }}
        </p> -->
        <p v-if="errorFormFillText" class="text-center text-red-600 mt-3">
          {{ $t("signup-page.invalid-general-message") }}
        </p>
      </div>
      <div class="bg-white shadow-md rounded px-8 pt-2 pb-8 mb-4 mt-1">
        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="password"
          >
            {{ $t("signup-page.password") }}
          </label>

          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            v-model="password"
            placeholder="******************"
            :style="errorBorderColor(invalidPassword)"
          />

          <p v-if="invalidPassword" class="text-red-500 text-xs italic">
            {{ $t("signup-page.password-error-message") }}
          </p>
        </div>

        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="passwordRep"
          >
            {{ $t("signup-page.repeat-password") }}
          </label>

          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="passwordRep"
            type="password"
            v-model="passwordRepeated"
            placeholder="******************"
            :style="errorBorderColor(invalidTwoPassword)"
          />

          <p v-if="invalidTwoPassword" class="text-red-500 text-xs italic">
            {{ $t("signup-page.repeat-password-error-message") }}
          </p>
        </div>

        <div>
          <button
            @click="signup"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            {{ $t("login-page.submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      password: "",
      passwordRepeated: "",
      validForm: true,
      errorFormFillText: false,
      invalidPassword: false,
      invalidTwoPassword: false,
    };
  },
  computed: {
    ...mapGetters(["backendUrl"]),
  },
  methods: {
    errorBorderColor(invalidField) {
      return { borderColor: invalidField ? "red" : "black" };
    },
    signup(e) {
      e.preventDefault();

      const signUpData = {
        password: this.password,
      };

      this.validForm = true;
      if (this.password.length < 3) {
        this.invalidPassword = true;
        this.validForm = false;
      } else {
        this.invalidPassword = false;
      }
      if (this.password !== this.passwordRepeated) {
        this.invalidTwoPassword = true;
        this.validForm = false;
      } else {
        this.invalidTwoPassword = false;
      }

      if (this.validForm) {
        const queryString = window.location.search;
        const contextUrlParams = new URLSearchParams(queryString);
        const code = contextUrlParams.get("code");

        let urlParams = new URLSearchParams();
        urlParams.append("code", code);

        axios
          .post(
            this.backendUrl + "/auth/user/verify?" + urlParams.toString(),
            signUpData
          )
          .then(() => {
            this.$router.push("/login");
          })
          .catch((error) => {
            console.log(error);
            alert(
              "No code hase been provided or it has expired, was already used or is not valid. Please contact an administrator."
            );
            this.errorMessage = error.message;
          });
      }
    },
  },
};
</script>
