<template>
  <router-view />
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    document.addEventListener("mouseup", () => {
      // NOTE: below code have indirect effect on set password route

      // if the cookie time already stored in session storage than and only than run the logic
      if (localStorage.getItem("cookieTime")) {
        let halfHour = 60 * 60 * 1000;
        let sessionDate = new Date(
          JSON.parse(localStorage.getItem("cookieTime"))
        );
        // if session storage date and time is greater than an hour than logout the user
        if (new Date() - sessionDate > halfHour) {
          this.$store.dispatch("logout");
          this.$router.replace("/login");
        } else localStorage.setItem("cookieTime", JSON.stringify(new Date()));
      } else {
        // if user  logout from one tab than every other tab should also logged out the user (only run below code when route is not in login or in set password)
        if (
          !localStorage.getItem("user") &&
          !["Login", "SetPassword", "SignUp"].includes(this.$route.name)
        ) {
          this.$store.dispatch("logout");
          this.$router.replace("/login");
        }
      }
    });

    const script = document.createElement("script");
    script.src = "//js.hs-scripts.com/9044894.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "your-portal-id",
          formId: "your-form-id",
          target: "#hubspotForm",
        });
      }
    });
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
  },
  created() {
    this.resetPendingApiCount(0);
    this.$store.dispatch("tryLogin");
    //Read the status information in sessionStorage when the page is loaded
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }

    //Save the information in vuex to sessionStorage when the page is refreshed
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },

  watch: {
    $route() {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    },

    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace("/login");
      }
    },
  },
  methods: {
    ...mapActions(["resetPendingApiCount", "setUserList"]),
  },
};
</script>
