<template>
  <div class="flex flex-col mx-3 relative mt-4 shadow rounded-lg p-2">
    <p class="text-xs w-32 h-12 text-center">
      {{ $t(`conversionRate.${rowheaders.replaceAll(".", "")}`) }}
    </p>
    <div>
      <actual-conversion-rate
        class="h-20"
        :valuePerc="arrayWithPerc[0]"
        :expectedRate="arrayWithPerc[1]"
        :isPdfReport="isPdfReport"
      />
    </div>

    <div class="flex flex-col my-3">
      <div class="flex flex-row items-center">
        <div class="bg-green-400 h-2 w-2 rounded-full mr-1"></div>
        <p class="labelText text-gray-400">
          {{ $t("dashboard.conversionrate-successful") }}
        </p>
      </div>
      <div class="flex flex-row items-center">
        <div class="bg-yellow-300 h-2 w-2 rounded-full mr-1"></div>
        <p class="labelText text-gray-400">
          {{ $t("dashboard.conversionrate-ok") }}
        </p>
      </div>
      <div class="flex flex-row items-center">
        <div class="bg-red-400 h-2 w-2 rounded-full mr-1"></div>
        <p class="labelText text-gray-400">
          {{ $t("dashboard.conversionrate-unrealized") }}
        </p>
      </div>
    </div>
    <div class="text-center">
      <p class="text-3xl text-center text-gray-400">{{ arrayWithPerc[1] }}%</p>
    </div>
  </div>
</template>
<script>
import ActualConversionRate from "./ActualConversionWidget.vue";
export default {
  props: ["data", "rowheaders", "isPdfReport"],
  components: {
    ActualConversionRate,
  },
  data() {
    return {
      arrayWithPerc: [],
      show: false,
    };
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    removePercent() {
      let newData = this.data;
      this.arrayWithPerc = newData.map((d) => {
        return Number(d);
      });
    },
  },
  mounted() {
    this.removePercent();
  },
};
</script>
<style scoped>
.labelText {
  font-size: 8px;
}
</style>
