<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <div>
        <p class="text-lg font-semibold text-center">
          {{ selectedAddEditCompanyName }}
        </p>
      </div>

      <div class="divider"></div>

      <div>
        <div class="flex flex-row items-center" key="selectAll">
          <label class="label cursor-pointer">
            <input
              type="checkbox"
              name="Select All"
              id="Select All"
              class="checkbox-sm checkbox"
              @change="onSelectAll($event.target.checked)"
            />
          </label>
          <span class="label-text ml-2">
            {{ $t("admin-page.select-all") }}</span
          >
        </div>
        <div
          class="flex flex-row items-center"
          v-for="job in jobList"
          :key="job.name"
        >
          <label class="label cursor-pointer">
            <input
              type="checkbox"
              :name="job.name"
              :id="job.name"
              class="checkbox-sm checkbox"
              v-model="job.checked"
            />
          </label>
          <span class="label-text ml-2">{{ job.name }}</span>
        </div>
      </div>

      <div class="flex flex-1 justify-end mt-7">
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center w-44 justify-center shadow-md"
          @click="onSave"
        >
          Save
        </button>
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center w-44 justify-center ml-5 shadow-md"
          @click="onRemoveCompany()"
        >
          Remove
        </button>
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center w-44 justify-center ml-5 shadow-md"
          @click="onCloseModal()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      jobList: [],
    };
  },
  mounted() {
    this.getCompayJobs();
  },
  computed: {
    ...mapGetters([
      "controls",
      "authorizationHeader",
      "backendUrl",
      "selectedAddEditCompanyName",
      "selectedAddEditCompanyId",
      "selectedEditJobsList",
      "assignedCompaniesList",
      "isRoleClient",
    ]),
  },
  methods: {
    ...mapActions([
      "setSelectedAddEditCompanyName",
      "setSelectedAddEditCompanyId",
      "setSelectedEditJobsList",
      "setIsModal",
      "setAssignedCompaniesList",
    ]),
    getCompayJobs() {
      const headers = this.authorizationHeader;
      axios
        .get(
          this.backendUrl +
            `/company/jobs?companyId=${this.selectedAddEditCompanyId}`,
          {
            headers,
          }
        )
        .then((response) => {
          let responseJobs = response.data;
          responseJobs = responseJobs.map((job) => ({
            ...job,
            checked:
              this.selectedEditJobsList.filter((value) => {
                return job.id === `${value.id}`;
              }).length > 0,
          })); // logic to add checked key value inside all jobs object

          this.jobList = [...responseJobs];
        })
        .catch((error) => console.log(error));
    },

    onSelectAll(isChecked) {
      let tempJobList = [...this.jobList];
      tempJobList.map((singleJob) => {
        singleJob.checked = isChecked;
      });
      this.jobList = [...tempJobList];
    },

    onSave() {
      let selectedJobs = this.jobList.filter((job) => {
        return job.checked;
      }); // filter  all the values having checked field true
      if (selectedJobs.length > 0) {
        let assignedCompaniesList = this.assignedCompaniesList.filter(
          (value) => {
            return value["company_name"] !== this.selectedAddEditCompanyName;
          }
        ); // to avoid multiple value of same job

        let newValue = {
          jobs: [...selectedJobs],
          ["company_name"]: this.selectedAddEditCompanyName,
          ["company_id"]: this.selectedAddEditCompanyId,
        };

        let assignedCompaniesListAsPerRole = this.isRoleClient
          ? [newValue]
          : [...assignedCompaniesList, newValue];

        this.setAssignedCompaniesList([...assignedCompaniesListAsPerRole]);

        this.onCloseModal(); // using this fuintion to close the modal and reset all values
      } else {
        alert("Select atleast 1 job");
      }
    },

    onCloseModal() {
      this.setSelectedAddEditCompanyName("");
      this.setSelectedAddEditCompanyId(0);
      this.setIsModal("");
      this.setSelectedEditJobsList([]);
    },

    onRemoveCompany() {
      let assignedCompaniesList = this.assignedCompaniesList.filter((value) => {
        return value["company_name"] !== this.selectedAddEditCompanyName;
      });
      this.setAssignedCompaniesList([...assignedCompaniesList]);
      this.onCloseModal();
    },
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 8% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
