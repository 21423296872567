<template>
  <div class="mt-4">
    <toggle-button
      @onToggleChange="onToggle"
      labelLeftText="Presentation Mode"
      :isChecked="isPresentationMode"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import ToggleButton from "../../ui/ToggleButton.vue";

export default defineComponent({
  name: "PresentationModeSwitcher",
  computed: {
    ...mapGetters(["isPresentationMode"]),
  },
  components: {
    ToggleButton,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["setPresentationMode"]),

    onToggle(value) {
      this.setPresentationMode(value.target.checked);
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    },
  },
});
</script>
<style scoped>
@media only screen and (max-width: 924px) {
  .smallScreenMargin {
    margin-left: 20px;
  }
}
</style>
