<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <div class="flex flex-1 flex-col h-full">
        <div class="grid grid-cols-2 gap-4">
          <div class="ml-5 flex flex-1 items-center">
            <p class="font-bold text-lg">Request A New Job</p>
          </div>
          <div>
            <span class="close" @click="$emit('onModalClose', true)"
              >&times;</span
            >
          </div>
        </div>

        <div class="h-full">
          <iframe
            src="https://share.hsforms.com/1fNiGfyIJRMmOnAvGjCiKdQ5dv32"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #ffffff;
  margin: 4% auto; /*  from the top and centered */
  padding: 5px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
  height: 85vh;
  border-radius: 10px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
