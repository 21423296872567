<template>
  <time-tracking-gauge-skeleton v-if="loading" />

  <div v-else>
    <div
      class="h-[300px] w-full rounded-3xl shadow-2xl pl-5 pr-3 pt-2 pb-4 flex flex-col justify-around mt-5"
      style="background-color: rgba(104, 67, 149, 0.3)"
    >
      <div class="flex flex-row">
        <div class="flex-1">
          <label class="block text-2xl text-black mt-2 mb-5 capitalize">
            {{
              $t(
                `timeTracking.${TimeTrackingGaugeDetails.general_info.widget_name}`
              )
            }}
          </label>
        </div>
        <div class="flex flex-1 justify-end">
          <button
            v-if="isAdmin"
            class="text-black flex flex-row h-8 text-sm font-semibold"
          >
            <CogIcon class="w-5 h-5 text-black" @click="onOpenSetting()" />
          </button>
        </div>
      </div>
      <div class="flex flex-row justify-center w-full">
        <vue-gauge
          :refid="'Time-Tracking-Gauge'"
          :options="timeTrackingGaugeOption"
        />
      </div>
    </div>
  </div>
  <time-frame-settings-modal
    v-if="isModal === 'Zeitrahmen'"
    @onModalClose="onModalClose()"
    :TimeTrackingGaugeDetails="TimeTrackingGaugeDetails"
  />
</template>

<script>
import { mapGetters } from "vuex";
import VueGauge from "vue-gauge";
import { CogIcon } from "@heroicons/vue/solid";
import _ from "lodash";
import axios from "axios";
import TimeFrameSettingsModal from "../../views/DashboardJob/ProjectDetailandTimeFrameWidgets/Modals/TimeFrameSettingsModal.vue";
import TimeTrackingGaugeSkeleton from "./TimeTrackingGaugeSkeleton.vue";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../helperFunctions/DataCaching";

export default {
  props: ["loadingToggle"],
  components: {
    VueGauge,
    CogIcon,
    TimeFrameSettingsModal,
    TimeTrackingGaugeSkeleton,
  },
  watch: {
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentJobId",
      "authorizationHeader",
      "backendUrl",
      "isAdmin",
    ]),

    timeTrackingGaugeOption() {
      // logic to find percentages of all values
      let timeInfoValues = _.omit(
        { ...this.TimeTrackingGaugeDetails.time_Info },
        ["newest_created_TEs_TimeStamp", "estimatedTimeSpentFromStartTilNow"]
      );

      let highestValueKey = Object.keys(timeInfoValues).reduce((a, b) =>
        JSON.parse(timeInfoValues[a]) > JSON.parse(timeInfoValues[b]) ? a : b
      );
      if (highestValueKey == "totalTimeSpentFromStartTilNow") {
        timeInfoValues = {
          ...timeInfoValues,
          totalTimeSpentFromStartTilNow: 99,
          estimatedTotalTimeToSpend: 80,
        };
      } else {
        Object.keys(timeInfoValues).map((key) => {
          let currentKeyValue = JSON.parse(timeInfoValues[key]);
          let highestValue = JSON.parse(
            this.TimeTrackingGaugeDetails.time_Info[highestValueKey]
          );
          let percentage = JSON.parse(
            ((currentKeyValue / highestValue) * 100).toFixed(2)
          );
          timeInfoValues = {
            ...timeInfoValues,
            [key]: percentage >= 98 ? 90 : percentage + 1,
          };
        });
      }
      timeInfoValues = Object.entries(timeInfoValues) // sort the object
        .sort(([, a], [, b]) => a - b)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

      // logic to check if the difference between both percentage is less than 5 than decrease 10 from first value
      // to avoid overlapping of labels of hrs
      if (
        Math.abs(
          _.values(timeInfoValues).reduce(
            (firstValue, SecondValue) => firstValue - SecondValue
          )
        ) <= 5
      ) {
        timeInfoValues[Object.keys(timeInfoValues)[0]] =
          timeInfoValues[Object.keys(timeInfoValues)[0]] - 10;
      }

      // set arc labels and arc colors according to the value of total time and actual spend time
      let totalTime = JSON.parse(
        this.TimeTrackingGaugeDetails.time_Info["estimatedTotalTimeToSpend"]
      );
      let timeSpendTillNow = JSON.parse(
        this.TimeTrackingGaugeDetails.time_Info["totalTimeSpentFromStartTilNow"]
      );
      let arcLabels = [];
      let arcColors = [];
      if (parseInt(totalTime) < parseInt(timeSpendTillNow)) {
        arcLabels = [
          `${totalTime.toFixed(2)}h`,
          `${timeSpendTillNow.toFixed(2)}h`,
        ];
        arcColors = [
          "#4ade80",
          "rgb(248, 113, 113)",
          "rgb(248, 113, 113)",
          "rgb(248, 113, 113)",
        ];
      } else {
        arcLabels = [
          `${timeSpendTillNow.toFixed(2)}h`,
          `${totalTime.toFixed(2)}h`,
        ];
        arcColors = [
          "#4ade80",
          "#2eb15e",
          "rgb(248, 113, 113)",
          "rgb(248, 113, 113)",
        ];
      }
      return {
        needleValue: timeInfoValues["totalTimeSpentFromStartTilNow"],
        arcDelimiters: _.values(timeInfoValues).sort(function (e1, e2) {
          return e1 - e2;
        }),
        arcColors: arcColors,
        arcLabels: arcLabels,
        needleColor: "rgb(75 85 99)",
        centralLabel: `${this.TimeTrackingGaugeDetails.percentage_calculations["percentageOfEstimatedTotal"]}`,
        rangeLabel: ["", ""],
        arcOverEffect: false,
        chartWidth: 255,
      };
    },
  },
  methods: {
    onOpenSetting() {
      this.isModal = "Zeitrahmen";
    },
    onModalClose() {
      this.isModal = "";
    },
    fetchDataFromBackend(jobId) {
      this.loading = true;
      const headers = this.authorizationHeader;
      axios
        .get(
          this.backendUrl +
            "/widget/time-tracking-gauge" +
            "?jobId=" +
            this.currentJobId,
          {
            headers,
          }
        )
        .then((response) => {
          storeJobDataInCache(jobId, response.data, "TimeTrackingGauge");
          this.postProcessOfFetchedData(response.data);
        })
        .catch((error) => console.log(error));
    },
    postProcessOfFetchedData(data) {
      this.TimeTrackingGaugeDetails = data;
      this.loading = false;
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "TimeTrackingGauge")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "TimeTrackingGauge")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
  data() {
    return {
      TimeTrackingGaugeDetails: {},
      loading: true,
      isModal: "",
    };
  },
};
</script>
