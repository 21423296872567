<template>
    <div class="tag-dropdown">
      <div class="tag-color-wrapper">
        <div class="tag-color" :style="`background-color:${colorForTag(defaultTag)};`"></div>
        <label class="category-label">{{ category }}</label>
      </div>
      <div class="dropdown-wrapper">
        <select v-model="selectedTag" @change="onSelectTag" class="border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          <option v-for="tag in filteredTags" :key="tag.name" :value="tag.name">
            {{ tag.name }}
          </option>
        </select>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      allTags: {
        type: Array,
        default: () => []
      },
      category: {
        type: String,
        required: true
      },
      defaultTag: {
        type: String,
        required: true
      },
      allHashtagLevels: {
        type: Object,
        required: true
      },
      clearTrigger: {
        type: Boolean,
        default: false
    }
    },
    data() {
      return {
        selectedTag: this.defaultTag
      };
    },
      watch: {
        clearTrigger() {
        this.resetSelectedTag();
      }
    },
    computed: {
      filteredTags() {
        return this.allTags.filter(tag => tag.category === this.category);
      }
    },
    methods: {
      onSelectTag() {
        this.$emit('tagSelected', this.selectedTag);
      },
      colorForTag(tagName) {
        return this.allHashtagLevels[tagName] || '#000000'; 
      },
      resetSelectedTag() {
        this.selectedTag = ''; // Reset the selected tag
        //this.$emit('tagSelected', this.selectedTag); 
    }
    }
  };
  </script>
  
  <style scoped>
  .tag-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.tag-color-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.category-label {
  margin-left: 10px;
}

.dropdown-wrapper {
  display: flex;
  align-items: center;
}

select {
  width: 150px;
  padding: 8px;
  font-size: 14px;
}

.tag-color {
  width: 20px;
  height: 20px;
}
  </style>
  