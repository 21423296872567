<template>
  <label class="block text-sm font-medium text-gray-700">{{
    data.label
  }}</label>
  <select
    id="code"
    name="code"
    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    v-model="select"
    :disabled="status == 'In Planung'"
  >
    <option :value="originalProjectCode" @change="emitValueChange()">
      {{ originalProjectCode }}
    </option>
    <option
      v-for="option in projectCodeArray"
      :key="option"
      :value="option"
      @change="emitValueChange()"
    >
      {{ option }}
    </option>
  </select>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../helperFunctions/DataCaching";
export default {
  props: ["data", "status"],
  emits: ["change"],
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
  },
  data() {
    return {
      select: "",
      projectCodeArray: [],
      originalProjectCode: this.data.value,
    };
  },
  watch: {
    select() {
      this.emitValueChange();
    },
  },
  methods: {
    emitValueChange() {
      this.$emit("change", this.select);
    },

    fetchDataFromBackend() {
      const headers = this.authorizationHeader;
      axios
        .get(
          `${this.backendUrl}/job/timetracking-mapping?jobId=${this.currentJobId}`,
          {
            headers,
          }
        )
        .then((response) => {
          storeJobDataInCache(
            this.currentJobId,
            response.data,
            "TimeTrackingMapping"
          );
          this.postProcessOfFetchedData(response.data);
        })
        .catch((error) => console.log(error));
    },
    postProcessOfFetchedData(data) {
      this.projectCodeArray = data.input_values;
      this.select = this.data.value;
    },
  },
  mounted() {
    // fetch data from cache of current job id -
    // if exist then do not attempt API hit and get all data from cache else get data from backend
    if (jobDataInCache(this.currentJobId, "TimeTrackingMapping")) {
      this.postProcessOfFetchedData(
        jobDataInCache(this.currentJobId, "TimeTrackingMapping")
      );
    } else {
      this.fetchDataFromBackend();
    }
  },
};
</script>
