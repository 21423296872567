<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <form class="w-full max-w-lg" @submit.prevent="onSubmit">
        <div
          class="flex flex-1 flex-col w-full justify-center items-center my-8 mx-2"
        >
          <label
            class="flex tracking-wide text-gray-700 flex-nowrap"
            for="grid-first-name"
          >
            {{ $t("confirm-msg.delete") }}
          </label>

          <label
            class="flex uppercase tracking-wide text-gray-800 font-bold flex-nowrap"
            for="grid-first-name"
          >
            {{ this.companyInformation.name }}
          </label>
        </div>

        <div class="flex flex-1 flex-row w-full justify-center items-center">
          <button
            class="bg-white py-2 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
            @click="$emit('onModalClose')"
            type="button"
          >
            <span>Cancel</span>
          </button>
          <button
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md"
          >
            <span>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { removeCompleteCompanyDataFromCache } from "../../helperFunctions/DataCaching";

export default {
  props: ["companyInformation"],
  mounted() {
    //initialize all values only when user want to edit the company info
    if (this.companyInformation) {
      this.companyName = this.companyInformation.name;
    }
  },
  data() {
    return {
      companyName: null,
    };
  },
  computed: {
    ...mapGetters(["backendUrl", "authorizationHeader"]),
  },
  methods: {
    onSubmit() {
      if (this.name == "") {
        this.isValidationFail = true;
      } else {
        // call an api endpoint to submit complete data
        let headers = this.authorizationHeader;
        let url =
          this.backendUrl +
          "/company/delete-internal/?companyId=" +
          this.companyInformation.company_id;
        let method = "DELETE";
        let companyId = this.companyInformation.company_id;
        axios({
          method,
          url,
          headers: headers,
          data: companyId,
        })
          .then((response) => {
            this.$emit("onWarningMsg", response.data, "/clients");
            this.$store.dispatch("resetCompanyVariables");
            removeCompleteCompanyDataFromCache(
              this.companyInformation.company_id
            );
          })
          .catch((error) => {
            this.errorMsg = error.message;
            console.log(error.response.data);
            this.$emit("onWarningMsg", error.response.data, null);
          });
      }
    },
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 6% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
