<template>
  <skeleton-disqualification-reasons-widget v-if="loading" />
  <div v-else class="flex flex-col w-full mr-2">
    <div
      class="pt-2 align-middle inline-block w-full px-6 pb-6 bg-white rounded-3xl shadow-2xl"
    >
      <div class="flex flex-1 flex-row">
        <div>
          <label class="block text-2xl text-black px-4 pt-4">
            {{ $t(`disqualificationReason.${disqualificationByType}`) }}
          </label>
        </div>
        <div
          class="flex flex-1 justify-end items-center mr-5"
          :key="disqualificationByType"
        >
          <label class="block text-6xl font-bold text-gray-500">
            {{
              Object.values(this.totalCountPerStage).reduce(
                (partialSum, a) => partialSum + a,
                0
              )
            }}
          </label>
        </div>
        <div class="flex justify-end items-center">
          <button
            class="text-black flex h-8 text-sm font-semibold"
            @click="onToggleChange()"
          >
            <SwitchHorizontalIcon class="w-6 h-6 text-black" />
          </button>
        </div>
      </div>

      <div>
        <Bar :chart-data="chartData" :chart-options="chartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../../../components/helperFunctions/DataCaching";
import SkeletonDisqualificationReasonsWidget from "./SkeletonDisqualificationReasonsWidget.vue";
import { SwitchHorizontalIcon } from "@heroicons/vue/solid";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  props: ["loadingToggle"],
  components: {
    SkeletonDisqualificationReasonsWidget,
    Bar,
    SwitchHorizontalIcon,
  },

  data() {
    return {
      loading: true,
      responseData: {},
      totalCountPerStage: {},
      disqualificationByType: "disqualificationByReason",
    };
  },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
    toggleLoadingSaveButton() {
      return this.$store.getters.toggleLoading;
    },

    dataAccordingDisqualificationType() {
      return this.disqualificationByType === "disqualificationByReason"
        ? this.responseData["sort_by_reason"]
        : this.responseData["sort_by_stage"];
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        scales: {
          y: {
            beginAtZero: true,

            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: true,
            },
          },
        },

        plugins: {
          tooltip: {
            callbacks: {
              label: this.setLabels,
            },
          },
        },
      };
    },
    chartData() {
      const labels = Object.entries(this.totalCountPerStage).map(
        ([value, count]) => `${value} (${count})`
      );
      return {
        labels: labels,
        datasets: [
          {
            label: "Disqualifications",
            backgroundColor: "#a5b4fc",
            data: Object.values(this.totalCountPerStage),
          },
        ],
      };
    },
  },
  watch: {
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
  },
  methods: {
    fetchDataFromBackend(jobId) {
      if (jobId !== null) {
        this.loading = true;
        const headers = this.authorizationHeader;
        axios
          .get(
            this.backendUrl +
              "/widget/disqualified-candidates-in-process" +
              "?jobId=" +
              jobId,
            {
              headers,
            }
          )
          .then((response) => {
            storeJobDataInCache(
              jobId,
              response.data,
              "DisqualificationReasons"
            );
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.responseData = data;
      this.loading = false;
      this.getTotalCountForEachStage(data.sort_by_reason);
    },
    getTotalCountForEachStage(data) {
      this.totalCountPerStage = {}; // necessary to update the DOM in graph

      // Calculate the total count for each stage
      this.totalCountPerStage = Object.keys(data).reduce((acc, value) => {
        const totalCount = Object.values(data[value]).reduce(
          (sum, childValue) => sum + childValue,
          0
        );
        acc[value] = totalCount;
        return acc;
      }, {});

      // Sort the object entries by value in descending order
      const sortedEntries = Object.entries(this.totalCountPerStage).sort(
        (a, b) => b[1] - a[1]
      );

      // Extract the sorted keys into a new an object with key value pair
      this.totalCountPerStage = sortedEntries.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      // logic to send the double disqualification reasons at the last key
      if (this.totalCountPerStage["not set"]) {
        const doubleDQ = this.totalCountPerStage["not set"];
        delete this.totalCountPerStage["not set"];
        this.totalCountPerStage["not set"] = doubleDQ;
      }
    },
    setLabels(context) {
      // check the condition of which disqualification type is active then get that data from overall JSON
      const newData = this.dataAccordingDisqualificationType;
      return Object.entries(newData[context.label.split("(")[0].trim()]).map(
        ([key, value]) => `${key}: ${value}`
      );
    },
    onToggleChange() {
      this.disqualificationByType =
        this.disqualificationByType === "disqualificationByReason"
          ? "disqualificationByStage"
          : "disqualificationByReason";
      this.getTotalCountForEachStage(this.dataAccordingDisqualificationType);
    },
  },

  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "DisqualificationReasons")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "DisqualificationReasons")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
