<template>
  <skeleton-input-form v-if="isLoading"></skeleton-input-form>

  <div
    v-else-if="selectData.length > 0"
    class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6"
  >
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{ $t("projekt-einstellungen.stage-mapping") }}
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          {{ $t("projekt-einstellungen.stage-mapping-text") }}
        </p>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <div class="grid grid-cols-6 gap-6">
          <div
            class="col-span-6 sm:col-span-3"
            v-for="select in selectData"
            :key="select.id"
          >
            <label
              for="location"
              class="block text-sm font-medium text-gray-700"
            >
              {{ select.label }}</label
            >
            <select
              id="location"
              name="location"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              v-model="select.text"
            >
              <option
                v-for="option in optionData.texts"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import SkeletonInputForm from "./SkeletonInputForm.vue";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../helperFunctions/DataCaching";

export default {
  components: {
    SkeletonInputForm,
  },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
  },
  data() {
    return {
      selectData: [],
      optionData: {
        values: [],
        texts: [],
      },
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(["closeProjectPreferences", "setApiToRun"]),
    fetchDataFromBackend() {
      const headers = this.authorizationHeader;
      this.isLoading = true;

      axios
        .get(
          `${this.backendUrl}/job/stages-mapping?jobId=${this.currentJobId}`,
          {
            headers,
          }
        )
        .then((response) => {
          storeJobDataInCache(this.currentJobId, response.data, "StageMapping");
          this.postProcessOfFetchedData(response.data);
        })
        .catch((error) => {
          this.isLoading = false;

          console.log(error);
        });
    },
    postProcessOfFetchedData(data) {
      this.selectData = data.selectData;
      this.optionData = data.optionData;
      this.isLoading = false;
    },
    submitData() {
      let body = { jobId: this.currentJobId };
      this.selectData.forEach(
        (select) => (body[select.id] = this.getIdbyOption(select.text))
      );
      const headers = this.authorizationHeader;
      this.isLoading = true;

      axios
        .put(
          this.backendUrl + "/job/stages-mapping?jobId=" + this.currentJobId,
          body,
          { headers }
        )
        .then(() => {
          this.setApiToRun("/job/stages-mapping");
        })
        .catch((error) => console.log(error));
      this.isLoading = false;
    },
    getIdbyOption(text) {
      let i = this.optionData.texts.findIndex((x) => x === text);
      return this.optionData.values[i];
    },
  },
  mounted() {
    // fetch data from cache of current job id -
    // if exist then do not attempt API hit and get all data from cache else get data from backend
    if (jobDataInCache(this.currentJobId, "StageMapping")) {
      this.postProcessOfFetchedData(
        jobDataInCache(this.currentJobId, "StageMapping")
      );
    } else {
      this.fetchDataFromBackend();
    }
  },
};
</script>
