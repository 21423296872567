import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      job: {
        id: null,
        name: "",
        kookuId: "",
      },
      company: {
        id: 0,
        name: "",
      },
      companies: [],
      filteredActiveJobs: [],
      allEndedJobs: [],
      activeJobs: [],
      activeCompanies: [],
      filteredActiveCompanies: [],
      keywords: [],
      sortBy: "",
      pendingApisCount: 0,
    };
  },
  mutations,
  actions,
  getters,
};
