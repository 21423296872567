<template>
  <div class="grid grid-cols-3 gap-3">
    <div>
      <label class="block text-sm font-medium text-gray-700"
        >Select Level</label
      >
      <select
        id="code"
        name="code"
        v-model="selectedLevel"
        class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        <option
          v-for="option in Object.keys(levelsForDropdown)"
          :key="option"
          :value="option"
        >
          <p>{{ option }}</p>
        </option>
      </select>

      <button
        v-if="isNewTag"
        class="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center justify-center shadow-md primary-button mt-5 w-full"
        @click="onAddNewTag()"
      >
        <p class="text-center">Add Tag</p>
      </button>
    </div>

    <div class="col-span-2 mt-5 ml-5">
      <div class="tag-input-container">
        <input
          type="text"
          v-model="newHashTag"
          @input="onFilterSuggestions"
          :placeholder="placeholder"
        />
        <ul class="dropdown" v-if="filteredSuggestions.length > 0">
          <li
            v-for="suggestion in filteredSuggestions"
            :key="suggestion"
            @click="addTagFromDropdown(suggestion)"
          >
            {{ suggestion.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["selectedData", "placeholder", "isForJob"],
  data() {
    return {
      newHashTag: "",
      filteredSuggestions: [],
      selectedLevel: this.isForJob ? "Seniority" : "Industry",
    };
  },
  computed: {
    ...mapGetters([
      "allTags", // [{category: <>, name: <>}]
      "levelsHexColors",
      "companyLevelsHexColors",
      "allHashtagLevels",
    ]),

    isNewTag() {
      let insertAllowedTags = [
        "Department",
        "Key Skills",
        "Default",
        "Company Type",
      ];
      // if the tag name is not already there in allTags array then show add tag button only
      let filteredTags = this.allTags.filter(
        (value) => value.name === this.newHashTag.toLowerCase()
      );
      return filteredTags.length <= 0 &&
        this.newHashTag.length > 0 &&
        insertAllowedTags.includes(this.selectedLevel)
        ? true
        : false;
    },
    levelsForDropdown() {
      if (this.isForJob)
        return { ...this.levelsHexColors, ...this.companyLevelsHexColors };
      else return { ...this.companyLevelsHexColors };
    },
  },
  watch: {
    selectedLevel() {
      this.onFilterSuggestions();
    },
    selectedData() {
      this.onFilterSuggestions();
    },
    allTags() {
      this.onFilterSuggestions();
    },
  },
  methods: {
    onFilterSuggestions() {
      if (this.isForJob)
        // Filter suggestions for jobs when not considering category
        this.filteredSuggestions = this.allTags.filter(
          (suggestion) =>
            suggestion.name
              .toLowerCase()
              .includes(this.newHashTag.toLowerCase()) &&
            !this.selectedData.includes(suggestion.name) &&
            suggestion.category == this.selectedLevel
        );
      // Filter suggestions for companies considering category
      else
        this.filteredSuggestions = this.allTags.filter(
          (suggestion) =>
            suggestion.name
              .toLowerCase()
              .includes(this.newHashTag.toLowerCase()) &&
            !this.selectedData.includes(suggestion.name) &&
            Object.keys(this.companyLevelsHexColors).includes(
              suggestion.category
            ) &&
            suggestion.category == this.selectedLevel
        );
    },
    onAddNewTag() {
      // Check if the newHashTag already exists in the data (case-insensitive comparison)
      const isValueExist = this.allTags.some(
        (item) => item.name.toLowerCase() === this.newHashTag.toLowerCase()
      );

      if (isValueExist) {
        // Display an alert if the newHashTag already exists in the data
        alert("Sorry, this hashtag is already exist");
      } else {
        // When the user wants to add a new tag to the database, ask for confirmation
        const isConfirmed = window.confirm(
          "Are you sure you want to add a new Hashtag?"
        );
        if (isConfirmed) {
          // If the user confirms the action, emit an event to add the new hashtag to the database
          this.$emit("onAddTag", this.newHashTag, this.selectedLevel, false);
        }
      }

      // Reset the newHashTag variable for the input field and filter suggestions
      this.newHashTag = "";
      this.onFilterSuggestions();
    },
    addTagFromDropdown(hashtag) {
      this.newHashTag = "";
      this.$emit("onAddTag", hashtag, null, true);
      this.onFilterSuggestions();
    },
  },
};
</script>

<style scoped>
.tag-input-container {
  display: inline-block;
  position: relative;
}

input[type="text"] {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 300px;
}

/* Styles for the dropdown container */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease;

  /* Hide the default scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* WebKit (Chrome, Safari) custom scrollbar styles */
.dropdown::-webkit-scrollbar {
  width: 8px;
}

.dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dropdown::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Styles for the dropdown items */
.dropdown li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Hover effect for dropdown items */
.dropdown li:hover {
  background-color: #f2f2f2;
}
</style>
