<template>
  <div>
    <div class="mt-5">
      <p class="mb-3 font-bold text-center">Time Expenditure</p>
      <form action="#" method="PUT">
        <div
          class="col-span-6 sm:col-span-3"
          v-for="(singleRow, i) in rowHeader"
          v-bind:key="singleRow"
        >
          <div class="mt-3">
            <label
              :for="singleRow"
              class="block text-sm font-medium text-gray-700"
            >
              {{ $t(`timeTracking.${singleRow.replaceAll(".", "")}`) }}</label
            >
            <div>
              <input
                type="number"
                :name="singleRow"
                :id="singleRow"
                v-model="rowValues[i]"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="flex flex-1 flex-row w-full justify-end items-end mt-4">
      <modal-footer-button
        @onModalClose="$emit('onModalClose')"
        @onSubmit="onNextStep"
        buttonText="Next"
        isCancelDisabled="true"
      />
    </div>
  </div>
</template>

<script>
import ModalFooterButton from "../../ui/ModalFooterButton.vue";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalFooterButton,
  },
  props: ["jobDetails"],
  data() {
    return {
      responseData: {},
      rowHeader: [],
      rowValues: [],
    };
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl"]),
  },
  methods: {
    onNextStep() {
      let body = { job_id: this.jobDetails.new_job_id };
      this.responseData.forEach((ele, i) => (body[ele.id] = this.rowValues[i]));
      const headers = this.authorizationHeader;

      axios
        .put(
          this.backendUrl +
            "/job/estimated-time-expenditure" +
            "?jobId=" +
            this.jobDetails.new_job_id,
          body,
          {
            headers,
          }
        )
        .then(() => {
          this.$emit("onNextStep");
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    const headers = this.authorizationHeader;

    axios
      .get(
        this.backendUrl +
          "/job/estimated-time-expenditure" +
          "?jobId=" +
          this.jobDetails.job_id,
        {
          headers,
        }
      )
      .then((response) => {
        let data = response.data;
        this.responseData = data;
        this.rowHeader = data.map((value) => value.label);
        this.rowValues = data.map((value) => value.value);
      })
      .catch((error) => console.log(error));
  },
};
</script>
