<template>
  <div>
    <div class="modal-overlay">
      <div class="modal-content">
        <!-- HubSpot form container -->
        <div id="hubspot-form-container"></div>

        <div class="flex flex-1 justify-end">
          <button class="btn btn-error text-white" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {},
  mounted() {
    this.loadHubSpotForm();
  },
  methods: {
    loadHubSpotForm() {
      // Load the HubSpot form script
      const script = document.createElement("script");
      script.charset = "utf-8";
      script.type = "text/javascript";
      script.src = "//js.hsforms.net/forms/embed/v2.js";

      script.onload = () => {
        // Create the HubSpot form
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "9044894",
            formId: "0e0688f7-38d6-44ea-b767-70b75b9fdb62",
            target: "#hubspot-form-container", // Specify the target container
          });
        }
      };

      document.head.appendChild(script);
    },

    closeModal() {
      this.$emit("onModalClose");
    },
  },
};
</script>

<style scoped>
/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  width: 80%;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 40px;
}
</style>
