<template>
  <div class="flex border bg-white mb-3 p-5 w-full">
    <div class="mx-5 w-1/6 r mb-5">
      <div class="mx-5 w-20 rounded-md mb-5 wave h-5"></div>
    </div>
    <div class="mx-5 mb-5 w-3/6 flex flex-col">
      <div class="mx-5 w-80 rounded-md mb-5 wave h-5"></div>
      <div class="mx-5 w-96 rounded-md mb-5 wave h-5"></div>
      <div class="mx-5 w-64 rounded-md mb-5 wave h-5"></div>
    </div>
    <div class="mx-5 w-2/6 rounded-md mb-5">
      <div class="mx-5 w-48 rounded-md mb-5 wave h-5"></div>
    </div>
  </div>
</template>
<style scoped>
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
