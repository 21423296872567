import { ROLE_ADMIN } from "../../../constant/Constants";

export default {
  setControls(state, payload) {
    state.controls = { ...payload };
  },
  setAllCompaniesList(state, payload) {
    state.allCompaniesList = [...payload];
  },
  setIsFormValidated(state, payload) {
    state.isFormValidated = payload;
  },
  setSelectedAddEditCompanyName(state, payload) {
    state.selectedAddEditCompanyName = payload;
  },
  setSelectedAddEditCompanyId(state, payload) {
    state.selectedAddEditCompanyId = payload;
  },
  setSelectedEditJobsList(state, payload) {
    state.selectedEditJobsList = [...payload];
  },
  setIsModal(state, payload) {
    state.isModal = payload;
  },
  setAssignedCompaniesList(state, payload) {
    state.assignedCompaniesList = [...payload];
  },
  setUserList(state, payload) {
    state.userList = [...payload];
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
  resetState(state) {
    state.allCompaniesList = [];
    state.isFormValidated = true;
    state.selectedAddEditCompanyName = "payload";
    state.selectedAddEditCompanyId = 0;
    state.selectedEditJobsList = [];
    state.assignedCompaniesList = [];
    state.roles = [];
    state.isModal = "";
    state.controls = {
      role: {
        value: ROLE_ADMIN,
        validationRules: [],
        errorMessage: "",
      },
      "first-name": {
        value: "",
        validationRules: ["required"],
        errorMessage: "",
      },
      "last-name": {
        value: "",
        validationRules: ["required"],
        errorMessage: "",
      },
      "email-address": {
        value: "",
        validationRules: ["email"],
        errorMessage: "",
      },
    };
  },
};
