import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      accessToken: null,
      refreshToken: null,
      didAutoLogout: false,
      isPresentationMode: false,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      user: {
        mail: null,
        name: null,
        isAdmin: false,
        roleName: null,
        noShowAtsConnector: false,
      },
      // this object is only for super admin, when it changes to another user it;s actual details save here
      actualUserDetails: {
        mail: null,
        name: null,
        isAdmin: false,
        roleName: null,
        noShowAtsConnector: false,
      },
    };
  },
  mutations,
  actions,
  getters,
};
