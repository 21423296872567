<template>
  <div class="flex flex-col mr-7 newWidth justify-around bg-white rounded-lg">
    <div class="my-2 overflow-x-auto rounded-lg">
      <div
        class="
          pt-2
          align-middle
          inline-block
          w-full
          px-6
          pb-6
          bg-white
          rounded-lg
        "
      >
        <div class="flex flex-row justify-between">
          <div class="flex flex-col">
            <div
              class="h-6 w-60 bg-gray-400 ml-4 mr-1 mt-3 rounded-lg wave"
            ></div>
            <div
              class="h-3 w-56 bg-gray-400 ml-4 mr-1 mt-1 rounded-lg wave"
            ></div>
            <div
              class="h-3 w-56 bg-gray-400 ml-4 mr-1 mt-1 mb-2 rounded-lg wave"
            ></div>
          </div>
          <div
            class="h-14 w-10 bg-gray-400 ml-4 mr-1 mt-3 mb-2 rounded-lg wave"
          ></div>
        </div>
        <div class="rounded-lg bg-white relative"></div>
      </div>
    </div>
    <table class="min-w-full divide-gray-200">
      <thead class="bg-white">
        <tr>
          <th scope="col" class="px-6 py-3" v-for="num in 2" v-bind:key="num">
            <div
              class="h-3 w-24 bg-gray-400 ml-4 mr-1 mt-1 mb-2 rounded-lg wave"
            ></div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="num in 3" :key="num" class="rounded-2xl bg-white">
          <td
            class="px-6 py-4"
            v-for="(num, i) in 2"
            :key="num"
            :class="i == 0 && 'border-r border-gray-200'"
          >
            <div
              class="h-3 w-40 bg-gray-400 ml-4 mr-1 mt-1 mb-2 rounded-lg wave"
            ></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
.newWidth {
  width: 38%;
}
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
