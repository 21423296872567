<template>
  <Listbox as="div" v-model="selected" class="mr-2">
    <div
      @click="showListCompany"
      class="flex flex-row border border-2 w-64 mt-1 md:w-80 md:max-w-xs rounded-2xl border-black p-2 ml-2 sm:text-sm align-middle smallScreenSelectWidth"
    >
      <div class="mr-2 truncate w-5/6" id="selectedItem">
        {{ selected.name }}
      </div>

      <SelectorIcon class="h-5 text-gray-400 w-1/6" aria-hidden="true" />
    </div>
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ul
        v-if="showList"
        class="absolute z-10 ml-2 mt-1 w-64 md:max-w-xs smallScreenSelectWidth sm:w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      >
        <div class="flex justify-center mb-3">
          <input
            id="companySearch"
            class="appearance-none block text-gray-700 focus:outline-none focus:bg-white border p-1 w-72 rounded-md"
            placeholder="Search Company"
            @input="onCompanyFilter($event)"
          />
        </div>

        <li
          @click="chosedCompany(company)"
          v-for="company in filterCompanies"
          :key="company"
          class="sm:text-xs p-2 flex flex-row"
          :class="{ active: active_el == company.id }"
        >
          {{ company.name }}
          <CheckIcon
            v-if="active_el == company.id"
            class="h-5 ml-1 w-1/5"
            aria-hidden="true"
          />
        </li>
      </ul>
    </transition>
  </Listbox>
</template>

<script>
import { mapGetters } from "vuex";

import { Listbox } from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Listbox,
    CheckIcon,
    SelectorIcon,
  },
  watch: {
    selected(newSelected) {
      this.$store.dispatch("setCompany", {
        newCompanyName: newSelected.name,
        newCompanyId: newSelected.id,
      });
    },
    company() {
      this.selected = this.company;
    },
    selectedCompanyId(id) {
      this.selectComp(id);
    },
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl", "selectedCompanyId"]),
    company() {
      return this.$store.getters.company;
    },
  },
  data() {
    return {
      companies: [],
      filterCompanies: [],
      selected: "",
      showList: false,
      active_el: null,
    };
  },
  methods: {
    onCompanyFilter(value) {
      this.filterCompanies = this.companies.filter((company) =>
        company.name.toLowerCase().includes(value.target.value.toLowerCase())
      );
    },
    showListCompany() {
      this.filterCompanies = [...this.companies];
      this.showList = !this.showList;
    },
    chosedCompany(newSelected) {
      this.$store.dispatch("setCompany", {
        newCompanyName: newSelected.name,
        newCompanyId: newSelected.id,
      });
      this.$store.dispatch("setJob", {
        newJobId: null,
        newJobName: null,
        newKookuId: null,
      });
      this.selected = newSelected;
      this.activate(newSelected.id);
      this.showList = false;
    },
    activate(el) {
      this.active_el = el;
    },
    selectComp(id) {
      let companySelectWithParam = this.companies.filter((company) => {
        return company.id == id;
      });
      this.selected = companySelectWithParam[0];
    },
    fetchDataFromStore() {
      this.companies = this.$store.getters.companies;
      this.companies.sort((a, b) => a.name.localeCompare(b.name));
      this.filterCompanies = [...this.companies];
      this.selected = this.company;
      this.activate(this.selected.id);
    },
  },
  mounted() {
    this.fetchDataFromStore();

    //  logic to close job list from header, check click event and if it is not occur from dropdown then close it else dont do anything
    document.body.addEventListener(
      "click",
      (event) => {
        if (!["selectedItem", "companySearch"].includes(event.target.id)) {
          this.filterCompanies = [...this.companies];
          this.showList = this.showList && false;
        }
      },
      true
    );
  },
};
</script>
<style scoped>
@media only screen and (max-width: 349px) {
  .smallScreenSelectWidth {
    width: 12rem;
  }
}
ul > li:hover {
  cursor: pointer;
}
.active {
  color: white;
  font-weight: bold;
  background-color: rgb(79 70 229);
}
</style>
