<template>
  <label class="block text-sm font-medium text-gray-700">{{
    $t(`projekt-einstellungen.${data.label}`)
  }}</label>
  <select
    id="code"
    name="code"
    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    v-model="select"
  >
    <option
      v-for="option in userList"
      :key="option.id"
      :value="option.name"
      @change="emitValueChange()"
    >
      {{ option.name }}
    </option>
  </select>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import {
  getGeneralDataInCache,
  storeGeneralDataInCache,
} from "../helperFunctions/DataCaching";

export default {
  props: ["data"],
  emits: ["change"],
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
  },
  data() {
    return {
      userList: [],
      select: this.data?.value ?? "Mathias Mengel",
    };
  },
  watch: {
    select() {
      this.emitValueChange();
    },
  },
  methods: {
    emitValueChange() {
      const selectedRecruiterIndex = this.userList.findIndex(
        (value) => value.name === this.select
      );
      this.$emit("change", {
        value: this.userList[selectedRecruiterIndex],
        details: this.data,
      });
    },

    fetchDataFromBackend() {
      const headers = this.authorizationHeader;
      axios
        .get(`${this.backendUrl}/auth/users`, {
          headers,
        })
        .then((response) => {
          let data = response.data.map(({ mail, name, id }) => ({
            mail,
            name,
            id,
          }));

          storeGeneralDataInCache("internalUserList", data);
          this.postProcessOfFetchedData(data);
        })
        .catch((error) => console.log(error));
    },
    postProcessOfFetchedData(data) {
      this.userList = [...data];
    },
  },
  mounted() {
    // fetch data from cache of current job id -
    // if exist then do not attempt API hit and get all data from cache else get data from backend
    if (getGeneralDataInCache("internalUserList")) {
      this.postProcessOfFetchedData(getGeneralDataInCache("internalUserList"));
    } else {
      this.fetchDataFromBackend();
    }
  },
};
</script>
