<template>
  <skeleton-actual-target-comparison v-if="loading" />
  <div
    v-else
    class="flex flex-col w-full h-[98%] bg-white my-2 ml-3 mr-2 rounded-3xl shadow-2xl pt-1"
  >
    <div class="overflow-x-auto pl-3">
      <div
        class="pt-2 align-middle inline-block px-6 pb-6 bg-white rounded-3xl w-full"
      >
        <div class="rounded-lg bg-white">
          <div class="flex flex-1 flex-row">
            <div class="flex flex-[0.9]">
              <label class="block text-2xl text-black bg-white pl-5 pt-4">
                {{ $t(`targetComparison.${name}`) }}
              </label>
            </div>

            <div class="flex flex-[0.1] items-end justify-end">
              <button
                v-if="isAdmin"
                class="text-black flex flex-row h-8 text-sm font-semibold mr-5"
              >
                <SwitchHorizontalIcon
                  @click="$emit('onToggleChange')"
                  class="w-5 h-5 text-black"
                />
              </button>
            </div>
          </div>

          <table class="min-w-full mb-2">
            <thead class="bg-white">
              <tr>
                <th
                  scope="col"
                  class="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  v-for="header in columnHeaders"
                  v-bind:key="header"
                >
                  {{ $t(`targetComparison.${header || "empty"}`) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in tableData"
                :key="data"
                :class="index % 2 !== 0 ? 'bg-white' : 'bg-gray-100'"
              >
                <td
                  class="pr-3 pl-5 py-2 whitespace-nowrap text-sm font-medium text-gray-500"
                >
                  {{ $t(`dashboardProjectStatus.${rowHeaders[index]}`) }}
                </td>
                <td
                  class="px-3 py-2 whitespace-nowrap text-sm text-gray-500 border-r-2"
                  v-for="value in data"
                  :key="value"
                >
                  {{ value }}
                </td>
                <td class="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                  <div
                    class="w-5 h-5 rounded-full bg-red border border-black flex flex-row items-center justify-center mr-3"
                    :class="colors[index]"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../../../components/helperFunctions/DataCaching";
import SkeletonActualTargetComparison from "./SkeletonActualTargetComparison.vue";
import { SwitchHorizontalIcon } from "@heroicons/vue/solid";

export default {
  props: ["loadingToggle"],
  components: {
    SkeletonActualTargetComparison,
    SwitchHorizontalIcon,
  },

  computed: {
    ...mapGetters([
      "currentJobId",
      "authorizationHeader",
      "backendUrl",
      "isAdmin",
    ]),
    toggleLoadingSaveButton() {
      return this.$store.getters.toggleLoading;
    },
  },

  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
    toggleLoadingSaveButton() {
      this.fetchDataFromBackend(this.currentJobId);
    },
  },
  data() {
    return {
      name: "",
      columnHeaders: [],
      rowHeaders: [],
      tableData: [],
      hasRowHeaders: false,
      colors: [],
      loading: true,
    };
  },
  methods: {
    fetchDataFromBackend(jobId) {
      this.loading = true;
      const headers = this.authorizationHeader;
      if (jobId !== null) {
        axios
          .get(
            this.backendUrl +
              "/widget/target-de-facto-comparison" +
              "?jobId=" +
              jobId,
            {
              headers,
            }
          )
          .then((response) => {
            storeJobDataInCache(jobId, response.data, "ActualTargetComparison");
            this.postProcessOfFetchedData(response.data);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFetchedData(data) {
      this.name = data.name;
      this.columnHeaders = data.column_headers;
      this.tableData = data.table_data;
      this.rowHeaders = data.row_headers;
      this.setColor();
      this.loading = false;
    },
    percentComparison(data1, data2) {
      return (Number(data1) * 100) / Number(data2);
    },
    setColor() {
      this.colors = [];
      this.tableData.forEach((couple) => {
        if (this.percentComparison(couple[0], couple[1]) >= 60) {
          this.colors.push(["bg-green-400"]);
        } else if (
          this.percentComparison(couple[0], couple[1]) < 60 &&
          this.percentComparison(couple[0], couple[1]) >= 40
        ) {
          this.colors.push(["bg-yellow-300"]);
        } else if (this.percentComparison(couple[0], couple[1]) < 40) {
          this.colors.push(["bg-red-400"]);
        }
      });
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "ActualTargetComparison")) {
        this.postProcessOfFetchedData(
          jobDataInCache(this.currentJobId, "ActualTargetComparison")
        );
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
<style scoped>
tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
