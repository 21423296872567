<template>
  <app-header />
  <div class="flex flex-row w-full">
    <div v-if="selectedViewDashboard == 'DashboardCard'"></div>
    <div class="my-5 mr-3 ml-2 w-full">
      <div class="flex flex-row justify-between mx-8">
        <ModalSubmitData v-if="toggleLoading" />
        <nav class="px-2 bg-white flex flex-row">
          <ChevronLeftIcon
            class="m-4 w-6 h-6 font-bold"
            @click="goToClientsPage"
          />
          <a
            v-for="item in views"
            :key="item.name"
            @click="selectNavigationItem(item)"
            :class="[
              selectedViewDashboard === item.component
                ? 'bg-gray-300 text-gray-900'
                : 'text-gray-600 bg-gray-100 hover:bg-gray-50 hover:text-gray-900',
              'flex justify-center items-center py-2 text-xl font-medium rounded-t-md',
            ]"
            class="w-60 mr-1"
          >
            {{ item.name }}
          </a>
        </nav>
        <div class="flex flex-row justify-between text-white">
          <button
            v-if="isAdmin"
            class="h-9 m-2 rounded-2xl bg-gray-400 p-4 flex flex-row items-center justify-center text-base shadow-md hover:bg-[#2197a7]"
            @click="onOpenUseAsTemplate"
          >
            {{ $t("job-clustering.template") }}
          </button>

          <button
            v-if="isAdmin"
            class="h-9 m-2 rounded-2xl bg-gray-400 p-4 flex flex-row items-center justify-center text-base shadow-md hover:bg-[#2197a7]"
            @click="openPreferenceCloseSideBar"
          >
            <CogIcon class="w-5 h-5 text-white mr-1" />
            {{ $t("dashboard.settings") }}
          </button>

          <pdf-button />
        </div>
      </div>
      <component :is="currentView"></component>
    </div>
    <project-preferences-silde-over></project-preferences-silde-over>

    <use-as-template-modal
      :job="jobDataForTemplate"
      v-if="onModal == 'useAsTemplateModal'"
      @onModalClose="onModalClose"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { shallowRef } from "vue";
import AppHeader from "../../components/layout/header/AppHeader.vue";
import DetailsCard from "./DetailsCard.vue";
import DashboardCard from "./DashbordCard.vue";
import ReportingsCard from "./ReportingsCard.vue";
import PdfButton from "./../../components/layout/header/PdfButton.vue";
import ProjectPreferencesSildeOver from "../../components/projectPreferences/ProjectPreferencesSildeOver.vue";
import UseAsTemplateModal from "../../components/Modals/JobClustering/UseAsTemplateModal.vue";

import {
  ClipboardListIcon,
  CogIcon,
  ChevronLeftIcon,
} from "@heroicons/vue/outline";
import ModalSubmitData from "./ModalSubmitData.vue";

export default {
  components: {
    AppHeader,
    DetailsCard,
    DashboardCard,
    ReportingsCard,
    ClipboardListIcon,
    CogIcon,
    ProjectPreferencesSildeOver,
    ChevronLeftIcon,
    PdfButton,
    ModalSubmitData,
    UseAsTemplateModal,
  },
  data() {
    return {
      currentView: shallowRef(DashboardCard),
      onModal: "",
    };
  },
  watch: {
    selectedViewDashboard(newVal) {
      if (newVal == "DashboardCard") {
        this.currentView = shallowRef(DashboardCard);
      } else if (newVal == "DetailsCard") {
        this.currentView = shallowRef(DetailsCard);
      } else if (newVal == "ReportingsCard") {
        this.currentView = shallowRef(ReportingsCard);
      }
    },
    isOpen() {
      if (this.isOpen == true) {
        let hubspot = document.getElementById(
          "hubspot-messages-iframe-container"
        );
        if (hubspot) hubspot.style.visibility = "hidden";
      } else if (this.isOpen == false) {
        let hubspot = document.getElementById(
          "hubspot-messages-iframe-container"
        );
        if (hubspot) hubspot.style.visibility = "visible";
      }
    },
  },
  computed: {
    ...mapGetters("sidebar", ["selectedViewDashboard"]),
    ...mapGetters([
      "toggleLoading",
      "isAdmin",
      "isRoleClient",
      "currentJobId",
      "currentJobName",
      "selectedCompanyId",
      "selectedCompanyName",
    ]),
    isOpen() {
      return this.$store.getters.isOpen;
    },
    selectedCompanyId() {
      return this.$store.getters.selectedCompanyId;
    },
    jobDataForTemplate() {
      let data = {
        company_id: this.selectedCompanyId,
        company_name: this.selectedCompanyName,
        job_id: this.currentJobId,
        job_name: this.currentJobName,
      };
      return data;
    },
    views() {
      const commonViews = [
        {
          name: "Dashboard",
          component: "DashboardCard",
        },
        {
          name: "Reportings",
          component: "ReportingsCard",
        },
      ];
      const additionalViews = [
        {
          name: "Details",
          component: "DetailsCard",
        },
      ];
      return this.isRoleClient
        ? commonViews
        : [...commonViews, ...additionalViews];
    },
  },

  methods: {
    ...mapActions("sidebar", ["setSelectedViewDashboard"]),
    selectNavigationItem(item) {
      this.selectedItem = item;

      this.setSelectedViewDashboard(item.component);
    },
    openPreferenceCloseSideBar() {
      this.$store.dispatch("toggleProjectPreferences");
    },
    goToClientsPage() {
      this.$router.push(`/clients/${this.selectedCompanyId}`);
    },
    onModalClose() {
      this.onModal = "";
    },
    onOpenUseAsTemplate() {
      this.onModal = "useAsTemplateModal";
    },
  },

  mounted() {
    this.setSelectedViewDashboard(this.views[0].component);
    this.jobDataForTemplate;
  },
};
</script>
