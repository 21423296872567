<template>
  <div class="bg-gray-200 mb-4 rounded-lg pl-9 pr-5 rounded-b-lg pb-12 pt-10">
    <div
      v-for="report in reports"
      :key="report.jobId"
      :tabIndex="report.jobId"
      className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-xl mt-5"
    >
      <div className="collapse-title">
        <div class="grid grid-cols-4">
          <div class="col-span-1">
            <p class="font-bold">{{ report.createdAt.split(" ")[0] }}</p>
          </div>
          <div class="col-span-2">
            <p class="font-bold">{{ report.reportName }}</p>
          </div>
          <div class="col-span-1">
            <div class="flex flex-1 flex-row justify-end">
              <button @click="onLiked(report)" :disabled="!isRoleClient">
                <ThumbUpIconSolid
                  v-if="JSON.parse(report.isLiked)"
                  class="w-6 h-6 mr-1 hover:text-gray-500 text-black"
                />
                <ThumbUpIconOutline
                  v-else
                  class="w-6 h-6 mr-1 hover:text-gray-500 text-black"
                />
              </button>
              <button :disabled="!isRoleClient" @click="onQuestionMark(report)">
                <QuestionMarkCircleIconSolid
                  v-if="JSON.parse(report.isQuestionable)"
                  class="w-6 h-6 mr-1 hover:text-gray-500 text-black"
                />
                <QuestionMarkCircleIconOutline
                  v-else
                  class="w-6 h-6 mr-1 hover:text-gray-500 text-black"
                />
              </button>
              <button>
                <DownloadIcon
                  @click="onDownload(report)"
                  class="w-6 h-6 mr-1 hover:text-gray-500 text-black"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="collapse-content">
        <textarea
          class="textarea textarea-bordered disabled w-full h-96"
          v-model="report.note"
        >
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import { DownloadIcon } from "@heroicons/vue/outline";
import { ThumbUpIcon as ThumbUpIconOutline } from "@heroicons/vue/outline";
import { QuestionMarkCircleIcon as QuestionMarkCircleIconOutline } from "@heroicons/vue/outline";
import { ThumbUpIcon as ThumbUpIconSolid } from "@heroicons/vue/solid";
import { QuestionMarkCircleIcon as QuestionMarkCircleIconSolid } from "@heroicons/vue/solid";
import {
  companyDataInCache,
  jobDataInCache,
} from "../../components/helperFunctions/DataCaching";

export default {
  components: {
    DownloadIcon,
    ThumbUpIconOutline,
    ThumbUpIconSolid,
    QuestionMarkCircleIconSolid,
    QuestionMarkCircleIconOutline,
  },
  mounted() {
    this.fetchDataFromBackend();
  },
  data() {
    return {
      reports: [],
    };
  },
  methods: {
    fetchDataFromBackend() {
      const headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + "/job/PDFReportList/" + this.currentJobId, {
          headers,
        })
        .then((response) => {
          this.reports = [...response.data];
        })
        .catch((error) => console.log(error));
    },
    onLiked(reportDetails) {
      const headers = this.authorizationHeader;
      let body = {
        isLiked: !JSON.parse(reportDetails.isLiked),
        isQuestionable: false,
        id: reportDetails.id,
        reportName: reportDetails.reportName,
        subjectMatterRecruiter: this.subjectMatterContact,
        companyName: this.companyName,
        jobName: this.$store.getters.currentJobName,
      };

      axios
        .put(this.backendUrl + "/job/ReportLiked", body, {
          headers,
        })
        .then(() => {
          this.fetchDataFromBackend();
        })
        .catch((error) => console.log(error));
    },
    onQuestionMark(reportDetails) {
      const headers = this.authorizationHeader;
      let body = {
        isQuestionable: !JSON.parse(reportDetails.isQuestionable),
        isLiked: false,
        id: reportDetails.id,
        reportName: reportDetails.reportName,
        subjectMatterRecruiter: this.subjectMatterContact,
        companyName: this.companyName,
        jobName: this.$store.getters.currentJobName,
      };
      axios
        .put(this.backendUrl + "/job/ReportOnQuestion", body, {
          headers,
        })
        .then(() => {
          this.fetchDataFromBackend();
        })
        .catch((error) => console.log(error));
    },
    onDownload(reportDetails) {
      const headers = this.authorizationHeader;
      axios
        .get(
          this.backendUrl +
            "/job/PDFReportDownload/" +
            reportDetails.reportName,
          {
            headers,
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          // Create a Blob from the response data
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          // Create a temporary URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element and trigger a download
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", reportDetails.reportName); // Specify the file name and extension
          document.body.appendChild(link);
          link.click();

          // Clean up resources
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.log(error));
    },
  },
  computed: {
    ...mapGetters([
      "currentJobId",
      "authorizationHeader",
      "backendUrl",
      "isRoleClient",
    ]),

    subjectMatterContact() {
      let subjectMatterRecruiter = jobDataInCache(
        this.currentJobId,
        "GeneralProjectInformation"
      )[8]["value"];

      return subjectMatterRecruiter;
    },
    companyName() {
      let companyInformation = companyDataInCache(
        this.$route.params.compId,
        "companyInformation"
      );
      return companyInformation.name;
    },
  },
};
</script>
