<template>
  <app-header />
  <!-- empty div if user is not admin  -->
  <div v-if="this.roleName === 'ROLE_CLIENT'"></div>

  <skeleton-client-overview v-else-if="loading" />
  <div class="m-10" v-else>
    <div class="flex flex-row justify-between my-4">
      <div class="flex items-end ml-4">
        <p class="text-2xl font-bold">
          {{ $t("clients-page.current-clients") }}
        </p>
      </div>

      <div class="flex flex-row justify-center items-center">
        <button
          v-if="isAdmin"
          class="bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md hover:bg-[#2197a7]"
          @click="onNewCompanyPopup"
        >
          <span class="text-white">+ {{ $t("add-new-job.add-company") }}</span>
        </button>
        <button
          v-if="isRoleInsideCompany"
          class="bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md hover:bg-[#2197a7]"
          @click="onAllJobsOpen"
        >
          <span class="text-white">{{ $t("job-clustering.all-jobs") }}</span>
        </button>
        <search-bar :dispatch="dispatchActive" :original="activeCompanies" />
        <sort-component
          @sortAz="sortAzActive"
          @sortZa="sortZaActive"
          @sortFav="sortFavActive"
          :isFilterActive="isFilterActive"
        />
        <button
          v-if="sortBy !== ''"
          class="bg-transparent text-gray-700 font-semibold py-0 px-2 rounded text-sm m-0"
          @click="onResetSort"
        >
          Reset
        </button>
      </div>
    </div>

    <ul class="flex flex-row flex-wrap justify-start mt-10">
      <li v-for="company in filteredActiveCompanies" :key="company.id">
        <active-company-card
          :loading="loading"
          :company="company"
          :favorites="favorites"
          @selectOrRemoveFavorite="selectOrRemoveFavorite(company.id)"
        />
      </li>
    </ul>

    <div class="divider my-10 mx-2"></div>

    <div class="grid grid-cols-2">
      <div>
        <h2 class="my-5 mx-2 text-2xl font-bold">
          {{ $t("clients-page.archived-clients") }}
        </h2>
      </div>
      <div class="flex justify-end">
        <h2
          @click="onChangeCompanyCategory('Inactive')"
          class="my-5 mx-2 text-md font-bold cursor-pointer text-blue-500 hover:text-blue-900 primary-color-text mr-4"
        >
          {{
            companyCateogryToShow.includes("Inactive")
              ? "- Collapse"
              : "+ Expand"
          }}
        </h2>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="companyCateogryToShow.includes('Inactive')">
        <archived-company-card
          :loading="loading"
          v-for="company in archivedCompanies"
          :key="company.id"
          :company="company"
          :favorites="favorites"
          @selectOrRemoveFavorite="selectOrRemoveFavorite(company.id)"
        />
      </div>
    </transition>

    <div class="divider my-10 mx-2"></div>

    <div class="grid grid-cols-2">
      <div>
        <h2 class="my-5 mx-2 text-2xl font-bold">
          {{ $t("clients-page.pending-clients") }}
        </h2>
      </div>
      <div class="flex justify-end">
        <h2
          @click="onChangeCompanyCategory('Pending')"
          class="my-5 mx-2 text-md font-bold cursor-pointer text-blue-500 hover:text-blue-900 primary-color-text mr-4"
        >
          {{
            companyCateogryToShow.includes("Pending")
              ? "- Collapse"
              : "+ Expand"
          }}
        </h2>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="companyCateogryToShow.includes('Pending')">
        <archived-company-card
          :loading="loading"
          v-for="company in pendingCompanies"
          :key="company.id"
          :company="company"
          :favorites="favorites"
          isPendingClients="true"
          @selectOrRemoveFavorite="selectOrRemoveFavorite(company.id)"
        />
      </div>
    </transition>

    <div class="divider my-10 mx-2"></div>

    <div class="grid grid-cols-2">
      <div>
        <h2 class="my-5 mx-2 text-2xl font-bold">
          {{ $t("clients-page.former-clients") }}
        </h2>
      </div>
      <div class="flex justify-end">
        <h2
          @click="onChangeCompanyCategory('Former')"
          class="my-5 mx-2 text-md font-bold cursor-pointer text-blue-500 hover:text-blue-900 primary-color-text mr-4"
        >
          {{
            companyCateogryToShow.includes("Former") ? "- Collapse" : "+ Expand"
          }}
        </h2>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="companyCateogryToShow.includes('Former')">
        <archived-company-card
          :loading="loading"
          v-for="company in closedCompanies"
          :key="company.id"
          :company="company"
          :favorites="favorites"
          @selectOrRemoveFavorite="selectOrRemoveFavorite(company.id)"
        />
      </div>
    </transition>

    <div>
      <new-company-modal
        @onModalClose="onModalClose"
        v-if="isModal == 'newCompany'"
      ></new-company-modal>
    </div>

    <div>
      <feeback-modal
        @onModalClose="onModalClose"
        v-if="isModal == 'feedbackModal'"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

import AppHeader from "../../components/layout/header/AppHeader.vue";
import SortComponent from "../../components/ui/SortComponent.vue";
import SearchBar from "../../components/ui/SearchBar.vue";
import ActiveCompanyCard from "./ActiveCompanyCard.vue";
import ArchivedCompanyCard from "./ArchivedCompanyCard.vue";
import NewCompanyModal from "../../components/Modals//NewCompanyModal/NewCompanyModal.vue";
import SkeletonClientOverview from "./SkeletonClientOverview.vue";
import {
  storeGeneralDataInCache,
  getGeneralDataInCache,
} from "../../components/helperFunctions/DataCaching";
import feebackModal from "../../components/Modals/FeedbackModal/FeedbackModal.vue";

export default {
  components: {
    AppHeader,
    SortComponent,
    SearchBar,
    ArchivedCompanyCard,
    ActiveCompanyCard,
    NewCompanyModal,
    SkeletonClientOverview,
    feebackModal,
  },
  data() {
    return {
      bg: { background: "white", color: "grey" },
      logo: "logo_transparent.png",
      dispatchActive: "setFilteredActiveCompanies",
      archivedCompanies: [],
      pendingCompanies: [],
      closedCompanies: [],
      favorites: [],
      isFilterActive: false,
      loading: false,
      companyCateogryToShow: [""],
    };
  },
  computed: {
    ...mapGetters([
      "selectedCompanyId",
      "authorizationHeader",
      "backendUrl",
      "sortBy",
      "isAdmin",
      "roleName",
      "isRoleInsideCompany",
      "userList",
      "internalUserList",
      "isSuperAdmin",
    ]),
    ...mapGetters("job", ["isModal"]),

    companies() {
      return this.$store.getters.companies;
    },
    activeCompanies() {
      return this.$store.getters.activeCompanies;
    },
    filteredActiveCompanies() {
      return this.$store.getters.filteredActiveCompanies;
    },
  },
  watch: {
    companies(newValue, oldValue) {
      if (newValue.length !== oldValue.length) this.sortCompaniesWithStatus();
    },
  },
  methods: {
    ...mapActions("job", ["setIsModal"]),
    ...mapActions(["resetPendingApiCount", "getAllUserList"]),

    onChangeCompanyCategory(categoryName) {
      if (this.companyCateogryToShow.includes(categoryName)) {
        const index = this.companyCateogryToShow.indexOf(categoryName);
        if (index > -1) {
          this.companyCateogryToShow.splice(index, 1);
        }
      } else {
        this.companyCateogryToShow = [
          ...this.companyCateogryToShow,
          categoryName,
        ];
      }
    },
    onModalClose() {
      this.setIsModal("");
    },
    sortAzActive() {
      let removeMultiple = this.activeCompanies.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      this.$store.dispatch("sortDataAz", removeMultiple);
      this.isFilterActive = true;
    },
    sortZaActive() {
      let removeMultiple = this.activeCompanies.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      this.$store.dispatch("sortDataZa", removeMultiple);
      this.isFilterActive = true;
    },
    sortFavActive() {
      this.$store.dispatch("sortDataFavCompanies", {
        companies: this.filteredActiveCompanies,
        favorites: this.favorites,
      });
      this.isFilterActive = true;
    },
    onResetSort() {
      let removeMultiple = this.activeCompanies.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      this.$store.dispatch("setFilteredActiveCompanies", removeMultiple);
      this.$store.dispatch("setActiveCompanies", removeMultiple);
      this.$store.dispatch("setSortBy", "");
      this.isFilterActive = false;
    },
    selectOrRemoveFavorite(company) {
      this.addAndRemoveFavoriteToList(company);
    },

    addAndRemoveFavoriteToList(id) {
      let headers = this.authorizationHeader;
      const newFavorite = {
        favorite_company_id: id,
      };
      axios
        .post(this.backendUrl + `/company/favorites`, newFavorite, { headers })
        .then(() => {
          this.getFavorites();
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    getFavorites() {
      let headers = this.authorizationHeader;
      axios
        .get(this.backendUrl + "/company/favorites", {
          headers,
        })
        .then((response) => {
          this.preProcessOfFavoriteCompanies(response.data.favorite_company_id);
          storeGeneralDataInCache(
            "favoriteCompaniesCache",
            response.data.favorite_company_id
          );
        })
        .catch((error) => console.log(error));
    },
    preProcessOfFavoriteCompanies(data) {
      this.favorites = data;
      this.showFavoritesBefore(this.filteredActiveCompanies, true);
      this.showFavoritesBefore(this.archivedCompanies, false);
      this.loading = false;
    },
    showFavoritesBefore(list, boolean) {
      let favComp = [];
      let favValues = Object.values(this.favorites);
      let listCompanies = list;
      list.forEach((comp) => {
        if (favValues.filter((e) => e == comp.id).length > 0) {
          favComp.unshift(comp);
        }
      });

      favComp.map((c) => {
        listCompanies.unshift(c);
      });
      if (boolean) {
        let reOrded = listCompanies.filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.place === value.place && t.id === value.id)
        );

        this.$store.dispatch("setFilteredActiveCompanies", reOrded);
      } else {
        this.archivedCompanies = listCompanies.filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.place === value.place && t.id === value.id)
        );
      }
      this.sortData();
    },
    sortData() {
      if (this.sortBy == "Favorites") {
        this.sortFavActive();
      } else if (this.sortBy == "A-Z") {
        this.sortAzActive();
      } else if (this.sortBy == "Z-A") {
        this.sortZaActive();
      }
    },
    onNewCompanyPopup() {
      this.setIsModal("newCompany");
    },
    onAllJobsOpen() {
      this.$router.push(`/jobsList`);
    },
    sortCompaniesWithStatus() {
      const companies = this.companies;
      companies.sort((a, b) => a.name.localeCompare(b.name));
      this.$store.dispatch("setAllCompany", companies);

      const activeCompanies = companies.filter(
        (company) => company.status == "active"
      );
      const archivedCompanies = companies.filter(
        (company) => company.status == "archived"
      );
      const pendingCompanies = companies.filter(
        (company) => company.status == "pending"
      );
      const closedCompanies = companies.filter(
        (company) => company.status == "closed"
      );

      this.$store.dispatch("setFilteredActiveCompanies", activeCompanies);
      this.$store.dispatch("setActiveCompanies", activeCompanies);
      this.archivedCompanies = [...archivedCompanies];
      this.pendingCompanies = [...pendingCompanies];
      this.closedCompanies = [...closedCompanies];

      if (getGeneralDataInCache("favoriteCompaniesCache")) {
        this.preProcessOfFavoriteCompanies(
          getGeneralDataInCache("favoriteCompaniesCache")
        );
      } else {
        this.getFavorites();
      }
    },

    // method to fetch all the user list and save it in store (only for super admin users)
    userListFromBackend() {
      if (this.isSuperAdmin && !getGeneralDataInCache("userList")) {
        this.getAllUserList();
      }
    },
  },

  mounted() {
    // time out is imp because in login.vue router url is changed even before login api returns it data compeletely
    // without timeout isAdmin is undefined because login api is not compeleted
    setTimeout(() => {
      this.userListFromBackend();

      // redirect to clients company directly without showing the list of all companies page
      if (
        JSON.parse(localStorage.getItem("user"))["roleName"] == "ROLE_CLIENT"
      ) {
        let headers = this.authorizationHeader;
        axios
          .get(this.backendUrl + "/company/favorites", {
            headers,
          })
          .then((response) => {
            this.$router.push(
              `/clients/${response.data.favorite_company_id[0]}`
            );
          });
      } else {
        // if company is already on cache no need to set loader to true
        this.loading = this.companies.length > 0 ? false : true;
        setTimeout(() => {
          this.sortCompaniesWithStatus();
        }, 500);
      }
    }, 500);
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
