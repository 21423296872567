<template>
  <div>
    <div class="w-48 mt-3">
      <toggle-button
        @onToggleChange="onToggle"
        labelRightText="Use Current Company"
        :isChecked="isExistingCompany"
      />
    </div>

    <div v-if="!this.isExistingCompany">
      <company-information-step
        @onModalClose="$emit('onModalClose')"
        @onNextStep="onNextStep"
      />
    </div>

    <div v-else>
      <div>
        <div class="w-full mt-3 ml-1">
          <!-- <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            {{ $t("add-new-job.company-name") }} *
          </label>
          <input
            :value="jobDetails.company_name"
            disabled
            class="appearance-none block w-full bg-gray-200 text-gray-700 rounded mb-3 focus:outline-none focus:bg-white shadow-md"
            type="text"
          />

          <ul class="dropdown" v-if="companyList.length > 0">
            <li
              v-for="companyName in companyList"
              :key="companyName.id"
              @click="onChangeCompany(companyName)"
            >
              {{ companyName.name }}
            </li>
          </ul> -->

          <div class="tag-input-container">
            <input type="text" :value="jobDetails.company_name" />
            <ul class="dropdown">
              <li>
                <input type="text" v-model="searchedCompanyText" />
              </li>
              <li
                v-for="companyName in companyData"
                :key="companyName.id"
                @click="onChangeCompany(companyName)"
              >
                {{ companyName.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="flex flex-1 flex-row w-full justify-end items-end mt-4">
        <modal-footer-button
          @onModalClose="$emit('onModalClose')"
          @onSubmit="onNextStep"
          buttonText="Next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ModalFooterButton from "../../ui/ModalFooterButton.vue";
import toggleButton from "../../ui/ToggleButton.vue";
import CompanyInformationStep from "../NewCompanyModal/CompanyInformationStep.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    toggleButton,
    CompanyInformationStep,
    ModalFooterButton,
  },
  props: ["jobDetails"],
  data() {
    return {
      isExistingCompany: true,
      searchedCompanyText: "",
      companyList: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl"]),

    companyData() {
      const companyName = this.jobDetails.company_name.toLowerCase();
      // Perform filtering based on the current job details and search text using Array.filter().
      return this.companyList.filter(
        (value) =>
          value.name !== companyName &&
          // If there is a search text, perform case-insensitive filtering based on the company name and the search text.
          (this.searchedCompanyText !== ""
            ? value.name
                .toLowerCase()
                .includes(this.searchedCompanyText.toLowerCase())
            : true)
      );
    },
  },
  methods: {
    onNextStep(data) {
      // if user creates a new company
      if (data) {
        this.$emit("onNext", data);
        // this.$store.dispatch("resetCompanyVariables");

        // const headers = this.authorizationHeader;
        // axios
        //   .get(this.backendUrl + "/company/all-companies", { headers })
        //   .then((response) => {
        //     this.$store.dispatch("setAllCompany", response.data);
        //     sessionStorage.setItem("store", JSON.stringify(this.$store.state));

        //   })
        //   .catch((error) => console.log(error));
      } else {
        let parameters = {
          newCompanyName: this.jobDetails.company_name,
          newCompanyId: this.jobDetails.company_id,
        };
        this.$emit("onNext", parameters);
      }
    },
    onToggle() {
      this.isExistingCompany = !this.isExistingCompany;
    },
    onChangeCompany(company) {
      this.$emit("onCompayChange", company);
    },
  },
  mounted() {
    this.companyList = this.$store.getters.companies;
  },
};
</script>

<style scoped>
.tag-input-container {
  display: inline-block;
  position: relative;
}

input[type="text"] {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 70%;
}

/* Styles for the dropdown container */
.dropdown {
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 70%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease;

  /* Hide the default scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* WebKit (Chrome, Safari) custom scrollbar styles */
.dropdown::-webkit-scrollbar {
  width: 8px;
}

.dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dropdown::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Styles for the dropdown items */
.dropdown li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Hover effect for dropdown items */
.dropdown li:hover {
  background-color: #f2f2f2;
}
</style>
