<template>
  <div class="flex items-center justify-center h-screen">
    <div class="text-center">
      <img
        class="mx-auto transform scale-50 content-center"
        src="../assets/eyes-emoji.png"
      />
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        {{ $t("notfound-page.header-message") }}
      </h3>
      <p v-if="isLoggedIn">
        {{ $t("notfound-page.loggedin-message") }}
        <router-link
          class="font-semibold text-blue-800 hover:underline"
          to="/"
          >{{ $t("notfound-page.dashboard") }}</router-link
        >.
      </p>
      <p v-else>
        {{ $t("notfound-page.no-loggedin-message") }}
        <router-link
          class="font-semibold text-blue-800 hover:underline"
          to="/"
          >{{ $t("notfound-page.login") }}</router-link
        >
        {{ $t("notfound-page.check-dashboard-message") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
};
</script>
