export default {
  company(state) {
    return state.company;
  },
  currentJobId(state) {
    return state.job.id;
  },
  currentJobName(state) {
    return state.job.name;
  },
  selectedCompanyId(state) {
    return state.company.id;
  },
  selectedCompanyName(state) {
    return state.company.name;
  },
  selectedJobDesignation(state) {
    return state.job.name + " " + state.job.kookuId;
  },
  companies(state) {
    return state.companies;
  },
  allJobs(state) {
    return state.allJobs;
  },
  filteredActiveJobs(state) {
    return state.filteredActiveJobs;
  },
  allEndedJobs(state) {
    return state.allEndedJobs;
  },
  activeJobs(state) {
    return state.activeJobs;
  },
  activeCompanies(state) {
    return state.activeCompanies;
  },
  filteredActiveCompanies(state) {
    return state.filteredActiveCompanies;
  },
  keywords(state) {
    return state.keywords;
  },
  sortBy(state) {
    return state.sortBy;
  },
  pendingApisCount(state) {
    return state.pendingApisCount;
  },
  storeState() {
    return JSON.parse(sessionStorage.getItem("store"));
  },
};
