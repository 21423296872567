export default {
  setAllJobList(state, payload) {
    state.allJobList = [...payload];
  },
  setAllTags(state, payload) {
    state.allTags = [...payload];
  },
  jobHashtagsChange(state, payload) {
    const index = state.allJobList.findIndex(
      (value) => value.job_id === payload.jobId
    );
    let tempAllJobs = [...state.allJobList];
    if (index !== -1) {
      tempAllJobs[index].job_tags = payload.hashtags;
      tempAllJobs[index].tag_category = payload.hashtagCategory;
      state.allJobList = [...tempAllJobs];
    }
  },

  addHashtag(state, payload) {
    let { hashtag } = payload;
    state.allTags.push(hashtag);
  },
  updateHashtag(state, payload) {
    let data = payload[0];
    const tagIndex = state.allTags.findIndex(
      (tag) => tag.name === data.old_tag_name
    );
    if (tagIndex !== -1) {
      state.allTags[tagIndex] = {
        ...state.allTags[tagIndex],
        name: data.new_tag_name,
        color: data.new_tag_color,
      };
    }
  },
};
