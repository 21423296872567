<template>
  <div
    class="flex justify-center"
    :key="label"
    v-for="(label, index) in rowHeadersBars"
  >
    <div class="flex flex-row w-4/5 mb-1">
      <div class="flex-initial w-2/5 pr-5">
        <p class="text-right">{{ $t(`dashboardProjectStatus.${label}`) }}</p>
      </div>

      <div class="flex flex-1 justify-end">
        <div
          class="flex-initial rounded"
          :style="calculateWidth(false, index)"
          style="box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5)"
        >
          <p class="text-center font-semibold text-white">
            {{ tableDataBars[index][1] }}
          </p>
        </div>
      </div>

      <div class="flex flex-1 justify-start">
        <div
          class="flex-initial rounded"
          :style="calculateWidth(true, index)"
          style="box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5)"
        >
          <p class="text-center font-semibold text-white">
            {{ barValue[index][1] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["barValue", "rowHeadersBars", "tableDataBars"],
  methods: {
    calculateWidth(isCalcColor, index) {
      let percentage = (this.barValue[index][1] / this.barValue[0][1]) * 100;
      let width = `width:${percentage > 90 ? percentage : percentage + 5}%`;
      let bgColor = ``;

      if (isCalcColor) {
        bgColor =
          parseInt(this.barValue[index][1]) >
          parseInt(this.tableDataBars[index][1])
            ? "background-color:#f87171"
            : parseInt(this.barValue[index][1]) <
              parseInt(this.tableDataBars[index][1])
            ? "background-color:#4ade80"
            : "background-color:#4b5563";
      } else {
        bgColor = `background-color:#4b5563`;
      }
      return `${width}; ${bgColor}`;
    },
  },
};
</script>
