export default {
  isOpen(state) {
    return state.open;
  },
  atsDialog(state) {
    return state.atsDialog;
  },
  modalSubmitData(state) {
    return state.modalSubmitData;
  },
  toggleLoading(state) {
    return state.toggleLoading;
  },
  Estimatedtimeexpenditure(state) {
    return state.Estimatedtimeexpenditure;
  },
  EstimateStageConversion(state) {
    return state.EstimateStageConversion;
  },
  GeneralProjectInformation(state) {
    return state.GeneralProjectInformation;
  },
  projectStatusBar(state) {
    return state.projectStatusBar;
  },
  isSidePanelHidden(state) {
    return state.isSidePanelHidden;
  },
  apiToRun(state) {
    return state.apiToRun;
  },
};
