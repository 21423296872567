<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <div class="border-b-2 border-slate-400 pb-3">
        <p class="text-2xl font-semibold">
          {{ $t("projectStatusChanges.project-status") }}
        </p>
      </div>

      <div class="grid grid-cols-4 gap-4 mt-5">
        <div v-for="data in inputData" v-bind:key="data.id">
          <div>
            <label
              :for="data.id"
              class="block text-sm font-medium text-gray-700"
              >{{ $t(`dashboardProjectStatus.${data.label}`) }}</label
            >
            <input
              min="0"
              :type="data.type"
              :name="data.id"
              :id="data.id"
              :value="data.value"
              v-on:input="onValueChange(data, $event.target.value)"
              class="mb-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div class="">
          <div>
            <label
              for="total hours"
              class="block text-sm font-medium text-gray-700"
              >{{
                $t(`dashboardProjectStatus.Gesamt geschätzter Zeitaufwand in h`)
              }}</label
            >
            <input
              type="text"
              name="total hours"
              :value="`${totalHours} h`"
              disabled="true"
              class="mb-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-slate-100"
            />
          </div>
        </div>
      </div>

      <funnel-graph
        v-if="barValue.length > 0 && projectStatus === 'In Planung'"
        :barValue="barValue"
        :rowHeadersBars="rowHeadersBars"
      />
      <pyramid-graph
        v-else-if="barValue.length > 0 && projectStatus !== 'In Planung'"
        :barValue="barValue"
        :rowHeadersBars="rowHeadersBars"
        :tableDataBars="tableDataBars"
      />

      <div class="flex flex-row justify-end items-end mt-10">
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
          @click="$emit('onModalClose')"
          type="button"
        >
          <span>Cancel</span>
        </button>

        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 v"
          @click="onResetValues()"
          type="button"
        >
          <span class="capitalize">Reset</span>
        </button>

        <button
          @click="onSubmit()"
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center shadow-md primary-button"
        >
          <span>Submit Changes</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import ProjectStatusBar from "../ProjectStatusBar.vue";
import axios from "axios";
import FunnelGraph from "../../../../components/Graphs/FunnelGraph.vue";
import { removeCompleteJobDataFromCache } from "../../../../components/helperFunctions/DataCaching";
import PyramidGraph from "../../../../components/Graphs/PyramidGraph.vue";
import calculateHours from "../../../../components/helperFunctions/CalculateProjectHours";
import calculateProjectSettingBarValues from "../../../../components/helperFunctions/CalculateProjectSettingBarValues";
import * as constants from "../../../../constant/Constants";
// import DateGraficTable from "../DateGraficTable.vue";

export default {
  props: [
    "tableDataBars",
    "totalProjectDays",
    "startDate",
    "endDate",
    "rowHeadersBars",
    "remainingDays",
    "today",
  ],
  data() {
    return {
      inputData: [],
      barPercent: 28,
      barlength: null,
      rowHeaders: [],
      barValue: [],
      totalHours: 0,
    };
  },
  methods: {
    onSubmit() {
      let body = { job_id: this.$route.params.jobId };
      this.inputData.forEach((ele) => (body[ele.id] = ele.value));
      const headers = this.authorizationHeader;
      axios
        .put(
          `${this.backendUrl}/job/estimated-stage-conversion-rates?jobId=${this.$route.params.jobId}`,
          body,
          { headers }
        )
        .then(() => {
          removeCompleteJobDataFromCache(this.$route.params.jobId);
          this.$router.go();
        })
        .catch((error) => console.log(error));
    },
    onValueChange(data, value) {
      //change value of inputData
      let index = this.inputData.findIndex(
        (singleObject) => singleObject.id == data.id
      );
      this.inputData[index] = { ...data, value: parseInt(value) };
      this.calculateBarValues();
    },
    setLabelBar(rowValue) {
      return `${rowValue}`;
    },
    calculateBarValues() {
      this.barValue = calculateProjectSettingBarValues(
        this.inputData,
        this.barValue,
        this.numberOfOpenings
      );
      this.onCalculateTotalHours();
    },
    onResetValues() {
      this.inputData.map((singleValue, index) => {
        this.inputData[index] = {
          ...this.inputData[index],
          value: constants[singleValue.id.toUpperCase()],
        };
      });
      this.calculateBarValues();
    },
    onCalculateTotalHours() {
      // logic to create an object with keys as the helper funton expects an object with respected keys of bar values
      let barValueWithKey = {};
      this.rowHeaders.map((objectKey, index) => {
        objectKey = objectKey.replaceAll(" ", "_");
        barValueWithKey[objectKey] = parseInt(this.barValue[index][1]);
      });

      // calculate total hours of a project
      let hours = calculateHours(
        this.Estimatedtimeexpenditure,
        this.EstimateStageConversion[0].value,
        barValueWithKey
      );
      this.totalHours = isNaN(hours) ? 0 : hours;
    },
  },
  beforeMount() {
    // doing this hack to prevent store values to be changed
    this.barValue = JSON.parse(JSON.stringify([...this.tableDataBars]));
    this.inputData = JSON.parse(
      JSON.stringify([...this.EstimateStageConversion])
    );

    this.rowHeaders = [
      "Kandidaten gesamt recherchiert",
      "Kandidaten kontaktiert",
      "Kandidaten mit Rückmeldung",
      "Interessiert",
      "Telefoninterviews",
      "Vorstellung beim Kunden",
      "Persönliches Gespräch Kunde",
      "Vertragsangebot",
      "Einstellung",
    ];

    this.onCalculateTotalHours();
  },
  computed: {
    ...mapGetters([
      "EstimateStageConversion",
      "GeneralProjectInformation",
      "Estimatedtimeexpenditure",
      "backendUrl",
      "authorizationHeader",
    ]),
    numberOfOpenings() {
      let openings = this.GeneralProjectInformation.filter(
        (value) => value.id == "num_openings"
      );
      return parseInt(openings[0].value);
    },
    projectStatus() {
      let status = this.GeneralProjectInformation.filter(
        (value) => value.id == "project_status"
      );
      return status[0].value;
    },
  },
  components: {
    // ProjectStatusBar,
    FunnelGraph,
    PyramidGraph,
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 3% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 90%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
