<template>
  <select
    v-model="$i18n.locale"
    :onChange="saveLocalInLocalStorage"
    class="h-10 rounded-lg mt-4 smallScreenMargin ml-2 border-none text-sm py-2"
  >
    <option :value="locale.code" v-for="locale in locales" :key="locale.code">
      {{ `${locale.name}  ${locale.flag}` }}
    </option>
  </select>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LocaleSwitcher",
  data() {
    return {
      locales: [
        {
          code: "de",
          name: "Deutsch",
          flag: "🇩🇪",
        },
        {
          code: "en",
          name: "English",
          flag: "🇺🇸",
        },
      ],
    };
  },
  methods: {
    saveLocalInLocalStorage(value) {
      sessionStorage.setItem("langLocal", value.target.value);
    },
  },
});
</script>
<style scoped>
@media only screen and (max-width: 924px) {
  .smallScreenMargin {
    margin-left: 20px;
  }
}
</style>
