export default {
  setJob(state, payload) {
    state.job.id = payload.newJobId;
    state.job.name = payload.newJobName;
    state.job.kookuId = payload.newKookuId;
  },
  setCompany(state, payload) {
    state.company.id = payload.newCompanyId;
    state.company.name = payload.newCompanyName;
  },
  setAllJobs(state, payload) {
    state.allJobs = payload;
  },
  setFilteredActiveJobs(state, payload) {
    state.filteredActiveJobs = payload;
  },
  setAllEndedJobs(state, payload) {
    state.allEndedJobs = payload;
  },
  sortDataAz(state, payload) {
    let filteredData = payload.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
    state.filteredActiveCompanies = filteredData;
    state.sortBy = "A-Z";
  },
  sortDataZa(state, payload) {
    let filteredData = payload.sort(function (a, b) {
      return b.name.toLowerCase().localeCompare(a.name.toLowerCase());
    });
    state.filteredActiveCompanies = filteredData;
    state.sortBy = "Z-A";
  },
  sortDataFavCompanies(state, payload) {
    const filteredData = payload.companies.filter((company) =>
      Object.values(payload.favorites).includes(JSON.parse(company.id))
    );
    state.filteredActiveCompanies = filteredData;
    state.sortBy = "Favorites";
  },
  sortDataFavJobs(state, payload) {
    const filteredData = payload.jobs.filter((company) =>
      Object.values(payload.favorites).includes(JSON.parse(company.id))
    );
    state.filteredActiveJobs = filteredData;
  },
  sortDataAzJobs(state, payload) {
    let filteredData = payload.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
    state.filteredActiveJobs = filteredData;
  },
  sortDataZaJobs(state, payload) {
    let filteredData = payload.sort(function (a, b) {
      return b.name.toLowerCase().localeCompare(a.name.toLowerCase());
    });
    state.filteredActiveJobs = filteredData;
  },
  setActiveJobs(state, payload) {
    state.activeJobs = payload;
  },
  resetJobVariables(state) {
    state.filteredActiveJobs = [];
    state.allEndedJobs = [];
    state.activeJobs = [];
  },
  setAllCompany(state, payload) {
    if (state.companies !== []) {
      state.companies = [...payload];
    }
  },
  setActiveCompanies(state, payload) {
    if (state.activeCompanies !== []) {
      state.activeCompanies = payload;
    }
  },
  setFilteredActiveCompanies(state, payload) {
    if (state.filteredActiveCompanies !== []) {
      state.filteredActiveCompanies = payload;
    }
  },
  resetCompanyVariables(state) {
    state.filteredActiveCompanies = [];
    state.activeCompanies = [];
    state.companies = [];
  },
  setKeywords(state, payload) {
    state.keywords = payload;
  },
  setSortBy(state, payload) {
    state.sortBy = payload;
  },
  setIncreasePendingApisCount(state) {
    state.pendingApisCount++;
  },
  setDecreasePendingApisCount(state) {
    state.pendingApisCount--;
  },
  resetPendingApiCount(state, payload) {
    state.pendingApisCount = payload;
  },
};
