<template>
  <!-- The Modal -->
  <div id="myModal" class="modal-body">
    <!-- Modal content -->
    <div class="modal-content">
      <div id="wrapper"></div>

      <div class="flex flex-1 flex-row w-full justify-end items-center">
        <button
          class="bg-white py-2 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md my-5 mr-2"
          @click="$emit('onModalClose', true)"
          type="button"
        >
          <span>Close</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";

export default {
  mounted() {
    // widget of the feedback, if want to change the feedback questions just change the url from typeform and place it in env variable
    createWidget(process.env.VUE_APP_TYPEFORM_URL, {
      container: document.getElementById("wrapper"),
      height: 700,
      hideHeaders: true,
      onSubmit: () => {
        this.$emit("onModalClose");
      },
      onClose: () => {
        this.$emit("onModalClose");
      },
    });
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #0f1c2d;
  margin: 4% auto; /*  from the top and centered */
  padding: 5px;
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
