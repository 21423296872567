<template>
  <div>
    <label class="cursor-pointer label">
      <span v-if="labelLeftText" class="label-text w-0">{{
        labelLeftText ?? ""
      }}</span>
      <input
        type="checkbox"
        id="toggle"
        class="toggle-input"
        :checked="isChecked"
        @change="onToggle"
      />
      <div class="toggle-button" @click="toggle"></div>
      <span v-if="labelRightText" class="label-text ml-1">{{
        labelRightText ?? ""
      }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: ["isChecked", "labelLeftText", "labelRightText"],
  methods: {
    onToggle(value) {
      this.$emit("onToggleChange", value);
    },
  },
};
</script>

<style scoped>
[type="checkbox"]:checked {
  background-image: none !important;
}

.toggle-input {
  display: none;
}

.toggle-button {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}

.toggle-button:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-input:checked + .toggle-button {
  background-color: #4caf50;
}

.toggle-input:checked + .toggle-button:before {
  transform: translateX(20px);
}
</style>
