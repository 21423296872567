<template>
  <div
    class="flex items-center justify-center border-2 rounded-2xl h-full shadow-md"
  >
    <div class="flex">
      <SearchIcon class="w-4 h-4 my-auto mx-1" />

      <input
        type="text"
        class="px-4 py-1 w-40 border-none !rounded-2xl"
        v-model="searched"
        :placeholder="$t('clients-page.search-placeholder')"
      />
    </div>
  </div>
</template>

<script>
import { SearchIcon } from "@heroicons/vue/solid";

export default {
  props: ["original", "dispatch"],

  components: {
    SearchIcon,
  },

  data() {
    return {
      searched: "",

      filteredArray: [],
    };
  },

  watch: {
    filteredArray(newVal) {
      this.$store.dispatch(this.dispatch, newVal);
    },

    searched() {
      this.searchCompanyName();
    },
  },

  methods: {
    searchCompanyName() {
      if (this.original !== undefined) {
        this.filteredArray = this.original.filter((job) => {
          return job.name.toLowerCase().includes(this.searched.toLowerCase());
        });
      }
    },
  },
};
</script>
l
