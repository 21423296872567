<template>
  <Listbox id="test" as="div" v-model="selected">
    <div
      @click="showListJobs"
      class="flex flex-row border-2 w-56 mt-1 md:max-w-xs rounded-2xl border-gray-300 p-2 ml-2 sm:text-sm align-middle smallScreenSelectWidth widthSelectorBetweenMdLg"
    >
      <div class="mr-2 truncate w-5/6">
        <p
          v-if="
            filteredActiveJobs.length > 0 && filteredActiveJobs !== undefined
          "
          id="selectedItem"
        >
          {{ $t("client-details-page.all-vacancies") }}
        </p>
        <p v-else id="selectedItem">
          {{ $t("client-details-page.no-vacancies") }}
        </p>
      </div>

      <SelectorIcon class="h-5 text-gray-400 w-1/6" aria-hidden="true" />
    </div>
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ul
        v-if="showList"
        class="absolute z-6 ml-2 mt-1 w-56 smallScreenSelectWidth widthSelectorBetweenMdLg bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      >
        <div class="flex justify-center mb-3">
          <input
            class="appearance-none block text-gray-700 focus:outline-none focus:bg-white border p-1 w-48 rounded-md"
            placeholder="Search Job"
            @input="onJobFilter($event)"
            id="jobSearch"
          />
        </div>
        <li
          @click="chosedJob(job)"
          v-for="job in filterJobs"
          :key="job"
          class="sm:text-xs p-2 flex flex-row"
        >
          {{ job.name }}
          <CheckIcon
            v-if="active_el == job.id"
            class="h-5 ml-1 w-1/5"
            aria-hidden="true"
          />
        </li>
      </ul>
    </transition>
  </Listbox>
</template>

<script>
import { mapGetters } from "vuex";

import { Listbox } from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Listbox,
    SelectorIcon,
    CheckIcon,
  },
  data() {
    return {
      jobs: [],
      filterJobs: this.$store.getters.filteredActiveJobs,
      selected: "",
      showList: false,
      active_el: null,
    };
  },
  mounted() {
    //  logic to close job list from header, check click event and if it is not occur from dropdown then close it else dont do anything
    document.body.addEventListener(
      "click",
      (event) => {
        if (!["selectedItem", "jobSearch"].includes(event.target.id)) {
          this.filterJobs = [...this.filteredActiveJobs];

          this.showList = this.showList && false;
        }
      },
      true
    );
  },
  watch: {
    selected(newSelected) {
      this.$store.dispatch("setJob", {
        newJobId: newSelected.id,
        newJobName: newSelected.name,
        newKookuId: newSelected.kookuId,
      });
    },
  },
  computed: {
    ...mapGetters(["selectedCompanyId", "authorizationHeader", "backendUrl"]),
    filteredActiveJobs() {
      return this.$store.getters.filteredActiveJobs;
    },
  },

  methods: {
    onJobFilter(value) {
      this.filterJobs = this.filteredActiveJobs.filter((job) =>
        job.name.toLowerCase().includes(value.target.value.toLowerCase())
      );
    },
    showListJobs() {
      this.filterJobs = [...this.filteredActiveJobs];
      this.showList = this.showList == false && true;
    },
    chosedJob(newSelected) {
      this.$store.dispatch("setJob", {
        newJobId: newSelected.id,
        newJobName: newSelected.name,
        newKookuId: newSelected.kookuId,
      });
      this.selected = newSelected;

      this.showList = false;
      this.$router.push(
        `/dashboardjob/${this.selectedCompanyId}/${newSelected.id}`
      );
    },
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1024px) and (max-width: 1035px) {
  .widthSelectorBetweenMdLg {
    max-width: 350px;
  }
}

@media only screen and (max-width: 349px) {
  .smallScreenSelectWidth {
    width: 12rem;
    max-width: 18rem;
  }
}
ul > li:hover {
  cursor: pointer;
}
.active {
  color: white;
  font-weight: bold;
  background-color: rgb(79 70 229);
}
</style>
