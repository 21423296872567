<template>
  <div
    class="h-16 bg-white hover:bg-gray-300 p-3 pt-4 mx-2 my-2 shadow-xl rounded-lg btn-ghost border border-gray-400"
  >
    <div class="flex flex-row justify-between items-center h-full">
      <div class="flex flex-row w-2/4 items-center">
        <favorite-button :element="job" :favorites="favorites" />

        <p
          class="ml-2 text-xl"
          :class="$store.state.auth.isPresentationMode ? 'blur-effect' : null"
        >
          {{ job.name }}
        </p>
      </div>
      <p class="text-xs primary-color-text">
        Status: {{ $t("client-details-page.completed") }}
      </p>
      <div>
        <button :class="detailsButtonClasses" @click="goToDashboard">
          {{ $t("client-details-page.details") }}
          <ChevronRightIcon class="w-5 h-5" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/solid";
import FavoriteButton from "../../components/ui/FavoriteButton.vue";
import { detailsButton } from "../../components/helperFunctions/UIClasses";
export default {
  props: ["job", "selectedCompanyId", "favorites"],
  components: {
    ChevronRightIcon,
    FavoriteButton,
  },
  methods: {
    goToDashboard() {
      this.$store.dispatch("setJob", {
        newJobId: this.job.id,
        newJobName: this.job.name,
        newKookuId: this.job.kookuId,
      });
      this.$router.push(
        `/dashboardJob/${this.selectedCompanyId}/${this.job.id}`
      );
    },
  },
  computed: {
    detailsButtonClasses() {
      return detailsButton();
    },
  },
};
</script>
