<template>
  <div>
    <div
      class="flex flex-1 items-center h-full cursor-pointer justify-center"
      @click="$emit('onHashtagClick')"
    >
      <div v-if="hashtags.length > 0">
        <div
          v-for="(category, index) in categories"
          :key="index"
          class="badge rounded-md ml-1 pt-0.5 pb-0.5"
          :style="`border:${allHashtagLevels[category]}; background-color: ${allHashtagLevels[category]}; height: auto;`"
        >
          <p class="text-xs">{{ hashtags[index] }}</p>
        </div>
      </div>
      <div
        v-else
        class="badge rounded-md border-[#2197a7] bg-[#2197a7] hover:bg-[#186c77] ml-1"
      >
        <p class="text-xs">Add Tag</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["hashtags", "categories"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "levelsHexColors",
      "companyLevelsHexColors",
      "allHashtagLevels",
    ]),
  },
  watch: {},
  methods: {},
};
</script>
