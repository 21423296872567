<template>
  <skeleton-table-widget v-if="loading"></skeleton-table-widget>

  <div v-else class="flex flex-col" style="-webkit-print-color-adjust: exact">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <!-- <label class="block text-base font-bold text-gray-700">
          {{ name }}
        </label> -->
        <label
          class="block font-bold text-gray-700 mb-2"
          :class="[
            isLargeItems
              ? 'border-b-4 border-gray-500 mb-5  text-center pb-1'
              : '',
            ongoingPrint ? 'text-2xl' : 'text-base',
          ]"
        >
          {{
            $t(
              `report-pdf.${
                path.includes("facto-comparison") ? "Projektstatus" : name
              }`
            )
          }}
        </label>
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-sm text-gray-500 uppercase tracking-wider"
                  :class="ongoingPrint ? 'text-lg' : 'text-xs'"
                  v-for="header in columnHeaders"
                  v-bind:key="header"
                >
                  {{
                    header && !header.includes(".")
                      ? $t(`report-pdf.${header}`)
                      : header
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in tableData"
                :key="data"
                :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td
                  class="px-6 py-2 whitespace-nowrap text-black border-2 border-gray-300"
                  :class="ongoingPrint ? 'text-lg' : 'text-xs'"
                  v-if="hasRowHeaders"
                >
                  {{ $t(`report-pdf.${rowHeaders[index]}`) }}
                </td>
                <td
                  class="px-6 py-2 whitespace-nowrap text-black border-2 border-gray-300"
                  :class="[
                    calculateColorOnPercentage(value, dataIndex),
                    ongoingPrint ? 'text-lg' : 'text-xs',
                    path == '/report/time-tracking-date-range'
                      ? 'text-center'
                      : '',
                    $store.state.auth.isPresentationMode &&
                    ['Partner', 'Projektnummer'].includes(rowHeaders[index])
                      ? 'blur-effect'
                      : null,
                  ]"
                  v-for="(value, dataIndex) in data"
                  :key="value"
                >
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import SkeletonTableWidget from "./SkeletonTableWidget.vue";

export default {
  components: {
    SkeletonTableWidget,
  },

  computed: {
    currentJobId() {
      return this.$route.params.jobId;
    },

    ...mapGetters(["authorizationHeader", "backendUrl"]),
  },
  props: ["path", "isLargeItems", "ongoingPrint", "selectedDate"],
  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
  },
  data() {
    return {
      name: "",
      columnHeaders: [],
      rowHeaders: [],
      tableData: [],
      hasRowHeaders: false,
      loading: true,
    };
  },
  methods: {
    ...mapActions([
      "setIncreasePendingApisCount",
      "setDecreasePendingApisCount",
    ]),

    calculateColorOnPercentage(value, index) {
      value = parseInt(value.replaceAll("%", ""));
      if ([2, 3, 4].includes(index) && value >= 80) {
        if (value >= 80 && value < 120) return "bg-green-600/30";

        if (value >= 120 && value < 160) return "bg-green-600/60";

        if (value >= 160) return "bg-green-600/80";
      } else return "";
    },
    fetchDataFromBackend(jobId) {
      this.loading = true;
      const headers = this.authorizationHeader;
      this.setIncreasePendingApisCount();
      axios
        .get(
          this.backendUrl +
            `${this.path}?jobId=${jobId}&days=${this.selectedDate}`,
          {
            headers,
          }
        )
        .then((response) => {
          let responseData = response.data;
          // only for the project status table, remove last column from reponse of percent
          if (responseData.name === "Projektstatus") {
            responseData.column_headers.pop();
            responseData.table_data.map((value) => {
              value.pop();
            });
          }
          // change the date into sentence if current date is smaller than expected end date
          if (this.path == "/report/project-properties") {
            let splitedString = responseData.table_data[4][0].split(".");
            if (
              new Date(
                `${splitedString[1]}.${splitedString[0]}.${splitedString[2]}`
              ) > new Date()
            ) {
              responseData.table_data[4][0] = "Noch offen";
            }
          }
          this.name = responseData.name;
          this.columnHeaders = responseData.column_headers;
          this.tableData = responseData.table_data;
          this.$emit("onResponseToParent", responseData);
          this.loading = false;
          if (
            Object.prototype.hasOwnProperty.call(responseData, "row_headers")
          ) {
            this.hasRowHeaders = true;
            this.rowHeaders = responseData.row_headers;
          }
          this.setDecreasePendingApisCount();
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      this.fetchDataFromBackend(this.currentJobId);
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
};
</script>
