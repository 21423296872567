<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-1 flex-col pl-8 pr-8 mt-10">
    <div class="ml-5 flex flex-1 items-center">
      <p class="italic">
        * Note: Please request users not to use the application, as these APIs
        may take approximately 1-2 minutes to update. You may also need to
        re-login to the application to see the changes reflected.
      </p>
    </div>

    <div
      class="mt-5 flex flex-1 flex-row justify-center"
      v-if="timerText !== ''"
    >
      <div class="mr-5">
        <p class="font-bold">{{ timerText }}</p>
      </div>
      <div>
        <progress class="progress w-56"></progress>
      </div>
    </div>

    <div class="flex flex-1 flex-row mt-8">
      <div>
        <button
          type="button"
          :disabled="timerText !== ''"
          @click="onLoadSince"
          class="h-9 mr-6 w-full rounded-xl text-white bg-gray-400 p-2 flex flex-row items-center justify-center text-base shadow-md primary-button"
        >
          Load Since
        </button>
      </div>
    </div>

    <div class="flex flex-1 flex-row mt-8">
      <div>
        <input
          type="text"
          placeholder="Enter Job ID"
          class="input input-bordered input-primary w-full max-w-xs"
          @keyup="onJobIdInputChange"
        />
      </div>
      <div class="ml-5 flex items-center">
        <button
          type="button"
          :disabled="timerText !== ''"
          @click="onFullJobLoad()"
          class="h-9 mr-6 w-full rounded-xl text-white bg-gray-400 p-2 flex flex-row items-center justify-center text-base shadow-md primary-button"
        >
          Single Job Full Load
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//   import { PlusIcon } from "@heroicons/vue/solid";
import axios from "axios";

export default {
  data() {
    return {
      jobId: "",
      timerText: "",
    };
  },
  methods: {
    onJobIdInputChange(e) {
      this.jobId = e.target.value;
    },
    startTimer() {
      const intervalInSeconds = 120; // 2 minutes in seconds
      let timeRemaining = intervalInSeconds;

      const intervalId = setInterval(() => {
        const minutes = Math.floor(timeRemaining / 60);
        const seconds = timeRemaining % 60;

        // Display the time remaining in a user-friendly format
        this.timerText = `${minutes} minute(s) ${seconds} second(s) remaining`;

        if (timeRemaining <= 0) {
          clearInterval(intervalId);
          this.timerText = "";
          alert("Database Changes Successfully");
        } else {
          timeRemaining--;
        }
      }, 1000); // Update every second
    },
    onLoadSince() {
      this.startTimer();

      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

      const year = threeDaysAgo.getFullYear();
      const month = String(threeDaysAgo.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const day = String(threeDaysAgo.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      let headers = this.authorizationHeader;
      axios.get(this.backendUrl + `/load-since?since=${formattedDate}`, {
        headers,
      });
    },
    onFullJobLoad() {
      if (this.jobId === "") {
        alert("Please Enter Job Id");
      } else {
        this.startTimer();

        let headers = this.authorizationHeader;
        axios.get(this.backendUrl + `/load-job-full?jobId=${this.jobId}`, {
          headers,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["authorizationHeader", "backendUrl"]),
  },
  mounted() {},
  components: {},
};
</script>

<style></style>
