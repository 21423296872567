export default {
  openSidebar(state) {
    state.open = true;
  },
  closeSidebar(state) {
    state.open = false;
  },
  setSelectedView(state, payload) {
    state.selectedView = payload;
  },
  setSelectedViewDashboard(state, payload) {
    state.selectedViewDashboard = payload;
  },
};
