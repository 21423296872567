export default function calculateHours(inputData, barValue, numberOfOpenings) {
  // variables use to calculate each bar
  let index = 0;
  let objectKeys = {
    research_quality: 0,
    reply_rate: 0,
    share_of_positive_replies: 0,
    transfer_to_phone_interview: 0,
    success_rate_phone_interview: 0,
    success_rate_presentation_to_client: 0,
    success_rate_interview_at_client: 0,
    success_rate_contract_negotiation: 0,
  };

  // assign values to each object key from inputData
  Object.keys(objectKeys).map((singleKey) => {
    index = inputData.findIndex((singleObject) => singleObject.id == singleKey);
    objectKeys[singleKey] = inputData[index].value;
  });

  // ======================================================== calculate bar values ====================================================
  // Vertragsangebot
  let aimKPIOffer =
    numberOfOpenings /
    ((objectKeys["success_rate_contract_negotiation"] <= 0
      ? 100
      : objectKeys["success_rate_contract_negotiation"]) /
      100);
  barValue[7][1] = `${Math.round(aimKPIOffer)}`;
  // this calculation is same as of backend so if we need to change the formula we need to change on both ends

  // Persönliches Gespräch Kunde
  let aimKPIInterviewAtClient =
    aimKPIOffer /
    (objectKeys["success_rate_interview_at_client"] <= 0
      ? 100
      : objectKeys["success_rate_interview_at_client"] / 100);
  barValue[6][1] = `${Math.round(aimKPIInterviewAtClient)}`;

  //  beim Kunden vorgestellt gesamt
  let aimKPIPresentationToClient =
    aimKPIInterviewAtClient /
    ((objectKeys["success_rate_presentation_to_client"] <= 0
      ? 100
      : objectKeys["success_rate_presentation_to_client"]) /
      100);
  barValue[5][1] = `${Math.round(aimKPIPresentationToClient)}`;

  // Telefoninterviews
  let aimKPIPhoneInterview =
    aimKPIPresentationToClient /
    ((objectKeys["success_rate_phone_interview"] <= 0
      ? 100
      : objectKeys["success_rate_phone_interview"]) /
      100);
  barValue[4][1] = `${Math.round(aimKPIPhoneInterview)}`;

  // Interessiert
  let aimKPIInterested =
    aimKPIPhoneInterview /
    ((objectKeys["transfer_to_phone_interview"] <= 0
      ? 100
      : objectKeys["transfer_to_phone_interview"]) /
      100);
  barValue[3][1] = `${Math.round(aimKPIInterested)}`;

  // Kandidaten mit Rückmeldung
  let aimKPIWithReplies =
    aimKPIInterested /
    ((objectKeys["share_of_positive_replies"] <= 0
      ? 100
      : objectKeys["share_of_positive_replies"]) /
      100);
  barValue[2][1] = `${Math.round(aimKPIWithReplies)}`;

  // Kandidaten kontaktiert
  let aimKPIContacted =
    aimKPIWithReplies /
    ((objectKeys["reply_rate"] <= 0 ? 100 : objectKeys["reply_rate"]) / 100);
  barValue[1][1] = `${Math.round(aimKPIContacted)}`;

  // Kandidaten gesamt recherchiert
  let aimKPITotallyResearched =
    aimKPIContacted /
    ((objectKeys["research_quality"] <= 0
      ? 100
      : objectKeys["research_quality"]) /
      100);
  barValue[0][1] = `${Math.round(aimKPITotallyResearched)}`;

  return barValue;
}
