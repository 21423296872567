export default {
  setProjectDetails(state, payload) {
    state.projectDetails = payload;
  },
  setTimeFrame(state, payload) {
    state.timeFrame = payload;
  },
  setIsModal(state, payload) {
    state.isModal = payload;
  },
};
