<template>
  <button
    class="h-9 m-2 rounded-2xl bg-gray-400 p-2 flex flex-row items-center justify-center text-base shadow-md hover:bg-[#2197a7]"
    @click="
      () => {
        isModal = true;
      }
    "
  >
    <ClipboardListIcon class="w-5 h-5 text-white mr-1" />PDF
  </button>

  <div id="myModal" class="modal-body" v-if="isModal">
    <!-- Modal content -->
    <div class="modal-content">
      <div>
        <p class="text-2xl font-semibold text-black">
          {{ $t(`report-pdf.modal-heading`) }}
        </p>
      </div>

      <div class="mt-5">
        <p class="mb-2">{{ $t(`report-pdf.modal-message`) }}</p>
        <input
          type="date"
          id="start"
          name="report-start"
          v-model="selectedDate"
          :max="new Date().toISOString().split('T')[0]"
        />
      </div>
      <div class="mt-5 flex justify-end">
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
          @click="
            () => {
              isModal = false;
            }
          "
          type="button"
        >
          <span>Cancel</span>
        </button>
        <button
          @click="onOpenPdf()"
          class="bg-gray-300 hover:bg-gray-400 font-bold py-2 px-4 rounded inline-flex items-center shadow-md primary-button text-white"
        >
          <span>{{ $t(`report-pdf.open pdf`) }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ClipboardListIcon } from "@heroicons/vue/outline";
export default {
  components: {
    ClipboardListIcon,
  },
  computed: {
    ...mapGetters([
      "currentJobId",
      "selectedCompanyName",
      "selectedJobDesignation",
      "currentJobName",
    ]),
    ...mapGetters("job", ["projectDetails"]),
  },
  methods: {
    onOpenPdf() {
      // handling Date(), we are NOT taking care of timeZoneOffsets caused by client browser-settings etc.
      // while it is not necessary rn, it might come in handy when users may select date-ranges
      // and we onboard external customers
      const currentDateAsEndDate = new Date();

      const startDate = new Date(this.selectedDate);
      // setting startDate time to 00:01 on the chosen day
      startDate.setHours(0, 1);

      const millisecondsPerDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.floor(
        (currentDateAsEndDate - startDate) / millisecondsPerDay
      );

      this.isModal = false;
      this.createPdf(diffDays);
    },
    createPdf(reportDays) {
      // Hunzala - comment this below line to stop change the title name of the browser tab
      // document.title =
      //   "Recruiting Report - " +
      //   this.selectedCompanyName +
      //   " - KW" +
      //   this.getWeekNumber(new Date()) +
      //   "- " +
      //   this.selectedJobDesignation;
      let parameterString = `?name=${encodeURIComponent(
        this.currentJobName
      )}&number=${this.projectDetails.Projektnummer}&days=${reportDays}`;
      window.open(
        `/report/${this.$route.params.compId}/${this.$route.params.jobId}${parameterString}`
      );
    },
    getWeekNumber(d) {
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      // Return array of year and week number
      // return [d.getUTCFullYear(), weekNo];
      return String(weekNo);
    },
  },
  data() {
    return {
      selectedDate: this.getDefaultDate,
      isModal: false,
    };
  },
  mounted() {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    this.selectedDate = currentDate.toISOString().split("T")[0];
  },
};
</script>

<style scoped>
@media only screen and (max-width: 924px) {
  .diffPadMargMob {
    margin: 0px;
    padding: 0px;
    color: rgb(75 85 99);
    background: white;
  }
  .diffPadMargMob:hover {
    background-color: rgb(249 250 251);
    color: rgb(17 24 39);
  }
}

/* The Modal (background) */
.modal-body {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 20%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
  color: black;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
