<template>
  <div class="flex flex-row justify-end mr-2">
    <div
      class="w-64 h-72 bg-white rounded-lg pl-5 pt-2 flex flex-col pb-4 mt-5"
    >
      <div class="w-full flex flex-row justify-end mb-2">
        <div class="h-3 w-24 bg-gray-300 mr-1 rounded-lg wave"></div>
      </div>
      <div>
        <div class="h-9 w-28 bg-gray-400 my-3 rounded-lg wave"></div>
        <div class="h-3 w-32 bg-gray-400 my-3 rounded-lg wave"></div>
      </div>

      <div class="h-12 w-24 bg-gray-400 my-3 rounded-lg wave"></div>
      <div class="mt-2 mr-2 h-12 bg-gray-100 my-3 rounded-full w-40 wave"></div>
    </div>
  </div>
</template>
<style scoped>
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
