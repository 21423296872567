<template>
  <skeleton-system-info-widget v-if="loading"></skeleton-system-info-widget>
  <ul v-else id="example-1" class="flex">
    <refresh-job-button
      v-show="messages[0]?.msg || !isRoleClient"
      :data-tip="messages[0]?.msg"
      :message="messages[0]?.msg"
      class="text-white"
      :class="isRoleClient ? '' : 'tooltip'"
    />
  </ul>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import RefreshJobButton from "../layout/RefreshJobButton.vue";
import SkeletonSystemInfoWidget from "./SkeletonSystemInfoWidget.vue";

export default {
  props: ["loadingToggle"],
  components: {
    RefreshJobButton,
    SkeletonSystemInfoWidget,
  },
  computed: {
    ...mapGetters([
      "currentJobId",
      "authorizationHeader",
      "backendUrl",
      "isRoleClient",
    ]),
    toggleLoadingSaveButton() {
      return this.$store.getters.toggleLoading;
    },
  },
  watch: {
    currentJobId(newJobId) {
      this.fetchDataFromBackend(newJobId);
    },
    loadingToggle() {
      if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
    toggleLoadingSaveButton() {
      this.fetchDataFromBackend(this.currentJobId);
    },
  },
  data() {
    return {
      messages: [],
      loading: false,
    };
  },
  methods: {
    fetchDataFromBackend(jobId) {
      this.loading = true;
      const headers = this.authorizationHeader;
      if (jobId !== null) {
        axios
          .get(this.backendUrl + "/widget/system-infos?jobId=" + jobId, {
            headers,
          })
          .then((response) => {
            let responseData = response.data;
            this.messages = responseData;
            this.messages.forEach((msg) => {
              switch (msg.priority) {
                case "NEUTRAL":
                  msg.color = "#2563EB";
                  break;
                case "LOW":
                  msg.color = "#059669";
                  break;
                case "MEDIUM":
                  msg.color = "#D97706";
                  break;
                case "HIGH":
                  msg.color = "#DC2626";
                  break;
              }
            });
            this.loading = false;
          })
          .catch((error) => console.log(error));
      }
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      this.fetchDataFromBackend(this.currentJobId);
    }
  },
};
</script>
