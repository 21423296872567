<template>
  <div class="flex flex-col w-full">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 w-full">
      <div class="py-2 align-middle inline-block sm:px-6 lg:px-8 w-full">
        <div
          class="
            shadow
            overflow-hidden
            border-b border-gray-200
            sm:rounded-lg
            bg-gray-50
          "
        >
          <div
            class="block h-5 bg-gray-400 w-64 ml-8 mb-2 mt-4 rounded-lg wave"
          ></div>
          <div class="mt-2 mb-4">
            <div class="flex flex-row mb-3">
              <div class="w-80 mr-4"></div>
              <div class="h-5 w-10 bg-gray-300 mr-6 px-2 rounded-lg wave"></div>
              <div class="h-5 w-24 bg-gray-300 px-2 rounded-lg wave"></div>
            </div>
            <div
              v-for="num in 8"
              :key="num"
              class="flex flex-row justify-between mr-10 ml-5"
            >
              <div class="p-2">
                <div class="h-5 w-56 bg-gray-300 rounded-lg wave"></div>
              </div>
              <div class="flex flex-row">
                <div class="p-2 border-r-2">
                  <div
                    class="h-5 w-8 bg-gray-300 mr-2 px-2 rounded-lg wave"
                  ></div>
                </div>
                <div class="p-2">
                  <div class="h-5 w-8 bg-gray-300 ml-2 rounded-lg wave"></div>
                </div>
              </div>
              <div class="p-2 border-l-2">
                <div class="w-5 h-5 rounded-full bg-gray-300 wave"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.wave {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 20px 0;
  }
}
</style>
