<template>
  <div>
    <div class="donutContainerExternal">
      <h2 class="text-xl">{{ percentString }}</h2>
      <div class="donutContainer">
        <svg width="240" height="240">
          <g>
            <title>Layer blue</title>
            <circle
              id="circle"
              style="stroke-dashoffset: 0; /* 471 */"
              class="donut"
              r="75"
              cy="120"
              cx="120"
              stroke-width="60"
              stroke="#A9A9A9"
              fill="none"
            />
          </g>
        </svg>
      </div>
      <div class="donutContainer2">
        <svg width="240" height="240">
          <g>
            <title>Layer Pink</title>
            <circle
              id="circle"
              :style="styleSmallCircle"
              class="donut"
              r="75"
              cy="120"
              cx="120"
              stroke-width="60"
              :stroke="percent > 100 ? '#696969' : '#DCDCDC'"
              fill="none"
            />
          </g>
        </svg>
      </div>

      <div class="containerSecBigDonuts">
        <svg width="240" height="240">
          <g>
            <title>Layer red/green</title>
            <circle
              id="circle"
              style="stroke-dashoffset: 0; /*723 */"
              class="donutBigger"
              r="115"
              cy="120"
              cx="120"
              stroke-width="8"
              :stroke="percent < 100 ? colorTimeDonut : 'green'"
              fill="none"
            />
          </g>
          <text
            class="text-sm"
            v-if="chartData.length > 0"
            text-anchor="middle"
            dy="2px"
            dx="5px"
            :x="chartData[0].textX"
            :y="chartData[0].textY"
            :transform="returnCircleTransformValue()"
          >
            {{ showedData[0] }}
          </text>
        </svg>
      </div>
      <svg width="240" height="240">
        <g>
          <title>Layer grey</title>

          <circle
            id="circle"
            :style="styleBigCircle /* 723 */"
            class="donutBigger"
            r="115"
            cy="120"
            cx="120"
            stroke-width="8"
            :stroke="daysColor[1]"
            fill="none"
          />
        </g>
        <text
          class="text-sm"
          v-if="chartData[1] != undefined"
          text-anchor="middle"
          dy="2px"
          dx="5px"
          :x="chartData[1].textX"
          :y="chartData[1].textY"
          :transform="returnCircleTransformValue()"
        >
          {{ showedData[1] }}
        </text>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "days",
    "totalDays",
    "daysColor",
    "actualValue",
    "expectedValue",
    "percentString",
  ],
  data() {
    return {
      turn: 10,
      tableData: [],
      percent: null,
      stroke: "",
      difference: 0,
      colorTimeDonut: "",
      expectedValueStroke: "",
      angleOffset: -90,
      chartData: [],
      showedData: [],
      radius: 74,
      cx: 116,
      cy: 116,
    };
  },
  computed: {
    styleBigCircle() {
      return { strokeDashoffset: this.stroke };
    },
    styleSmallCircle() {
      return { strokeDashoffset: this.expectedValueStroke };
    },
  },
  watch: {
    days() {
      this.daysConverter();
    },
    actualValue() {
      this.leadsConverter();
      this.calculateChartData();
      this.tableDataActualBiggerthanExpected();
      this.setTableData();
    },
    expectedValue() {
      this.leadsConverter();
      this.calculateChartData();
    },
    tableData() {
      this.calculateChartData();
    },
  },
  methods: {
    setPercent() {
      this.percent = Math.round(
        (Number(this.actualValue) * 100) / Number(this.expectedValue)
      );
    },
    setTableData() {
      this.tableData[0] = Number(this.actualValue);
      let difference = Number(this.expectedValue) - Number(this.actualValue);
      this.tableData[1] = difference;
      this.setPercent();
    },
    daysConverter() {
      this.difference = this.days - this.totalDays;
      if (this.days > 0 && this.stroke < 30 && this.difference < 0) {
        this.stroke = 40;
        this.colorTimeDonut = "#D3D3D3";
      } else if (this.days > this.totalDays) {
        this.stroke = -(723 / this.totalDays) * this.difference;
        this.colorTimeDonut = "#FF0000";
      } else {
        this.stroke = (723 / this.totalDays) * this.days;
        this.colorTimeDonut = "#D3D3D3";
      }
    },
    leadsConverter() {
      if (this.percent == 0 && Number(this.actualValue) > 0) {
        this.expectedValueStroke = -(471 / 100) * 1;
      } else {
        this.expectedValueStroke = -(471 / 100) * this.percent;
      }
    },

    calculateChartData() {
      this.setTableData();
      this.chartData = [];

      if (
        this.tableData.length > 0 &&
        !this.tableData.some(isNaN) &&
        this.percent !== Infinity
      ) {
        return this.tableData.forEach((dataVal) => {
          const { x, y } = this.calculateTextCoords(dataVal, this.angleOffset);
          // start at -90deg so that the largest segment is perpendicular to top
          const data = {
            degrees: this.angleOffset,
            textX: x,
            textY: y,
          };
          this.chartData.push(data);
          this.angleOffset =
            this.dataPercentage(dataVal) * 360 + this.angleOffset;
        });
      } else {
        return;
      }
    },
    calculateTextCoords(dataVal, angleOffset) {
      const angle = (this.dataPercentage(dataVal) * 360) / 2 + angleOffset;
      const radians = this.degreesToRadians(angle);

      const textCoords = {
        x: this.radius * Math.cos(radians) + this.cx,
        y: this.radius * Math.sin(radians) + this.cy,
      };

      return textCoords;
    },
    dataPercentage(dataVal) {
      return dataVal / this.expectedValue;
    },
    degreesToRadians(angle) {
      return angle * (Math.PI / 180);
    },
    returnCircleTransformValue() {
      if (this.chartData.length > 0) {
        return `rotate(90, ${this.cx}, ${this.cy})`;
      }
    },
    tableDataActualBiggerthanExpected() {
      let diff = this.actualValue - this.expectedValue;

      if (diff > 0) {
        this.showedData[0] = `${this.expectedValue}/${this.expectedValue}`;
        this.showedData[1] = Math.abs(this.tableData[1]);
      } else {
        this.showedData[0] = this.tableData[0];
        this.showedData[1] = this.tableData[1];
      }
    },
  },
  mounted() {
    this.calculateChartData();
    this.leadsConverter();
    this.calculateChartData();
    this.tableDataActualBiggerthanExpected();
    this.setTableData();
  },
};
</script>
<style scoped>
.donutContainerExternal {
  position: relative;
  width: 240px;
}
.containerSecBigDonuts {
  position: absolute;
}
.wrapperInternalDonuts {
  position: absolute;
  width: 100%;
}
.containerTwoDonuts {
  width: 240px;
  height: 100%;

  position: relative;
}
.donutContainer {
  position: absolute;
  width: 100%;
}
.donutContainer2 {
  position: absolute;
}
h2 {
  text-align: center;
  position: absolute;
  line-height: 240px;
  width: 100%;
}

svg {
  transform: rotate(-90deg);
}

.donut {
  stroke-dasharray: 471;
  -webkit-animation: donut 1s ease-out forwards;
  animation: donut 1s ease-out forwards;
}
.donutBigger {
  stroke-dasharray: 723;
  -webkit-animation: donut 1s ease-out forwards;
  animation: donut 1s ease-out forwards;
}

@-webkit-keyframes donut {
  from {
    stroke-dashoffset: 471;
  }
}

@keyframes donut {
  from {
    stroke-dashoffset: 471;
  }
}
</style>
