<template>
  <app-header />
  <div class="ml-8 p-2 pt-4 pb-20">
    <p class="text-2xl mb-5 font-bold">
      {{ $t("client-details-page.client") }}
    </p>
    <company-info-card
      @onOpenCompanyDetails="onModalOpen('editCompanyCard')"
      @onOpenOrCreateContactDetails="onOpenOrCreateContactDetails"
      @onDeleteConfirm="onModalOpen('deleteConfirm')"
      :companyContactDetails="companyContactDetails"
      :companyInformation="companyInformation"
      :errorMsg="errorMsg"
      :redirectURL="redirectURL"
      @onOpenContactsImport="onOpenContactsImport"
    />

    <skeleton-jobs-card v-if="loading" />
    <div v-else>
      <div class="mt-12">
        <div class="flex flex-row justify-between items-center h-20">
          <p class="text-2xl font-bold my-5">
            {{ $t("client-details-page.vacancies") }}
          </p>
          <div class="flex flex-row self-start">
            <button
              v-if="isAdmin"
              class="bg-gray-400 hover:bg-[#2197a7] text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mr-4 shadow-md"
              @click="onModalOpen('newJobModal')"
            >
              <span class="text-white">+ {{ $t("add-new-job.add-job") }}</span>
            </button>
            <search-bar :original="activeJobList" :dispatch="dispatchActive" />
            <sort-component
              @sortAz="sortAscOrDesc('sortDataAzJobs')"
              @sortZa="sortAscOrDesc('sortDataZaJobs')"
              @sortFav="sortFavActive"
              :isFilterActive="isFilterActive"
              isLocalSortBy="true"
            />
            <button
              v-if="isFilterActive"
              class="bg-transparent text-gray-700 font-semibold py-0 px-2 rounded text-sm m-0 shadow-md"
              @click="onResetSort"
            >
              Reset
            </button>
          </div>
        </div>

        <div>
          <div class="flex flex-row flex-start p-8 bg-gray-200 rounded-lg">
            <p
              v-if="
                filteredActiveJobs.length == 0 || filteredActiveJobs == null
              "
              class="bg-white p-2 rounded-lg"
            >
              {{ $t("client-details-page.no-vacancies") }}
            </p>
            <div v-else class="grid grid-cols-4 gap-4">
              <vacancies-card
                v-for="job in filteredActiveJobs"
                :key="job.id"
                :job="job"
                :selectedCompanyId="selectedCompanyId"
                :favorites="favorites"
                @selectOrRemoveFavorite="selectFavorite(job.id)"
                :contacts="contacts"
                @onContactModalFinish="onContactModalFinish()"
                @onOpenContactsImport="onOpenContactsImport"
              />
            </div>
          </div>
        </div>

        <div class="mt-12">
          <div class="flex flex-row justify-between items-center">
            <p class="text-2xl my-5 text-2xl font-bold">
              {{ $t("client-details-page.in-planning-vacancies") }}
            </p>
          </div>
          <div class="flex flex-row flex-start p-4 bg-gray-200 rounded-lg">
            <p
              v-if="inPlaningJobs.length == 0 || inPlaningJobs == null"
              class="bg-white p-2 rounded-lg"
            >
              {{ $t("client-details-page.no-in-planning-vacancies") }}
            </p>
            <div v-else class="grid grid-cols-4 gap-4">
              <vacancies-card
                v-for="job in inPlaningJobs"
                :key="job.id"
                :job="job"
                :selectedCompanyId="selectedCompanyId"
                isInPlanning="true"
                :contacts="contacts"
                @onContactModalFinish="onContactModalFinish()"
                @onJobDeleteConfirm="
                  (job) => onModalOpen('jobDeleteConfirm', job)
                "
                @onConnectAtsJob="(job) => onModalOpen('connectAtsJob', job)"
                @onOpenContactsImport="onOpenContactsImport"
              />
            </div>
          </div>
        </div>

        <div class="mt-12">
          <div class="grid grid-cols-2">
            <div>
              <h2 class="my-5 mx-2 text-2xl font-bold">
                {{ $t("client-details-page.archive") }}
              </h2>
            </div>
            <div class="flex justify-end mr-10">
              <h2
                @click="onChangeCompanyCategory('Archive')"
                class="my-5 mx-2 text-md font-bold cursor-pointer text-blue-500 hover:text-blue-900 primary-color-text mr-4"
              >
                {{
                  jobCategoryToShow.includes("Archive")
                    ? "- Collapse"
                    : "+ Expand"
                }}
              </h2>
            </div>
          </div>

          <transition name="slide-fade">
            <div v-if="jobCategoryToShow.includes('Archive')">
              <div
                v-if="endedJobList.length == 0 || endedJobList == null"
                class="flex flex-row flex-start p-4 bg-gray-200 rounded-lg"
              >
                <div class="bg-white p-2 rounded-lg">
                  {{ $t("client-details-page.no-archive") }}
                </div>
              </div>
              <div v-else class="flex flex-col p-4 bg-gray-200 rounded-lg">
                <archiv-card
                  v-for="job in endedJobList"
                  :key="job.id"
                  :job="job"
                  :selectedCompanyId="selectedCompanyId"
                  :favorites="favorites"
                  @selectOrRemoveFavorite="selectFavorite(job.id)"
                />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <new-job-modal
      @onModalClose="onModalClose"
      :companyId="selectedCompanyId"
      v-if="isModal == 'newJobModal'"
    />

    <new-company-modal
      v-if="['editCompanyCard', 'editContactInfo'].includes(isModal)"
      :editStep="isModal == 'editCompanyCard' ? 1 : 2"
      :jobIds="jobIds"
      @onModalClose="onModalClose"
      :companyInformation="companyInformation"
      :contacts="contacts"
      :companyContactDetailsToEdit="companyContactDetailsToEdit"
    />

    <delete-confirm-modal
      v-if="isModal == 'deleteConfirm'"
      @onModalClose="onModalClose"
      @onWarningMsg="onWarningMsg"
      :companyInformation="companyInformation"
    />

    <delete-job-confirm-modal
      v-if="isModal == 'jobDeleteConfirm'"
      @onModalClose="onModalClose"
      @onWarningMsg="onWarningMsg"
      :job="job"
    />

    <connect-ats-job-modal
      v-if="isModal == 'connectAtsJob'"
      @onModalClose="onModalClose"
      @onWarningMsg="onWarningMsg"
      :job="job"
      :filteredActiveJobs="filteredActiveJobs"
    />

    <warning-msg-modal
      v-if="isModal == 'warningMsg'"
      @onModalCloseOrRedirect="onModalCloseOrRedirect"
      :errorMsg="errorMsg"
      :redirectURL="redirectURL"
    />

    <import-contacts-modal
      v-if="isModal == 'importContacts'"
      @onModalCloseOrRedirect="onModalCloseOrRedirect"
      :importContactDetails="importContactDetails"
      @onModalClose="onModalClose"
      :contacts="contacts"
    />
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import AppHeader from "../../components/layout/header/AppHeader.vue";
import CompanyInfoCard from "./CompanyInfoCard.vue";
import VacanciesCard from "./VacanciesCard.vue";
import ArchivCard from "./ArchivCard.vue";
import SortComponent from "../../components/ui/SortComponent.vue";
import SearchBar from "../../components/ui/SearchBar.vue";
import NewJobModal from "../../components/Modals/JobModal/NewJobModal.vue";
import NewCompanyModal from "../../components/Modals/NewCompanyModal/NewCompanyModal.vue";
import DeleteConfirmModal from "../../components/Modals/NewCompanyModal/DeleteConfirmModal.vue";
import DeleteJobConfirmModal from "../../components/Modals/JobModal/DeleteJobConfirmModal.vue";
import WarningMsgModal from "../../components/Modals/NewCompanyModal/WarningMsgModal.vue";
import SkeletonJobsCard from "./SkeletonJobsCard.vue";
import ImportContactsModal from "./ImportContactsModal.vue";
import ConnectAtsJobModal from "../../components/Modals/JobModal/ConnectAtsJobModal.vue";
import {
  storeCompanyDetailsInCache,
  companyDataInCache,
  removeCompleteCompanyDataFromCache,
} from "../../components/helperFunctions/DataCaching";

export default {
  components: {
    AppHeader,
    CompanyInfoCard,
    VacanciesCard,
    ArchivCard,
    SortComponent,
    SearchBar,
    NewJobModal,
    NewCompanyModal,
    DeleteConfirmModal,
    DeleteJobConfirmModal,
    ConnectAtsJobModal,
    WarningMsgModal,
    SkeletonJobsCard,
    ImportContactsModal,
  },
  data() {
    return {
      dispatchActive: "setFilteredActiveJobs",
      activeJobList: [],
      endedJobList: [],
      inPlaningJobs: [],
      job: {},
      jobs: [],
      jobStatus: "",
      favorites: [],
      isFilterActive: false,
      companyContactDetails: [],
      companyContactDetailsToEdit: {},
      contacts: [],
      companyInformation: {},
      loading: false,
      errorMsg: null,
      redirectURL: null,
      importContactDetails: {},
      jobCategoryToShow: [""],
      jobIds: [],
    };
  },
  watch: {
    selectedCompanyId(id) {
      this.fetchDataFromBackend(id);
    },
    activeJobList(newVal) {
      this.$store.dispatch("setFilteredActiveJobs", newVal);
      this.$store.dispatch("setActiveJobs", newVal);
    },
    endedJobList(newVal) {
      this.$store.dispatch("setAllEndedJobs", newVal);
    },
    isModal(newVal) {
      // whenever a model open delete all data from cache to store new one
      if (newVal !== "") {
        removeCompleteCompanyDataFromCache(this.$route.params.id);
      } else {
        this.$router.go(0);
      }
    },
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
    filteredActiveJobs() {
      return this.$store.getters.filteredActiveJobs;
    },
    calculateJobIds() {
      let jobDetails = companyDataInCache(this.selectedCompanyId, "jobsList");
      let jobIds = jobDetails.map((job) => job.id); // Map the ids of filtered jobs
      return jobIds;
    },
    ...mapGetters("job", ["isModal"]),
    ...mapGetters([
      "selectedCompanyId",
      "authorizationHeader",
      "backendUrl",
      "isAdmin",
    ]),
  },
  methods: {
    ...mapActions("job", ["setIsModal"]),
    //  ================================= small helper functions START ==============================================
    onContactModalFinish() {
      this.fetchDataFromBackend(this.selectedCompanyId);
    },
    onModalOpen(modalName, job) {
      if (job) this.job = job;
      this.setIsModal(modalName);
    },
    onModalClose() {
      this.setIsModal("");
    },
    onWarningMsg(errorMsg, redirectURL) {
      this.errorMsg = errorMsg;
      this.redirectURL = redirectURL;
      this.setIsModal("warningMsg");
    },
    onOpenOrCreateContactDetails(contactToEdit) {
      this.companyContactDetailsToEdit = contactToEdit;
      this.jobIds = this.calculateJobIds;
      this.setIsModal("editContactInfo");
    },
    onModalCloseOrRedirect() {
      this.setIsModal("");
      removeCompleteCompanyDataFromCache(this.$route.params.id);
      if (this.redirectURL != null) {
        this.$router.push(this.redirectURL);
      } else {
        this.$router.go(0);
      }
    },
    sortAscOrDesc(storeMethod) {
      let removeMultiple = this.activeJobList.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      this.$store.dispatch(storeMethod, removeMultiple);
      this.isFilterActive = true;
    },
    sortFavActive() {
      this.$store.dispatch("sortDataFavJobs", {
        jobs: this.filteredActiveJobs,
        favorites: this.favorites,
      });
      this.isFilterActive = true;
    },
    onResetSort() {
      this.showFavoritesBefore(this.activeJobList, true);
      this.showFavoritesBefore(this.endedJobList, false);
      this.isFilterActive = false;
    },
    onOpenContactsImport(jobId, alreadyImportedContacts) {
      this.setIsModal("importContacts");
      this.importContactDetails = { jobId, alreadyImportedContacts };
    },
    //  ================================= small helper functions END ==============================================

    onChangeCompanyCategory(categoryName) {
      if (this.jobCategoryToShow.includes(categoryName)) {
        const index = this.jobCategoryToShow.indexOf(categoryName);
        if (index > -1) {
          this.jobCategoryToShow.splice(index, 1);
        }
      } else {
        this.jobCategoryToShow = [...this.jobCategoryToShow, categoryName];
      }
    },

    fetchDataFromBackend(id) {
      this.loading = true;
      this.$store.dispatch("resetJobVariables"); // to reset all variables to empty array before API hit
      const headers = this.authorizationHeader;
      const urls = [
        `${this.backendUrl}/menu/jobs-info/${id}`,
        `${this.backendUrl}/contact/company-contacts?companyId=${id}`,
        `${this.backendUrl}/company/company-information/?companyId=${id}`,
      ];

      axios
        .all(urls.map((url) => axios.get(url, { headers })))
        .then(
          axios.spread((response1, response2, response3) => {
            const [data1, data2, data3] = [
              response1.data,
              response2.data,
              response3.data,
            ];
            storeCompanyDetailsInCache(id, "jobsList", data1);
            storeCompanyDetailsInCache(id, "contacts", data2);
            storeCompanyDetailsInCache(id, "companyInformation", data3);
            this.postProcessOfFetchedData(data1, data2, data3);
          })
        )
        .catch((error) => {
          console.log(error);
        });
    },
    postProcessOfFetchedData(jobsList, contacts, companyInformation) {
      this.activeJobList = [];
      this.endedJobList = [];
      this.inPlaningJobs = [];

      this.filterJobList(jobsList);

      this.filterCompanyContacts(contacts);

      // logic for endpoint of getting company information
      this.companyInformation = companyInformation;
      this.$store.dispatch("setCompany", {
        newCompanyName: companyInformation.name,
        newCompanyId: companyInformation.company_id,
      });

      this.loading = false;
    },
    filterCompanyContacts(contacts) {
      // logic for endpoint of all contacts
      this.contacts = contacts;

      // filter company contacts
      let filterCompanyContact = contacts.filter(
        (values) => values.cardLevel == "1" && values.job_id == "0"
      );
      this.companyContactDetails = filterCompanyContact;
    },
    filterJobList(jobsList) {
      jobsList.forEach((singleJob) => {
        switch (singleJob.status) {
          case "Läuft":
            this.activeJobList.push(singleJob);
            break;
          case "Beendet":
            this.endedJobList.push(singleJob);
            break;
          case "In Planung":
            this.inPlaningJobs.push(singleJob);
            break;
          default:
            break;
        }
      });

      this.activeJobList.sort((a, b) => a.name.localeCompare(b.name));
      this.endedJobList.sort((a, b) => a.name.localeCompare(b.name));
      this.getFavorites();
    },

    fetchDataFromBackendCompanyGoBack() {
      let companies = this.$store.getters.storeState.project.companies;
      let companySelectWithParam = companies.filter((company) => {
        return company.id == document.URL.split("/").slice(-1)[0];
      });
      this.$store.dispatch("setCompany", {
        newCompanyName: companySelectWithParam[0].name,
        newCompanyId: companySelectWithParam[0].id,
      });
    },
    ////
    selectFavorite(company) {
      this.addAndRemoveFavoriteToList(company);
    },
    addAndRemoveFavoriteToList(id) {
      let headers = this.authorizationHeader;
      const newFavorite = {
        job_id: id,
      };
      axios
        .post(this.backendUrl + `/job/favorites`, newFavorite, { headers })
        .then(() => {
          localStorage.removeItem("favoriteJobs");
          this.getFavorites();
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    getFavorites() {
      if (localStorage.getItem("favoriteJobs")) {
        setTimeout(() => {
          this.postProcessOfFavoriteJobs(
            JSON.parse(localStorage.getItem("favoriteJobs"))
          );
        }, 200);
      } else {
        let headers = this.authorizationHeader;
        axios
          .get(this.backendUrl + "/job/favorites", {
            headers,
          })
          .then((response) => {
            localStorage.setItem(
              "favoriteJobs",
              JSON.stringify(response.data.job_id)
            );
            this.postProcessOfFavoriteJobs(response.data.job_id);
          })
          .catch((error) => console.log(error));
      }
    },
    postProcessOfFavoriteJobs(data) {
      this.favorites = data;
      if (this.filteredActiveJobs.length > 0)
        // condition to check if list length is greater than 0 than run a method only
        this.showFavoritesBefore(this.filteredActiveJobs, true);
      this.showFavoritesBefore(this.endedJobList, false);
    },
    showFavoritesBefore(list, boolean) {
      let favJobs = [];
      let favValues = Object.values(this.favorites);
      let listJobs = list;
      list.forEach((job) => {
        if (favValues.filter((e) => e == job.id).length > 0) {
          favJobs.unshift(job);
        }
      });
      favJobs.map((c) => {
        listJobs.unshift(c);
      });
      if (boolean) {
        let reOrded = listJobs.filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.place === value.place && t.id === value.id)
        );
        this.activeJobList = reOrded;
        this.$store.dispatch("setFilteredActiveJobs", reOrded);
      } else {
        this.endedJobList = listJobs.filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.place === value.place && t.id === value.id)
        );
      }
    },
  },
  mounted() {
    // this.getFavorites(); Hunzala: comment this method becuase it is of no use to call here just increasing performace issue

    // check if any one key is available in cache it means all other data is present in cache and no need to hit API

    if (companyDataInCache(this.$route.params.id, "jobsList")) {
      let jobList = companyDataInCache(this.$route.params.id, "jobsList");
      let contacts = companyDataInCache(this.$route.params.id, "contacts");
      let companyInformation = companyDataInCache(
        this.$route.params.id,
        "companyInformation"
      );
      this.postProcessOfFetchedData(jobList, contacts, companyInformation);
    } else {
      this.fetchDataFromBackend(this.$route.params.id);
    }

    window.onpopstate = () => {
      if (!isNaN(document.URL.slice(-1))) {
        this.fetchDataFromBackendCompanyGoBack();
      }
    };
  },
};
</script>
